import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import { getItem } from "../../utility/localStorageControl";
import { useDispatch } from "react-redux";
import logo from "../../assets/logo.svg";
import style from "./AuthFiles.module.css";
import selectedSVG from "../../assets/svg/selected.svg";
import { updateQuestions } from "../../redux/authentication/action";

// DEFINE MAIN FUNCTION
const SignUpDeliveryQuestions = () => {
  // DEFINE ALL STATES
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [optionSelect, setOptionSelect] = useState("");

  // DEFINE USEEFFECT FOR FORM VALUES VALIDATE
  useEffect(() => {
    if (getItem("userDetails")) {
      if (getItem("userDetails")?.signup_step == 1) {
        setOptionSelect(
          getItem("userDetails")?.imporve_deliverability_for
            ? getItem("userDetails")?.imporve_deliverability_for
            : ""
        );
      } else {
        navigate("/signup");
      }
    } else {
      navigate("/signup");
    }
  }, []);

  // DEFINE FUNCTION FOR SELECTED VALUE SUBMIT
  const onSubmit = async (values) => {
    let response = await dispatch(
      updateQuestions({
        imporve_deliverability_for: values,
      })
    );
    if (response.status) {
      if (response?.data?.signup_step == 2) {
        navigate("/onboarding");
      }
    }
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <div className={style.questionComponent}>
        <header className={style.questionHeader}>
          <div className={style.backbutton}>
            <Button
              icon={<ArrowLeftOutlined />}
              size="large"
              onClick={() => {
                navigate(-1);
              }}
            >
              {" "}
              Back
            </Button>
            <div>
              <div className={style.logo}>
                <img src={logo} />
                MailOptimal
              </div>
            </div>
          </div>
        </header>
        <div
          className="signup-question-progress"
          style={{ borderBottom: "4px solid #F75F18", width: "50%" }}
        ></div>
        <div
          // style={{ maxHeight: "1000px", overflow: "auto" }}
          className={style.scrollDiv}
        >
          <div className={style.questionsSection}>
            <div className={style.section}>
              <h3 className={style.title}>
                What type of emails you want to improve deliverability for?
              </h3>
              <div className={style.questionButtons}>
                <button
                  onClick={() => {
                    setOptionSelect("Marketing Emails");
                  }}
                  className={
                    optionSelect == "Marketing Emails" ? style.selected : ""
                  }
                >
                  {optionSelect == "Marketing Emails" ? (
                    <img src={selectedSVG} />
                  ) : (
                    ""
                  )}

                  <span>Marketing Emails</span>
                </button>
                <button
                  onClick={() => {
                    setOptionSelect("Newsletter Emails");
                  }}
                  className={
                    optionSelect == "Newsletter Emails" ? style.selected : ""
                  }
                >
                  {optionSelect == "Newsletter Emails" ? (
                    <img src={selectedSVG} />
                  ) : (
                    ""
                  )}
                  <span>Newsletter Emails</span>
                </button>
                <button
                  onClick={() => {
                    setOptionSelect("Transactional Emails");
                  }}
                  className={
                    optionSelect == "Transactional Emails" ? style.selected : ""
                  }
                >
                  {optionSelect == "Transactional Emails" ? (
                    <img src={selectedSVG} />
                  ) : (
                    ""
                  )}
                  <span>Transactional Emails</span>
                </button>

                <button
                  onClick={() => {
                    setOptionSelect("Cold Emails");
                  }}
                  className={
                    optionSelect == "Cold Emails" ? style.selected : ""
                  }
                >
                  {optionSelect == "Cold Emails" ? (
                    <img src={selectedSVG} />
                  ) : (
                    ""
                  )}
                  <span>Cold Emails</span>
                </button>
                <button
                  onClick={() => {
                    setOptionSelect("Promotional Emails");
                  }}
                  className={
                    optionSelect == "Promotional Emails" ? style.selected : ""
                  }
                >
                  {optionSelect == "Promotional Emails" ? (
                    <img src={selectedSVG} />
                  ) : (
                    ""
                  )}
                  <span>Promotional Emails</span>
                </button>
                <button
                  onClick={() => {
                    setOptionSelect("1:1 Emails");
                  }}
                  className={optionSelect == "1:1 Emails" ? style.selected : ""}
                >
                  {optionSelect == "1:1 Emails" ? (
                    <img src={selectedSVG} />
                  ) : (
                    ""
                  )}
                  <span>1:1 Emails</span>
                </button>
                <button
                  onClick={() => {
                    if (optionSelect) {
                      onSubmit(optionSelect);
                    }
                  }}
                  style={{
                    opacity: optionSelect !== "" ? "100%" : "35%",
                  }}
                  type="submit"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default SignUpDeliveryQuestions;
