import React, { useDebugValue, useEffect, useRef, useState } from "react";
import style from "./deliverabilityTable.module.css";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Input,
  Row,
  Table,
  Select,
  Checkbox,
  Pagination,
  notification,
  Spin,
  Radio,
} from "antd";
import debounce from "lodash.debounce";
import Speedometer, {
  Background,
  Arc,
  Needle,
  Progress,
  Marks,
  Indicator,
} from 'react-speedometer';

import DeleteEmailModal from "../../../component/dashboardModal/DeleteEmailModal/DeleteEmailModal";
import DeleteIcon from "../../../assets/dashboard/trash.svg";
import rightIcon from "../../../assets/Email/notification-right.png";
import deliverability from "../../../assets/deleverability/deliverability.png";
import { useDispatch, useSelector } from "react-redux";
import {
  EmailDeliverabilityTemplateDelete,
  getDeliverabilityTestListApi,
} from "../../../redux/deliverabilityTest/action";
import AddNewAccount from "../../../component/global/AddNewAccount";
import moment from "moment";
import ReactSpeedometer from "react-d3-speedometer";
import { connectedEmailList, getAllEmailList } from "../../../redux/dashboard/action";
import { EmailConnectDelete } from "../../../state/EmailConnect/Emailconnect.api";
import { getItem } from "../../../utility/localStorageControl";
import { CheckCircleOutlined } from "@ant-design/icons";

// DEFINE MAIN FUNCTION
const DeliverabilityTable = () => {
  // DEFINE ALL STATES
  let isMount = useRef(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10);
  const [searchData, setSearchData] = useState("");
  const [searchData2, setSearchData2] = useState("");
  const [checkedItems, setCheckedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  const [deleteEmailModal, setDeleteEMailModal] = useState(false);
  const [commingSoon, setCommingSoon] = useState(false);
  const [loading, setLoading] = useState(false);

  const [tableSortingValues, setTableSortingValues] = useState({
    createdAt: "",
    placement_score: "",
    setup_score: "",
  });
  const dataFromApi = useSelector((state) => {
    return state?.deliverabilityTest?.deliverabilityTestList;
  });
  console.log("dataFromApi", dataFromApi)

  const [deliverabilityTableData, setDeliverabilityTableData] = useState([]);
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [selectOption, setSelectOption] = useState([]);
  useEffect(() => {
    setDeliverabilityTableData(dataFromApi?.data)
  }, [dataFromApi])

  useEffect(() => {
    setTableLoading(true)
    fetchDeliverabilityTestList();
    setTableLoading(false)
  }, [currentPage, searchData, pageSize, checkedItems, tableSortingValues]);

  const EmailAccountList = async () => {
    try {
      const payload = {
        userId: getItem("userDetails")?.id,
      }
      const res = await dispatch(getAllEmailList(payload));
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    EmailAccountList();
  }, [])

  let listOfEmailUser = useSelector((state) => state.dashboard.newEmailList);
  console.log("listOfEmailUser", listOfEmailUser);

  useEffect(() => {
    getSelectOptions();
  }, [listOfEmailUser]);

  const getSelectOptions = () => {
    if (listOfEmailUser && listOfEmailUser.length > 0) {
      let arr = [];
      listOfEmailUser &&
        listOfEmailUser.map((email, i) => {
          const obj = {
            data: email,
            value: email?.sender_email,
            key: `rc-index-key-${i}`,
            label: (
              <span className={style.custome_selectOtption_lable}>
                <span className={style.emailType_logo}>
                  {email.type == "google" ? (
                    <svg viewBox="0 0 500 500" id="google">
                      <path
                        fill="#fbbb00"
                        d="M113.47 309.408 95.648 375.94l-65.139 1.378C11.042 341.211 0 299.9 0 256c0-42.451 10.324-82.483 28.624-117.732h.014L86.63 148.9l25.404 57.644c-5.317 15.501-8.215 32.141-8.215 49.456.002 18.792 3.406 36.797 9.651 53.408z"
                      />
                      <path
                        fill="#518ef8"
                        d="M507.527 208.176C510.467 223.662 512 239.655 512 256c0 18.328-1.927 36.206-5.598 53.451-12.462 58.683-45.025 109.925-90.134 146.187l-.014-.014-73.044-3.727-10.338-64.535c29.932-17.554 53.324-45.025 65.646-77.911h-136.89V208.176h245.899z"
                      />
                      <path
                        fill="#28b446"
                        d="m416.253 455.624.014.014C372.396 490.901 316.666 512 256 512c-97.491 0-182.252-54.491-225.491-134.681l82.961-67.91c21.619 57.698 77.278 98.771 142.53 98.771 28.047 0 54.323-7.582 76.87-20.818l83.383 68.262z"
                      />
                      <path
                        fill="#f14336"
                        d="m419.404 58.936-82.933 67.896C313.136 112.246 285.552 103.82 256 103.82c-66.729 0-123.429 42.957-143.965 102.724l-83.397-68.276h-.014C71.23 56.123 157.06 0 256 0c62.115 0 119.068 22.126 163.404 58.936z"
                      />
                    </svg>
                  ) : email.type == "smtp_imap" ? (
                    <svg
                      viewBox="0 0 27 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.834 23.9163H8.16732C4.66732 23.9163 2.33398 22.1663 2.33398 18.083V9.91634C2.33398 5.83301 4.66732 4.08301 8.16732 4.08301H19.834C23.334 4.08301 25.6673 5.83301 25.6673 9.91634V18.083C25.6673 22.1663 23.334 23.9163 19.834 23.9163Z"
                        stroke="#101828"
                        strokeWidth="1.75"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        opacity="0.4"
                        d="M19.8327 10.5L16.181 13.4167C14.9793 14.3733 13.0077 14.3733 11.806 13.4167L8.16602 10.5"
                        stroke="#101828"
                        strokeWidth="1.75"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg viewBox="0 0 260 260">
                      <path
                        d="M104.868 104.868H0V0h104.868v104.868z"
                        fill="#f1511b"
                      />
                      <path
                        d="M220.654 104.868H115.788V0h104.866v104.868z"
                        fill="#80cc28"
                      />
                      <path
                        d="M104.865 220.695H0V115.828h104.865v104.867z"
                        fill="#00adef"
                      />
                      <path
                        d="M220.654 220.695H115.788V115.828h104.866v104.867z"
                        fill="#fbbc09"
                      />
                    </svg>
                  )}
                </span>
                <span className={style.selectMailtext}>
                  {email?.sender_email}
                </span>
              </span>
            ),
          };
          arr.push(obj);
        });
      setSelectOption(arr);
    }
  };
  const filterEmailList = selectOption?.filter((item) => {
    if (searchData2 == '') return item
    return item?.data?.receiver_email?.toLowerCase()?.includes(searchData2?.toLowerCase()) || item?.data?.sender_username?.toLowerCase()?.includes(searchData2?.toLowerCase())
  })


  const speedometerNedle = document.getElementsByClassName('pointer');

  // Check if speedometerNedle[5] exists and is an elemen




  // DEFINE FUNCTION FOR BOX ONCHANGE
  const handleCheckboxChange = (e, email) => {
    if (e.target.checked) {

      setCheckedItems([email]);
    }
    else {
      setCheckedItems(checkedItems.filter(item => item.id !== email.id));
    }

    // Update the searchData with checkedItems
    if (checkedItems.length === 1) {
      setSearchData(checkedItems[0].sender_email);
    } else {
      setSearchData("");
    }
  };

  // DEFINE FUNCTION FOR TABLE PAGE CHANGE
  const onPageChange = async (page, current) => {
    setCurrentPage(page);
    setPageSize(current);
    // list(page, current, searchData);
  };
  console.log("table sorting value changed", tableSortingValues?.createdAt)
  const fetchDeliverabilityTestList = async () => {
    setTableLoading(true)
    let params = {
      page: currentPage ? currentPage : 1,
      limit: pageSize ? pageSize : 10,
      search: searchData ? searchData : "",
      createdAt: tableSortingValues?.createdAt,
      setup_score: tableSortingValues?.setup_score,
      placement_score: tableSortingValues?.placement_score,
      email_account_id:
        checkedItems && checkedItems.length !== 0 ? checkedItems[0] : "",
    };
    if (searchData || checkedItems.length !== 0) {
      setLoading(false);
      setTableLoading(false)
    } else {
      setLoading(true);
      setTableLoading(true);
    }
    setTableLoading(true);
    const res = await dispatch(getDeliverabilityTestListApi(params));
    setTableLoading(false)
    setLoading(false);
  };

  // if (loading) {
  //   return (
  //     <>
  //       {" "}
  //       <div className="no-data-component">
  //         <Spin />
  //       </div>
  //     </>
  //   );
  // }
  // DEFINE TABLE COLUMNS
  console.log("listOfEmailUser", listOfEmailUser?.length)

  const needlewidth = () => {
    if (listOfEmailUser?.length > 0) {
      for (let i = 0; i < 40; i++) {
        if (listOfEmailUser.length > 0) {
          const speedometerNedle = document.getElementsByClassName('pointer');

          // Check if speedometerNedle[speedometerNedleIndex] exists and is an element
          if (speedometerNedle.length > i && speedometerNedle[i]?.querySelector) {
            const pathElement = speedometerNedle[i].querySelector('path');

            // Check if pathElement is valid and has setAttribute method
            if (pathElement && pathElement.setAttribute) {
              const newDAttributeValue = 'M 2.5 0 C 1.667 -12.5 0.833 -25 0 -25 C -0.833 -25 -1.667 0 -2.5 0 C -1.667 0 -0.833 2.5 0 2.5 C 0.833 2.5 1.667 1.25 2.5 0';
              pathElement.setAttribute('d', newDAttributeValue);
              console.log("Updated path d attribute:", newDAttributeValue);
            } else {
              console.error("pathElement or its setAttribute method is not valid.");
            }
          } else {
            console.error(`speedometerNedle[${i}] or its querySelector method is not valid.`);
          }
        } else {
          console.log("No users in the list.");
        }
      }
    }
  }
  useEffect(() => {

    setTimeout(() => {
      needlewidth();
    }, [200])
  }, [listOfEmailUser, tableSortingValues])


  // Example usage:
  const columns = [
    {
      title: <span>EMAIL ACCOUNT</span>,
      dataIndex: "EMAIL ACCOUNT",
      key: "column2",
      className: "custom-header",
      render: (icon, value) => {
        return (
          <span className="columData">
            <div>
              <span>{value?.emailAccount?.sender_email} </span>
            </div>
          </span>
        );
      },
    },
    {
      title: <span>SUBJECT </span>,
      dataIndex: "warmup_status",
      key: "warmup_status",
      className: "custom-header",
      render: (icon, value) => {
        return (
          <span className="columData">
            <div>
              <span>{value?.subject} </span>
            </div>
          </span>
        );
      },
      width: 300,
    },
    {
      title: (
        <span
          onClick={() => {
            setTableSortingValues({
              ...tableSortingValues,
              placement_score: "",
              setup_score: "",
              createdAt:
                tableSortingValues.createdAt == "" ||
                  tableSortingValues.createdAt == "DESC"
                  ? "ASC"
                  : "DESC",
            });
          }}>
          CREATED{" "}
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.0835 8.74967L7.00016 11.6663L9.91683 8.74967M4.0835 5.24967L7.00016 2.33301L9.91683 5.24967"
              stroke="#98A2B3"
              strokeWidth="1.16667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      ),
      dataIndex: "sent_today",
      key: "sent_today",
      className: "custom-header",
      render: (icon, value) => {
        return (
          <span

            className="columData">
            <div>
              <span>{moment(value?.createdAt).format("Do MMMM YYYY")} </span>
            </div>
          </span>
        );
      },
    },
    {
      title: (
        <span
          onClick={() => {
            setTableSortingValues({
              ...tableSortingValues,
              placement_score: "",
              createdAt: "",
              setup_score:
                tableSortingValues.setup_score == "" ||
                  tableSortingValues.setup_score == "DESC"
                  ? "ASC"
                  : "DESC",
            });
            // fetchDeliverabilityTestList();
          }}>
          SETUP SCORE{" "}
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.0835 8.74967L7.00016 11.6663L9.91683 8.74967M4.0835 5.24967L7.00016 2.33301L9.91683 5.24967"
              stroke="#98A2B3"
              strokeWidth="1.16667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      ),
      dataIndex: "receive_today",
      key: "receive_today",
      className: "custom-header",
      render: (icon, value) => {
        console.log("value: ", value)

        return (
          <div className="deliverabilityMeterContainer deliverabilityData">
            <div style={{ height: "60px" }}>
              {/* <Speedometer
                fontFamily='squada-one'
                value={value?.domainHealthScore ? value?.domainHealthScore : 0}
                max={100}
                width={60}
                angle={180}
                children={<Needle color="#000" circleRadius={2} />}
              >

                <Arc arcWidth={7} height={30} color="red" />
                <Arc arcWidth={7} height={30} color="blue" />

                <Needle color="#000" circleRadius={2} />
                <Progress arcWidth={7} />

              </Speedometer> */}
              <ReactSpeedometer
                maxSegmentLabels={3}
                segments={3}
                maxValue={100}
                width={100}
                ringWidth={6}

                currentValueText=""
                value={value?.domainHealthScore ? value?.domainHealthScore : 0}
                needleColor="#272525"

                needleHeightRatio={0.5}
                paddingHorizontal={0}
                paddingVertical={0}
                labelFontSize={0}
                valueTextFontSize={0}
                segmentColors={["#DA6868", "#E3D02B", "#5BCD7C"]}
              />
            </div>
            <div className="part"></div>
            <span
              style={{
                color:
                  value?.domainHealthScore < 33
                    ? "#E31F1F"
                    : value?.domainHealthScore < 66
                      ? "#D37C0C"
                      : "Green",
              }}
            >
              {value?.domainHealthScore}%
            </span>
          </div>
        );
      },
    },
    {
      title: (
        <span onClick={() => {
          setTableSortingValues({
            ...tableSortingValues,
            placement_score:
              tableSortingValues.placement_score == "" ||
                tableSortingValues.placement_score == "DESC"
                ? "ASC"
                : "DESC",
            createdAt: "",

            setup_score: "",

          });
          // fetchDeliverabilityTestList();
        }}
        >
          PLACEMENT SCORE{" "}
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.0835 8.74967L7.00016 11.6663L9.91683 8.74967M4.0835 5.24967L7.00016 2.33301L9.91683 5.24967"
              stroke="#98A2B3"
              strokeWidth="1.16667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      ),
      dataIndex: "deliverability",
      key: "deliverability",
      className: "custom-header",
      render: (icon, value) => {
        return (
          <div className="deliverabilityMeterContainer deliverabilityData">
            <div style={{ height: "60px" }}>
              <ReactSpeedometer
                maxSegmentLabels={3}
                segments={3}
                maxValue={100}
                width={100}
                ringWidth={6}
                // height={45}
                currentValueText=""
                value={
                  value?.inboxPlacementScore ? value?.inboxPlacementScore : 0
                }
                needleColor="#272525"
                needleHeightRatio={0.5}
                paddingHorizontal={0}
                paddingVertical={0}
                labelFontSize={0}
                valueTextFontSize={0}
                segmentColors={["#DA6868", "#E3D02B", "#5BCD7C"]}
              />
            </div>
            <div className="part"></div>
            <span
              style={{
                color:
                  value?.inboxPlacementScore < 33
                    ? "#E31F1F"
                    : value?.inboxPlacementScore < 66
                      ? "#D37C0C"
                      : "Green",
              }}
            >
              {value?.inboxPlacementScore}%
            </span>
          </div>
        );
      },
    },
    {
      dataIndex: "column1",
      key: "more",
      className: "custom-header",
      render: (icon, value) => {
        return (
          // <DeleteOutlined className={style.deleteIcon} />
          <img
            src={DeleteIcon}
            className={style.deleteIcon}
            onClick={(e) => {
              e.stopPropagation();
              setDeleteEMailModal(true);
              setSelectedValue(value);
            }}
            alt="setting"
          />
        );
      },
    },
  ];

  const debouncedSearchHandler = debounce((value) => {
    setSearchData(value);
    // Additional logic after debouncing if needed
  }, 1000);

  const deleteTemplate = async (id) => {
    setDeleteModalLoading(true);
    const response = await dispatch(EmailDeliverabilityTemplateDelete(id));
    if (response) {
      setDeleteModalLoading(false);
      setSelectedValue({});
      fetchDeliverabilityTestList();
      notification.success({
        message: (
          <span className="notificationText">
            Email Deliverability Test has been deleted successfully
          </span>
        ),
        description: <span className="startBorder"></span>,
        icon: <img src={rightIcon} alt="right-icon" width={20} height={20} />,
        className: "custom-notification",
        duration: 3,
        style: { width: "362px" },
      });
      setDeleteEMailModal(false);
    } else {
      setDeleteModalLoading(false);
    }
  };
  // changes the email account
  const handleChangeEmailAccount = (value) => {
    if (value === "" || value === undefined) {
      setCheckedItems([]);
    }
    else {
      // setCheckedItems([value]);
    }
    console.log("value", value)
  }

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      {commingSoon ? (
        <>
          <div className={style.demoDeliverabilityImagContainer}>
            <h1>
              Coming Soon : Identity all your email problems with comprehensive
              deliverability test
            </h1>
            <img src={deliverability} className={style.deliverabilityImage} />
          </div>
        </>
      ) : (
        <>
          <div className={"templateListTable"}>
            <Card>
              <Row
                justify={"space-between"}
                gutter={[20, 20]}
                align={"middle"}
                className="card-main-row"
              >
                <div>
                  <Input
                    onChange={(event) => {
                      setSearchData(event.target.value);
                    }}
                    className={style.searchInput}
                    prefix={
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.58317 17.5003C13.9554 17.5003 17.4998 13.9559 17.4998 9.58366C17.4998 5.2114 13.9554 1.66699 9.58317 1.66699C5.21092 1.66699 1.6665 5.2114 1.6665 9.58366C1.6665 13.9559 5.21092 17.5003 9.58317 17.5003Z"
                          stroke="#98A2B3"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          opacity="0.4"
                          d="M18.3332 18.3337L16.6665 16.667"
                          stroke="#98A2B3"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    }
                    placeholder="Search"
                    value={searchData}
                  />
                  <Select
                    size="large"
                    placeholder="Email Account"
                    className={style.selectEmail}
                    allowClear={true}
                    onChange={handleChangeEmailAccount}
                    options={filterEmailList}
                    popupClassName="selectPopup"
                    dropdownRender={(menu) => (
                      <div>
                        <div className={style.searchInputDropdownContainor}>
                          <Input
                            onChange={(event) => {
                              setSearchData2(event.target.value);
                            }}
                            className={style.searchInputDropdown}
                            prefix={
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.58317 17.5003C13.9554 17.5003 17.4998 13.9559 17.4998 9.58366C17.4998 5.2114 13.9554 1.66699 9.58317 1.66699C5.21092 1.66699 1.6665 5.2114 1.6665 9.58366C1.6665 13.9559 5.21092 17.5003 9.58317 17.5003Z"
                                  stroke="#98A2B3"
                                  strokeWidth="1.25"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  opacity="0.4"
                                  d="M18.3332 18.3337L16.6665 16.667"
                                  stroke="#98A2B3"
                                  strokeWidth="1.25"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            }
                            suffix={
                              searchData2 &&
                              searchData2.length > 0 && (
                                <div onClick={() => setSearchData2("")}>
                                  <svg
                                    fill-rule="evenodd"
                                    viewBox="64 64 896 896"
                                    focusable="false"
                                    data-icon="close"
                                    width="10px"
                                    height="10px"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                                  </svg>
                                </div>
                              )
                            }
                            placeholder="Search"
                            value={searchData2}
                          />
                        </div>
                        {menu}
                      </div>
                    )}

                    optionRender={(option) => {
                      return (
                        <div className="selectPopupOption">
                          <Checkbox
                            onChange={(e) => handleCheckboxChange(e, option?.data?.data?.id)}

                            checked={checkedItems.includes(option?.data?.data?.id)}
                          >
                            <div className="select_item_content">
                              <span className={style.selectMailname}>
                                {option.data.data?.sender_username}
                              </span>
                              <span className={style.imgGoogle}>
                                {option.data.data.type == "google" ? (
                                  <svg viewBox="0 0 500 500" id="google">
                                    <path
                                      fill="#fbbb00"
                                      d="M113.47 309.408 95.648 375.94l-65.139 1.378C11.042 341.211 0 299.9 0 256c0-42.451 10.324-82.483 28.624-117.732h.014L86.63 148.9l25.404 57.644c-5.317 15.501-8.215 32.141-8.215 49.456.002 18.792 3.406 36.797 9.651 53.408z"
                                    />
                                    <path
                                      fill="#518ef8"
                                      d="M507.527 208.176C510.467 223.662 512 239.655 512 256c0 18.328-1.927 36.206-5.598 53.451-12.462 58.683-45.025 109.925-90.134 146.187l-.014-.014-73.044-3.727-10.338-64.535c29.932-17.554 53.324-45.025 65.646-77.911h-136.89V208.176h245.899z"
                                    />
                                    <path
                                      fill="#28b446"
                                      d="m416.253 455.624.014.014C372.396 490.901 316.666 512 256 512c-97.491 0-182.252-54.491-225.491-134.681l82.961-67.91c21.619 57.698 77.278 98.771 142.53 98.771 28.047 0 54.323-7.582 76.87-20.818l83.383 68.262z"
                                    />
                                    <path
                                      fill="#f14336"
                                      d="m419.404 58.936-82.933 67.896C313.136 112.246 285.552 103.82 256 103.82c-66.729 0-123.429 42.957-143.965 102.724l-83.397-68.276h-.014C71.23 56.123 157.06 0 256 0c62.115 0 119.068 22.126 163.404 58.936z"
                                    />
                                  </svg>
                                ) : option.data.data.type == "smtp_imap" ? (
                                  <svg
                                    viewBox="0 0 27 27"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M19.834 23.9163H8.16732C4.66732 23.9163 2.33398 22.1663 2.33398 18.083V9.91634C2.33398 5.83301 4.66732 4.08301 8.16732 4.08301H19.834C23.334 4.08301 25.6673 5.83301 25.6673 9.91634V18.083C25.6673 22.1663 23.334 23.9163 19.834 23.9163Z"
                                      stroke="#101828"
                                      strokeWidth="1.75"
                                      strokeMiterlimit="10"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      opacity="0.4"
                                      d="M19.8327 10.5L16.181 13.4167C14.9793 14.3733 13.0077 14.3733 11.806 13.4167L8.16602 10.5"
                                      stroke="#101828"
                                      strokeWidth="1.75"
                                      strokeMiterlimit="10"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg viewBox="0 0 260 260">
                                    <path
                                      d="M104.868 104.868H0V0h104.868v104.868z"
                                      fill="#f1511b"
                                    />
                                    <path
                                      d="M220.654 104.868H115.788V0h104.866v104.868z"
                                      fill="#80cc28"
                                    />
                                    <path
                                      d="M104.865 220.695H0V115.828h104.865v104.867z"
                                      fill="#00adef"
                                    />
                                    <path
                                      d="M220.654 220.695H115.788V115.828h104.866v104.867z"
                                      fill="#fbbc09"
                                    />
                                  </svg>
                                )}
                              </span>
                              <div className={style.deliverabilityTestEmailContainer}>
                                <span className={style.selectMailtext}>
                                  {option.data.data?.sender_email}
                                </span>
                                <span className={style.selectIcontext}>
                                  <CheckCircleOutlined />
                                </span>
                              </div>
                            </div>
                          </Checkbox>
                        </div>
                      );
                    }}
                  />
                </div>

                <div className={style.priceContainer}>
                  <button
                    onClick={() => {
                      navigate("/deliverability/create-new");
                    }}
                  >
                    <span>+ New Test</span>
                  </button>
                </div>
              </Row>
              {/* {loading ? <>
                <div className="no-data-component">
                  <Spin />
                </div></> : <> */}
              <Table
                className="templateListTable"
                columns={columns}
                dataSource={deliverabilityTableData}
                loading={loading}
                scroll={{
                  x: "1150px",
                }}
                pagination={false}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      event.preventDefault();
                      navigate(`/deliverability-test/${record?.id}`, {
                        state: record,
                      });
                    },
                  };
                }}
              />
              {dataFromApi && dataFromApi?.totalCount > 9 && (
                <div className={style.tablePagenation}>
                  <Pagination
                    total={dataFromApi && dataFromApi?.totalCount}
                    showSizeChanger
                    pageSizeOptions={[10, 20, 50, 100]}
                    defaultPageSize={pageSize}
                    defaultCurrent={currentPage}
                    onChange={onPageChange}
                  />
                  <span className={style.table_counts}>{`${currentPage * pageSize - (pageSize - 1)
                    }-${currentPage * pageSize} of ${dataFromApi && dataFromApi?.totalCount
                    }`}</span>
                </div>
              )}
              {/* </> */}
              {/* } */}

            </Card>

          </div>
          {deleteEmailModal && (
            <DeleteEmailModal
              deleteEmailModal={deleteEmailModal}
              selectedValue={selectedValue}
              type={"test"}
              loading={deleteModalLoading}
              onClose={() => {
                setDeleteEMailModal(false);
                setSelectedValue({});
              }}
              onSubmit={deleteTemplate}
            />
          )}
        </>
      )}
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default DeliverabilityTable;
