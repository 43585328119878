import React from "react";

const WarmUpRecoverSVGIcon = ({ status }) => {
  return (
    <svg
      width="185"
      height="81"
      viewBox="0 0 185 81"
      className="warm_up_mode_container_component_svg"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="-4.37114e-08" y1="80.5" x2="185" y2="80.5" stroke="#EAECF0" />
      <line
        x1="-2.18557e-08"
        y1="60.75"
        x2="185"
        y2="60.75"
        stroke="#EAECF0"
        strokeWidth="0.5"
        stroke-dasharray="2 2"
      />
      <line
        x1="-2.18557e-08"
        y1="40.75"
        x2="185"
        y2="40.75"
        stroke="#EAECF0"
        strokeWidth="0.5"
        stroke-dasharray="2 2"
      />
      <line
        x1="-2.18557e-08"
        y1="20.75"
        x2="185"
        y2="20.75"
        stroke="#EAECF0"
        strokeWidth="0.5"
        stroke-dasharray="2 2"
      />
      <line
        x1="-2.18557e-08"
        y1="0.750031"
        x2="185"
        y2="0.750014"
        stroke="#EAECF0"
        strokeWidth="0.5"
        stroke-dasharray="2 2"
      />
      <path
        d="M2 77.5C2 75.0147 4.01472 73 6.5 73C8.98528 73 11 75.0147 11 77.5V80.0588C11 80.5786 10.5786 81 10.0588 81H2.94118C2.42138 81 2 80.5786 2 80.0588V77.5Z"
        fill={`url(#paint0_linear_1833_${status ? "31103" : "30754"})`}
      />
      <path
        d="M17 5.50003C17 3.01475 19.0147 1.00003 21.5 1.00003C23.9853 1.00003 26 3.01475 26 5.50003V77C26 78.8857 26 79.8285 25.4142 80.4142C24.8284 81 23.8856 81 22 81H21C19.1144 81 18.1716 81 17.5858 80.4142C17 79.8285 17 78.8857 17 77V5.50003Z"
        fill={`url(#paint1_linear_1833_${status ? "31103" : "30754"})`}
      />
      <path
        d="M32 51.5C32 49.0147 34.0147 47 36.5 47C38.9853 47 41 49.0147 41 51.5V77C41 78.8856 41 79.8285 40.4142 80.4142C39.8284 81 38.8856 81 37 81H36C34.1144 81 33.1716 81 32.5858 80.4142C32 79.8285 32 78.8856 32 77V51.5Z"
        fill={`url(#paint2_linear_1833_${status ? "31103" : "30754"})`}
      />
      <path
        d="M47 28.5C47 26.0147 49.0147 24 51.5 24C53.9853 24 56 26.0147 56 28.5V77C56 78.8856 56 79.8284 55.4142 80.4142C54.8284 81 53.8856 81 52 81H51C49.1144 81 48.1716 81 47.5858 80.4142C47 79.8284 47 78.8856 47 77V28.5Z"
        fill={`url(#paint3_linear_1833_${status ? "31103" : "30754"})`}
      />
      <path
        d="M62 58.5C62 56.0147 64.0147 54 66.5 54C68.9853 54 71 56.0147 71 58.5V77.8235C71 78.9214 71 79.4704 70.7903 79.8915C70.5964 80.2809 70.2809 80.5964 69.8915 80.7903C69.4704 81 68.9214 81 67.8235 81H65.1765C64.0786 81 63.5296 81 63.1085 80.7903C62.7191 80.5964 62.4036 80.2809 62.2097 79.8915C62 79.4704 62 78.9214 62 77.8235V58.5Z"
        fill={`url(#paint4_linear_1833_${status ? "31103" : "30754"})`}
      />
      <path
        d="M77 45.5C77 43.0147 79.0147 41 81.5 41C83.9853 41 86 43.0147 86 45.5V77C86 78.8856 86 79.8285 85.4142 80.4142C84.8284 81 83.8856 81 82 81H81C79.1144 81 78.1716 81 77.5858 80.4142C77 79.8285 77 78.8856 77 77V45.5Z"
        fill={`url(#paint5_linear_1833_${status ? "31103" : "30754"})`}
      />
      <path
        d="M92 77.5C92 75.0147 94.0147 73 96.5 73C98.9853 73 101 75.0147 101 77.5V80.0588C101 80.5786 100.579 81 100.059 81H92.9412C92.4214 81 92 80.5786 92 80.0588V77.5Z"
        fill={`url(#paint6_linear_1833_${status ? "31103" : "30754"})`}
      />
      <path
        d="M107 60.5C107 58.0147 109.015 56 111.5 56C113.985 56 116 58.0147 116 60.5V78.0588C116 78.9355 116 79.3739 115.865 79.7225C115.662 80.247 115.247 80.6617 114.722 80.8649C114.374 81 113.936 81 113.059 81H109.941C109.064 81 108.626 81 108.278 80.8649C107.753 80.6617 107.338 80.247 107.135 79.7225C107 79.3739 107 78.9355 107 78.0588V60.5Z"
        fill={`url(#paint7_linear_1833_${status ? "31103" : "30754"})`}
      />
      <path
        d="M122 65.5C122 63.0147 124.015 61 126.5 61C128.985 61 131 63.0147 131 65.5V78.6471C131 78.9749 131 79.1388 130.981 79.2763C130.857 80.1615 130.162 80.8573 129.276 80.9808C129.139 81 128.975 81 128.647 81H124.353C124.025 81 123.861 81 123.724 80.9808C122.838 80.8573 122.143 80.1615 122.019 79.2763C122 79.1388 122 78.9749 122 78.6471V65.5Z"
        fill={`url(#paint8_linear_1833_${status ? "31103" : "30754"})`}
      />
      <path
        d="M139 14.5C139 12.0147 141.015 10 143.5 10C145.985 10 148 12.0147 148 14.5V77C148 78.8856 148 79.8284 147.414 80.4142C146.828 81 145.886 81 144 81H143C141.114 81 140.172 81 139.586 80.4142C139 79.8284 139 78.8856 139 77V14.5Z"
        fill={`url(#paint9_linear_1833_${status ? "31103" : "30754"})`}
      />
      <path
        d="M156 42.5C156 40.0147 158.015 38 160.5 38C162.985 38 165 40.0147 165 42.5V77C165 78.8857 165 79.8285 164.414 80.4142C163.828 81 162.886 81 161 81H160C158.114 81 157.172 81 156.586 80.4142C156 79.8285 156 78.8856 156 77V42.5Z"
        fill={`url(#paint10_linear_1833_${status ? "31103" : "30754"})`}
      />
      <path
        d="M173 68.5C173 66.0147 175.015 64 177.5 64C179.985 64 182 66.0147 182 68.5V79C182 80.1046 181.105 81 180 81H175C173.895 81 173 80.1046 173 79V68.5Z"
        fill={`url(#paint11_linear_1833_${status ? "31103" : "30754"})`}
      />
      <defs>
        <linearGradient
          id={`paint0_linear_1833_${status ? "31103" : "30754"}`}
          x1="6"
          y1="69"
          x2="6"
          y2="85.4444"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#E8450E" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#FCAE75" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_1833_${status ? "31103" : "30754"}`}
          x1="21"
          y1="-39"
          x2="21"
          y2="125.444"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#E8450E" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#FCAE75" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint2_linear_1833_${status ? "31103" : "30754"}`}
          x1="36"
          y1="30"
          x2="36"
          y2="99.8889"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#E8450E" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#FCAE75" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint3_linear_1833_${status ? "31103" : "30754"}`}
          x1="51"
          y1="-4.5"
          x2="51"
          y2="112.667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#E8450E" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#FCAE75" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint4_linear_1833_${status ? "31103" : "30754"}`}
          x1="66"
          y1="40.5"
          x2="66"
          y2="96"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#E8450E" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#FCAE75" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint5_linear_1833_${status ? "31103" : "30754"}`}
          x1="81"
          y1="21"
          x2="81"
          y2="103.222"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#E8450E" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#FCAE75" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint6_linear_1833_${status ? "31103" : "30754"}`}
          x1="96"
          y1="69"
          x2="96"
          y2="85.4444"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#E8450E" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#FCAE75" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint7_linear_1833_${status ? "31103" : "30754"}`}
          x1="111"
          y1="43.5"
          x2="111"
          y2="94.8889"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#E8450E" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#FCAE75" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint8_linear_1833_${status ? "31103" : "30754"}`}
          x1="126"
          y1="51"
          x2="126"
          y2="92.1111"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#E8450E" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#FCAE75" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint9_linear_1833_${status ? "31103" : "30754"}`}
          x1="143"
          y1="-25.5"
          x2="143"
          y2="120.444"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#E8450E" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#FCAE75" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint10_linear_1833_${status ? "31103" : "30754"}`}
          x1="160"
          y1="16.5"
          x2="160"
          y2="104.889"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#E8450E" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#FCAE75" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint11_linear_1833_${status ? "31103" : "30754"}`}
          x1="177"
          y1="55.5"
          x2="177"
          y2="90.4444"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#E8450E" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#FCAE75" : "#D1DAEF"} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default WarmUpRecoverSVGIcon;
