import React, { useEffect } from "react";
import { Layout, Popover } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../config/CommonFuntion";
import leftSideSVG from "../../assets/svg/leftSide.svg";
import helpSVG from "../../assets/svg/help.svg";
import programSVG from "../../assets/svg/program.svg";
import SubscriptionButtion from "../subscription/SubscriptionButtion";
import { getItem, setItem } from "../../utility/localStorageControl";
import { useSelector } from "react-redux";
import { userDetailsSave } from "../../redux/authentication/action";

// DEFINE MAIN FUNCTION
const PublicTopHeader = () => {
  // DEFINE ALL STATES
  //   const userdetails = useSelector((state) => state.auth.userDetails);
  const { Header } = Layout;
  const location = useLocation();
  const navigate = useNavigate();

  //   const onTemplateTitleValueChange = (value) => {
  //     setItem("templateTitle", value);
  //   };
  const settingView = getItem("userInfo");
  //   const userDetails = useSelector((state) => state.auth.userDetails);
  // DEFINE MAIN FUNCTION RETURN
  return (
    <Header className="dashboard-header public_dashboard_header">
      <div>
        <h4
          onClick={() => {
            if (
              location?.pathname.includes("/account") ||
              location.pathname == "/subscription/choose-plan" ||
              location.pathname.includes("/deliverability")
            ) {
              navigate(-1);
            }
          }}
          style={{
            cursor:
              location?.pathname.includes("/account") ||
                location.pathname == "/subscription/choose-plan" ||
                location.pathname.includes("/deliverability")
                ? "pointer"
                : "default",
          }}
        >
          {location?.pathname.includes("/account") ||
            location.pathname == "/subscription/choose-plan" ||
            location.pathname.includes("/deliverability") ? (
            <img src={leftSideSVG} />
          ) : (
            ""
          )}
          <span
            className={
              location?.pathname == "/templates/add"
                ? "template_title_header"
                : ""
            }
          >
            {location?.pathname.includes("/templates/")
              ? //   <input
              //     className="template_title"
              //     defaultValue={
              //       getItem("templateTitle")
              //         ? getItem("templateTitle")
              //         : "Untitled Template 1"
              //     }
              //     onChange={(event) => {
              //       onTemplateTitleValueChange(event.target.value);
              //     }}
              //   />
              ""
              : location?.pathname == "/"
                ? "Email Warmup"
                : location?.pathname.includes("/account")
                  ? settingView
                    ? "View Email Account"
                    : "Add Email Account"
                  : location.pathname == "/deliverability"
                    ? "Deliverability Test"
                    : location.pathname == "/subscription/choose-plan"
                      ? "Choose a plan"
                      : location.pathname.includes("/email-warmup")
                        ? "Email Warmup"
                        : location.pathname == "/templates"
                          ? "Templates"
                          : location.pathname.includes("/deliverability")
                            ? "Deliverability Test"
                            : location.pathname.includes("/billing-and-subscription")
                              ? "Billing and Subscription"
                              : location.pathname.includes("/setting")
                                ? "Settings"
                                : ""}
          </span>
        </h4>
      </div>

      {location.pathname !== "/subscription/choose-plan" &&
        !location.pathname.includes("/deliverability-test") &&
        location.pathname !== "/deliverability/create-new" ? (
        <div>
          <div className="progress-container">
            {/* <label>
              <span>
                {userdetails?.subscribed
                  ? userdetails?.subscriptions?.subscriptionPlan
                      ?.subscription_plan_validity == "month"
                    ? "Monthly"
                    : "Monthly"
                  : "Monthly"}{" "}
                Warmup Emails
              </span>
              <span>
                {userdetails.current_sending_mail_count}/
                {userdetails.maximum_email_send_count} Remaining (
                {Number(
                  (userdetails.current_sending_mail_count * 100) /
                    userdetails.maximum_email_send_count
                ).toFixed(2)}
                % Used)
              </span>
            </label> */}
            {/* <div className="progress">
              <div
                style={{
                  width: `${Number(
                    (userdetails.current_sending_mail_count * 100) /
                      userdetails.maximum_email_send_count
                  ).toFixed(2)}%`,
                }}
              ></div>
            </div> */}
          </div>
          <div className="navbar-part-stroke"></div>

          {/* <Popover
            overlayClassName="sidebar"
            placement="top"
            title={"Help Center "}
          >
            <Link to={"https://mailoptimal.tawk.help/"} target="_blank">
              <div className="navbar-part-icones">
                <img src={helpSVG} />
              </div>
            </Link>
          </Popover>
          <Popover
            overlayClassName="sidebar"
            placement="top"
            title={"Affiliate Program"}
          >
            <div className="navbar-part-icones">
              <img src={programSVG} />
            </div>
          </Popover> */}

          {/* {userdetails.free_trial && (
            <div>
              {" "}
              <div className="navbar-part-stroke"></div>
              <button
                onClick={() => navigate("/billing-and-subscription/plans")}
                size="large"
              >
                <span>Upgrade</span>
              </button>
            </div>
          )} */}
        </div>
      ) : (
        ""
      )}
    </Header>
  );
};
// DEFINE MAIN FUNCTION EXPORT
export default PublicTopHeader;
