import React from "react";

const WarmUpBuildSVGIcon = ({ status }) => {
  return (
    <svg
      width="180"
      height="80"
      viewBox="0 0 185 81"
      className="warm_up_mode_container_component_svg"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="-4.37114e-08" y1="80.5" x2="185" y2="80.5" stroke="#EAECF0" />
      <line
        x1="-2.18557e-08"
        y1="60.75"
        x2="185"
        y2="60.75"
        stroke="#EAECF0"
        strokeWidth="0.5"
        stroke-dasharray="2 2"
      />
      <line
        x1="-2.18557e-08"
        y1="40.75"
        x2="185"
        y2="40.75"
        stroke="#EAECF0"
        strokeWidth="0.5"
        stroke-dasharray="2 2"
      />
      <line
        x1="-2.18557e-08"
        y1="20.75"
        x2="185"
        y2="20.75"
        stroke="#EAECF0"
        strokeWidth="0.5"
        stroke-dasharray="2 2"
      />
      <line
        x1="-2.18557e-08"
        y1="0.750031"
        x2="185"
        y2="0.750014"
        stroke="#EAECF0"
        strokeWidth="0.5"
        stroke-dasharray="2 2"
      />
      <path
        d="M2 76.5C2 74.0147 4.01472 72 6.5 72C8.98528 72 11 74.0147 11 76.5V79.9412C11 80.5259 10.5259 81 9.94118 81H3.05882C2.47405 81 2 80.5259 2 79.9412V76.5Z"
        fill={`url(#paint0_linear_1833_${status ? "30725" : "31068"})`}
      />
      <path
        d="M17 74.5C17 72.0147 19.0147 70 21.5 70C23.9853 70 26 72.0147 26 74.5V79.7059C26 80.4206 25.4206 81 24.7059 81H18.2941C17.5794 81 17 80.4206 17 79.7059V74.5Z"
        fill={`url(#paint1_linear_1833_${status ? "30725" : "31068"})`}
      />
      <path
        d="M32 71.5C32 69.0147 34.0147 67 36.5 67C38.9853 67 41 69.0147 41 71.5V79.3529C41 80.2626 40.2626 81 39.3529 81H33.6471C32.7374 81 32 80.2626 32 79.3529V71.5Z"
        fill={`url(#paint2_linear_1833_${status ? "30725" : "31068"})`}
      />
      <path
        d="M47 68.5C47 66.0147 49.0147 64 51.5 64C53.9853 64 56 66.0147 56 68.5V79C56 80.1046 55.1046 81 54 81H49C47.8954 81 47 80.1046 47 79V68.5Z"
        fill={`url(#paint3_linear_1833_${status ? "30725" : "31068"})`}
      />
      <path
        d="M62 65.5C62 63.0147 64.0147 61 66.5 61C68.9853 61 71 63.0147 71 65.5V78.6471C71 78.9749 71 79.1388 70.9808 79.2763C70.8573 80.1615 70.1615 80.8573 69.2763 80.9808C69.1388 81 68.9749 81 68.6471 81H64.3529C64.0251 81 63.8612 81 63.7237 80.9808C62.8385 80.8573 62.1427 80.1615 62.0192 79.2763C62 79.1388 62 78.9749 62 78.6471V65.5Z"
        fill={`url(#paint4_linear_1833_${status ? "30725" : "31068"})`}
      />
      <path
        d="M77 62.5C77 60.0147 79.0147 58 81.5 58C83.9853 58 86 60.0147 86 62.5V78.2941C86 78.9507 86 79.279 85.9237 79.5473C85.7341 80.2135 85.2135 80.7341 84.5473 80.9237C84.279 81 83.9507 81 83.2941 81H79.7059C79.0493 81 78.721 81 78.4527 80.9237C77.7865 80.7341 77.2659 80.2135 77.0763 79.5473C77 79.279 77 78.9507 77 78.2941V62.5Z"
        fill={`url(#paint5_linear_1833_${status ? "30725" : "31068"})`}
      />
      <path
        d="M92 58.5C92 56.0147 94.0147 54 96.5 54C98.9853 54 101 56.0147 101 58.5V77.8235C101 78.9214 101 79.4704 100.79 79.8915C100.596 80.2809 100.281 80.5964 99.8915 80.7903C99.4704 81 98.9214 81 97.8235 81H95.1765C94.0786 81 93.5296 81 93.1085 80.7903C92.7191 80.5964 92.4036 80.2809 92.2097 79.8915C92 79.4704 92 78.9214 92 77.8235V58.5Z"
        fill={`url(#paint6_linear_1833_${status ? "30725" : "31068"})`}
      />
      <path
        d="M107 51.5C107 49.0147 109.015 47 111.5 47C113.985 47 116 49.0147 116 51.5V77C116 78.8856 116 79.8285 115.414 80.4142C114.828 81 113.886 81 112 81H111C109.114 81 108.172 81 107.586 80.4142C107 79.8285 107 78.8856 107 77V51.5Z"
        fill={`url(#paint7_linear_1833_${status ? "30725" : "31068"})`}
      />
      <path
        d="M122 42.5C122 40.0147 124.015 38 126.5 38C128.985 38 131 40.0147 131 42.5V77C131 78.8857 131 79.8285 130.414 80.4142C129.828 81 128.886 81 127 81H126C124.114 81 123.172 81 122.586 80.4142C122 79.8285 122 78.8856 122 77V42.5Z"
        fill={`url(#paint8_linear_1833_${status ? "30725" : "31068"})`}
      />
      <path
        d="M139 32.5C139 30.0148 141.015 28 143.5 28C145.985 28 148 30.0147 148 32.5V77C148 78.8856 148 79.8285 147.414 80.4142C146.828 81 145.886 81 144 81H143C141.114 81 140.172 81 139.586 80.4142C139 79.8285 139 78.8856 139 77V32.5Z"
        fill={`url(#paint9_linear_1833_${status ? "30725" : "31068"})`}
      />
      <path
        d="M156 18.5C156 16.0147 158.015 14 160.5 14C162.985 14 165 16.0147 165 18.5V77C165 78.8857 165 79.8285 164.414 80.4142C163.828 81 162.886 81 161 81H160C158.114 81 157.172 81 156.586 80.4142C156 79.8285 156 78.8857 156 77V18.5Z"
        fill={`url(#paint10_linear_1833_${status ? "30725" : "31068"})`}
      />
      <path
        d="M173 5.50003C173 3.01475 175.015 1.00003 177.5 1.00003C179.985 1.00003 182 3.01475 182 5.50003V77C182 78.8857 182 79.8285 181.414 80.4142C180.828 81 179.886 81 178 81H177C175.114 81 174.172 81 173.586 80.4142C173 79.8285 173 78.8857 173 77V5.50003Z"
        fill={`url(#paint11_linear_1833_${status ? "30725" : "31068"})`}
      />
      <defs>
        <linearGradient
          id={`paint0_linear_1833_${status ? "30725" : "31068"}`}
          x1="6"
          y1="67.5"
          x2="6"
          y2="86"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#FCAE75" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#E8450E" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_1833_${status ? "30725" : "31068"}`}
          x1="21"
          y1="64.5"
          x2="21"
          y2="87.1111"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#FCAE75" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#E8450E" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint2_linear_1833_${status ? "30725" : "31068"}`}
          x1="36"
          y1="60"
          x2="36"
          y2="88.7778"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#FCAE75" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#E8450E" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint3_linear_1833_${status ? "30725" : "31068"}`}
          x1="51"
          y1="55.5"
          x2="51"
          y2="90.4444"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#FCAE75" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#E8450E" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint4_linear_1833_${status ? "30725" : "31068"}`}
          x1="66"
          y1="51"
          x2="66"
          y2="92.1111"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#FCAE75" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#E8450E" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint5_linear_1833_${status ? "30725" : "31068"}`}
          x1="81"
          y1="46.5"
          x2="81"
          y2="93.7778"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#FCAE75" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#E8450E" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint6_linear_1833_${status ? "30725" : "31068"}`}
          x1="96"
          y1="40.5"
          x2="96"
          y2="96"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#FCAE75" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#E8450E" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint7_linear_1833_${status ? "30725" : "31068"}`}
          x1="111"
          y1="30"
          x2="111"
          y2="99.8889"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#FCAE75" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#E8450E" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint8_linear_1833_${status ? "30725" : "31068"}`}
          x1="126"
          y1="16.5"
          x2="126"
          y2="104.889"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#FCAE75" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#E8450E" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint9_linear_1833_${status ? "30725" : "31068"}`}
          x1="143"
          y1="1.50003"
          x2="143"
          y2="110.444"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#FCAE75" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#E8450E" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint10_linear_1833_${status ? "30725" : "31068"}`}
          x1="160"
          y1="-19.5"
          x2="160"
          y2="118.222"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#FCAE75" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#E8450E" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint11_linear_1833_${status ? "30725" : "31068"}`}
          x1="177"
          y1="-39"
          x2="177"
          y2="125.444"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#FCAE75" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#E8450E" : "#D1DAEF"} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default WarmUpBuildSVGIcon;
