import React from "react";

const ProfileUserIcons = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M12.1188 12.7805C12.0488 12.7705 11.9588 12.7705 11.8788 12.7805C10.1188 12.7205 8.71875 11.2805 8.71875 9.51047C8.71875 7.70047 10.1787 6.23047 11.9988 6.23047C13.8088 6.23047 15.2788 7.70047 15.2788 9.51047C15.2688 11.2805 13.8788 12.7205 12.1188 12.7805Z"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.34"
        d="M18.7378 19.3805C16.9578 21.0105 14.5978 22.0005 11.9978 22.0005C9.39781 22.0005 7.03781 21.0105 5.25781 19.3805C5.35781 18.4405 5.95781 17.5205 7.02781 16.8005C9.76781 14.9805 14.2478 14.9805 16.9678 16.8005C18.0378 17.5205 18.6378 18.4405 18.7378 19.3805Z"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ProfileUserIcons;
