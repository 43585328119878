// DEFINE ALL EXTERNAL IMPORT
import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// DEFINE ALL INTERNAL IMPORT
import EmailSetUpScore from "./deliverability/EmailSetUpScore";
import InboxPlacementScore from "./deliverability/InboxPlacementScore";
import styles from "../admin/deliverability/deliverability.module.css";
import DeliverabilityDetails from "./deliverability/DeliverabilityDetails";
import SubscriptionButtion from "../../component/subscription/SubscriptionButtion";
import greenCheck from "../../assets/deleverability/greenCheck.png";
import GoogleSVGIcon from "../../assets/dashboard/GoogleSVGIcon";
import SMTPSVGIcon from "../../assets/dashboard/SMTPSVGIcon";
import MicrisoftSVGIcon from "../../assets/dashboard/MicrisoftSVGIcon";
import { getItem, setItem } from "../../utility/localStorageControl";
import { EmailActiveWarmupOn } from "../../state/EmailConnect/Emailconnect.api";
import { deliverabilityTestDataById } from "../../redux/deliverabilityTest/action";
import { capitalizeFirstWord } from "../../config/CommonFuntion";

const PublicDeliverability = () => {
  // DEFINE ALL STATES
  const { id } = useParams();
  console.log("id", id);
  const location = useLocation();
  const [deleverybityData, setDeleverybityData] = useState();
  const [loader, setLoader] = useState(false);
  // const [id, setId] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchDataDeliverabilityDataById = async () => {
    setLoader(true);
    try {
      if (id) {
        const result = await dispatch(deliverabilityTestDataById(id));
        if (result) {
          console.log("fetchDataDeliverabilityTest success");
          setDeleverybityData(result);
          setLoader(false);
        } else {
          console.log("fetchDataDeliverabilityTest failed");
          setLoader(false);
        }
      }
    } catch (error) {
      console.log("error", error);
      setLoader(false);
    }
  };
  useEffect(() => {
    fetchDataDeliverabilityDataById();
  }, [id]);

  // DEFINE MAIN FUNCTION Return
  return (
    <>
      <div className={styles.publicDeliverabilityTestMainContainer}>
        <div className={styles.headerTitleSubject}>
          <p>{capitalizeFirstWord(deleverybityData?.subject)}</p>
        </div>
        <Row justify={"space-between"} className={styles.userInfo}>
          <Col>
            <div className={styles.username}>
              <h4>{deleverybityData?.emailAccount?.sender_username}</h4>
              <div>
                {deleverybityData?.emailAccount?.type == "google" ? (
                  <GoogleSVGIcon className={styles.EmailProviderIcons} />
                ) : deleverybityData?.emailAccount?.type == "smtp_imap" ? (
                  <SMTPSVGIcon className={styles.EmailProviderIcons} />
                ) : (
                  <MicrisoftSVGIcon className={styles.EmailProviderIcons} />
                )}
              </div>
            </div>

            <div className={styles.userMain}>
              <a href="mailto:tienlapspktnd@gmail.com">
                {deleverybityData?.emailAccount?.sender_email}
              </a>
              <img src={greenCheck} alt="" />
            </div>
          </Col>
          <Col>
            {/* <SubscriptionButtion
            loading={loader}
            onClick={warmupOn}
            btnText={"Start Warm-Up"}
          /> */}
          </Col>
        </Row>
        <Row gutter={{ xs: 12, sm: 24 }}>
          <EmailSetUpScore score={deleverybityData?.domainHealthScore} />
          <InboxPlacementScore score={deleverybityData?.inboxPlacementScore} />
        </Row>
        <Row className={styles.collepseMainContainer}>
          <DeliverabilityDetails deleverybityData={deleverybityData} />
        </Row>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default PublicDeliverability;
