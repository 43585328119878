import { API } from "../../config/api/Index";
import { message } from "antd";
import { DataService } from "../../config/DataService";
import { setItem } from "../../utility/localStorageControl";

// TEST SMTP API CALL
export const testSMTP = (payloads) => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.post(API.test.smtp, payloads);
      if (loggedIn.data.status) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// TEST SMTP API CALL
export const testIMAP = (payloads) => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.post(API.test.imap, payloads);
      if (loggedIn.data.status) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// CONNECT EMAIL ACCOUNT API CALL
export const connectAccount = (payloads) => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.post(API.test.connect, payloads);
      if (loggedIn.data.status) {
        return true;
      } else {
        message.error(loggedIn.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// EDIT CONNECT EMAIL ACCOUNT API CALL
export const editConnectAccount = (payloads, id) => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.put(
        API.test.editConnect + id,
        payloads
      );
      if (loggedIn.data.status) {
        return true;
      } else {
        message.error(loggedIn.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// CONNECT EMAIL ACCOUNT SETTING API CALL
export const warmupAccountSetting = (id) => {
  return async (dispatch) => {
    try {
      const res = await DataService.get(`${API.test.warmupSetting}/${id}`);

      if (res.data.status) {
        dispatch({
          type: API.test.warmupSetting,
          warmupSettingData: res.data.data,
          userSettingView: true,
        });
        setItem("userInfo", true);

        return res.data.data;
      } else {
        message.error(res.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
