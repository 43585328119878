import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import "../DeleteEmailModal/DeleteEmailModal.css";
import { Modal, Spin } from "antd";

// DEFINE MAIN FUNCTION
const WarmupConfirm = ({
  WarmupConfirmModal,
  onClose,
  selectedValue,
  onSubmit,
  loading,
  type,
}) => {
  // DEFINE MAIN FUNCTION RETURN
  return (
    <div>
      <Modal
        open={WarmupConfirmModal}
        className={`confirmModal ${type == "template" ? "template_modal" : ""}`}
        footer={null}
        centered
        width={450}
        onCancel={() => {
          onClose();
        }}
        maskStyle={{
          backdropFilter: "blur(8px) saturate(35%)",
          backgroundColor: "rgba(16, 24, 40, 0.35)",
        }}
      >
        <div className="deleteModalContent">
          <div className="confirmModalHeader">
            <span>
              {type == "template"
                ? "Inactive Confirmtion"
                : "Are you sure you want to turn off warmup for this email account?"}
            </span>
          </div>
          <div className="modalDesc">
            <span>
              {type == "template"
                ? "Are you sure you want to inactive this template?"
                : "Email warmup helps you build and maintain optimal deliverability. Turning it off may put your deliverability at risk."}
            </span>
          </div>
        </div>
        <div className="confirmModalFooter">
          <button
            onClick={() => {
              if (!loading) {
                onSubmit(selectedValue?.id, false, false);
              }
            }}
          >
            {loading ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      color: "white",
                    }}
                    spin
                  />
                }
              />
            ) : (
              "Confirm"
            )}
          </button>
          <button
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

// DEFINE MAIN FUNCTION EXP0ORT
export default WarmupConfirm;
