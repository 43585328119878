import { API } from "../../config/api/Index";

// ALL TYPE WISE DATA RETURN
const initState = {
  emailListCounts: {
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
  emailList: null,
  emailListForTemplate: [],
  personalizationList: [],
  emailAnalyticsDetails: {},
  deliverabilityAnalyticsDetails: {},
  typeData: {
    type: [],
    total: {
      send_today: 0,
      receive_today: 0,
    },
  },
  emailTemplateList: [],
  newEmailList: []
};

// DEFINE AUTH REDUCER FUNCTION
const dashboardReducer = (state = initState, action) => {
  switch (action.type) {
    case API.dashboard.emailList:
      return {
        ...state,
        emailList: action.emailList,
        emailListCounts: action.emailListCounts,
        emailListForTemplate: action.emailListForTemplate,
      };
    case API.personalization.list:
      return {
        ...state,
        personalizationList: action.personalizationList,
      };
    case API.dashboard.emailAnalytics:
      return {
        ...state,
        emailAnalyticsDetails: action.emailAnalyticsDetails,
        typeData: action.typeData,
      };
    case API.dashboard.deliverabilityAnalytics:
      return {
        ...state,
        deliverabilityAnalyticsDetails: action.deliverabilityAnalyticsDetails,
        typeData: action.typeData,
      };
    case API.dashboard.templateList:
      return {
        ...state,
        emailTemplateList: action.emailTemplateList,
      };
    case API.dashboard.newEmailList:
      return {
        ...state,
        newEmailList: action.newEmailList,
      };
    default:
      return state;
  }
};

// EXPORT ALL FUNCTION
export default dashboardReducer;
