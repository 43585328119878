import { message } from "antd";
import { DataService } from "../../config/DataService";
import { API } from "../../config/api/Index";
import { setItem } from "../../utility/localStorageControl";
import { userDetailsSave } from "../../redux/authentication/action";

export const getUserDetail = async (id,dispatch) => {
  try {
    if (id) {
      const res = await DataService.get(API.auth.userDetail + id);
      if (res.data.status) {
        setItem("userDetails", res.data.data);
        dispatch(userDetailsSave(res.data.data))
        return res.data.data;
      } else {
        message.error(res.data.message);
        return false;
      }
    }
  } catch (err) {
    return false;
  }
};
