import { API } from "../../config/api/Index";
import { message } from "antd";
import { DataService } from "../../config/DataService";
import { encodeQueryParams } from "../../config/CommonFuntion";

// CREATE TEMPLATE API CALL
export const createTemplate = (payloads) => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.post(API.template.create, payloads);
      if (loggedIn.data.status) {
        message.success(loggedIn.data.message);
        return true;
      } else {
        message.error(loggedIn.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// EDIT TEMPLATE API CALL
export const editTemplate = (payloads, id) => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.put(
        API.template.update + id,
        payloads
      );
      if (loggedIn.data.status) {
        message.success(loggedIn.data.message);
        return true;
      } else {
        message.error(loggedIn.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// TEMPLATE LIST API CALL
export const templateList = (queryStrings) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        API.template.list + encodeQueryParams(queryStrings)
      );
      if (response.data.status) {
        dispatch({
          type: API.template.list,
          templateList: response.data.data,
          templateListCounts: {
            currentPage: response.data.currentPage,
            totalCount: response.data.totalCount,
            totalPages: response.data.totalPages,
          },
        });
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// DELETE TEMPLATE API CALL
export const templateDelete = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(API.template.delete + "/" + id);
      if (response.data.status) {
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// DETAILS TEMPLATE API CALL
export const getTemplateDetails = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(API.template.details + "/" + id);
      if (response.data.status) {
        return { status: true, data: response.data.data };
      } else {
        message.error(response.data.message);
        return { status: false };
      }
    } catch (err) {
      return { status: false };
    }
  };
};

// TEMPLATE STATUS UPDATE ON API CALL
export const templateStatusUpdate = (id, value) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        API.template.updateStatus + "/" + id,
        {
          is_active: value,
        }
      );
      if (response.data.status) {
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
