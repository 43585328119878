import { createStore, applyMiddleware, compose } from "redux";
import { withExtraArgument } from "redux-thunk";
import rootReducers from "./RootReducers";

// DEFINE MAIN FUNCTION
const store = createStore(
  rootReducers,
  compose(applyMiddleware(withExtraArgument()))
);

// DEFINE MAIN FUNCTION EXPORT
export default store;
