import { Modal, Checkbox, Row, Divider } from "antd";
import React, { useEffect, useState } from "react";
import style from "./PurchaseCreditModal.module.css";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  creditPlanList,
  purchaseCredit,
} from "../../../../../redux/credit-plan/action";

const PurchaseCreditModal = ({ purchaseCreditModal, onClose }) => {
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    list();
  }, []);
  const list = async () => {
    const response = await dispatch(creditPlanList());
    if (response) {
      console.log("response of purchaseCreditModal", response);
    }
  };
  // DEFINE ALL LISTS WITH REDUX
  let listOfPlan = useSelector((state) => state.creditPlan.creditPlanList);
  console.log("listOfPlan", listOfPlan);

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };
  const handleBuyNow = async () => {
    if (selectedPlanId) {
      let urlRedirect =
        "https://app.mailoptimal.com" + window.location.pathname;
      let payload = {
        credit_plan_id: selectedPlanId,
        redirect_url: urlRedirect,
        credit_product_id: selectedProductId
      };
      const response = await dispatch(purchaseCredit(payload));
      console.log("response", response)
      if (response) {
        window.location.replace(response?.data?.url);
        // window.location.reload()
      }
    } else {
      console.log("No plan selected.");
    }
  };
  const handleClick = (planId) => {
    setSelectedPlanId(planId.id);
    setSelectedProductId(planId?.creditProduct[0]?.id);

  };
  return (
    <div>
      <Modal
        open={purchaseCreditModal}
        className={style.activeModal}
        footer={null}
        title="Purchase Credits"
        centered
        width={450}
        onCancel={() => {
          onClose();
        }}
        maskStyle={{
          backdropFilter: "blur(8px) saturate(35%)",
          backgroundColor: "rgba(16, 24, 40, 0.35)",
        }}
      >
        <Divider />
        {listOfPlan && listOfPlan.length > 0 ? (
          <>
            {listOfPlan.map((plan) => (
              <div
                key={plan.id}
                className={`${style.activeModalContent} ${selectedPlanId === plan.id ? style.active : ""
                  }`}
                onClick={() => handleClick(plan)}
              >
                <Row justify={"space-between"}>
                  <div>{plan.credit} Credits</div>
                  {plan.tag !== "" ? (
                    <div className={style.DomainSetupDetailsLabel}>
                      <button>
                        <span>{plan.tag}</span>
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                  <div>
                    {plan.creditProduct[0].iso_code == "US" ? "$" : '₹'}{" "}
                    {plan.creditProduct[0].amount}
                  </div>
                </Row>
              </div>
            ))}
          </>
        ) : (
          ""
        )}
        <Checkbox onChange={onChange} className={style.radio}>
          <span>
            By purchasing credits, I agree that email verification credits are
            non-refundable.
          </span>
        </Checkbox>
        <div className={style.ActiveModalFooter}>
          <button
            disabled={selectedPlanId ? false : true}
            onClick={() => {
              handleBuyNow();
              // onClose();
            }}
          >
            Buy Now
          </button>
          <button
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default PurchaseCreditModal;
