import React, { useEffect, useState } from "react";
import style from "./ConnectGoogle.module.css";
import { Form, Input, Spin, Checkbox, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  connectAccount,
  editConnectAccount,
  testIMAP,
  testSMTP,
  warmupAccountSetting,
} from "../../../redux/connect-account/action";
import successSVG from "../../../assets/svg/success.svg";
import failSVG from "../../../assets/svg/fail.svg";
import rightIcon from "../../../assets/Email/notification-right.png";
import GoogleSVGIcon from "../../../assets/dashboard/GoogleSVGIcon";

// DEFINE MAIN FUNCTION
const ConnectGoogle = () => {
  // DEFINE ALL STATES
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formValidateStatus, setFormValidateStatus] = useState(false);
  const [showUserNameField, setShowUserNameField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [testSMTPLoading, setTestSMTPLoading] = useState(false);
  const [testSMTPValidation, setTestSMTPValidation] = useState("");
  const [testIMAPValidation, setTestIMAPValidation] = useState("");
  const [testIMAPLoading, setTestIMAPLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchWarmupSettings();
    }
  }, [id]);

  const fetchWarmupSettings = async () => {
    try {
      let response = await dispatch(warmupAccountSetting(id));
      if (response) {
        form.setFieldValue("sender_username", response.sender_username);
        form.setFieldValue("sender_password", response.sender_password);
        form.setFieldValue("sender_email", response.sender_email);
        form.setFieldValue(
          "sender_different_username",
          response.use_different_sender_name
        );
        onCheckFormChangeValue();
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  // DEFINE FUNCTION FOR FORM SUBMIT
  const onFinish = async (values) => {
    setLoading(true);
    let payload = {
      ...values,
      type: "google",
      use_different_sender_name: values.sender_different_username
        ? true
        : false,
    };
    if (id) {
      let response = await dispatch(editConnectAccount(payload, id));
      if (response) {
        navigate("/");
        setLoading(false);
        notification.success({
          message: (
            <span className="notificationText">
              Congratulations. Your email account is updated successfully.
            </span>
          ),
          description: (
            <span className="startBorder" style={{ height: "88px" }}></span>
          ),
          icon: <img src={rightIcon} alt="right-icon" width={20} height={20} />,
          className: "custom-notification",
          duration: 3,
          style: { width: "362px", height: "88px" },
        });
      } else {
        setLoading(false);
      }
    } else {
      let response = await dispatch(connectAccount(payload));
      if (response) {
        navigate("/");
        setLoading(false);
        notification.success({
          message: (
            <span className="notificationText">
              Congratulations. Your email account is connected successfully.
            </span>
          ),
          description: (
            <span className="startBorder" style={{ height: "88px" }}></span>
          ),
          icon: <img src={rightIcon} alt="right-icon" width={20} height={20} />,
          className: "custom-notification",
          duration: 3,
          style: { width: "362px", height: "88px" },
        });
      } else {
        setLoading(false);
      }
    }
  };

  // DEFINE FUNCTION FOR SMTP TEST FUNCTION
  const onClickSMTPTest = async () => {
    setTestSMTPLoading(true);
    let payload = {
      type: "google",
      sender_email: form.getFieldsValue()?.sender_email,
      sender_password: form.getFieldsValue()?.sender_password,
      sender_host: "string",
      sender_port: "string",
      sender_encryption_type: "string",
      sender_different_username:
        form.getFieldsValue()?.sender_different_username,
    };
    let response = await dispatch(testSMTP(payload));
    if (response) {
      setTestSMTPValidation("succcess");
      setTestSMTPLoading(false);
    } else {
      setTestSMTPValidation("fail");
      setTestSMTPLoading(false);
    }
  };

  // DEFINE FUNCTION FOR IMAP TEST FUNCTION
  const onClickIMAPTest = async () => {
    console.log(
      "Sagaratfasdasasasasasasasa",
      form.getFieldsValue()?.sender_different_username
    );
    setTestIMAPLoading(true);
    let payload = {
      type: "google",
      receiver_email: form.getFieldsValue()?.sender_different_username
        ? form.getFieldsValue()?.sender_different_username
        : form.getFieldsValue()?.sender_email,
      receiver_password: form.getFieldsValue()?.sender_password,
      receiver_host: "string",
      receiver_port: "string",
      receiver_encryption_type: "string",
    };
    let response = await dispatch(testIMAP(payload));
    if (response) {
      setTestIMAPLoading(false);
      setTestIMAPValidation("succcess");
    } else {
      setTestIMAPValidation("fail");
      setTestIMAPLoading(false);
    }
  };

  // DEFINE FUNCTION FOR FORM ONCHANGE VALUES VALIDATIONS CHECK
  const onCheckFormChangeValue = () => {
    setTestIMAPValidation("");
    setTestSMTPValidation("");
    const validationErrors = form.getFieldsError();
    const validationValues = Object.values(form.getFieldsValue());
    if (
      !validationValues.includes(undefined) &&
      !validationValues.includes("") &&
      validationErrors.find((err) => err.name == "sender_email").errors
        .length == 0
    ) {
      setFormValidateStatus(true);
    } else {
      setFormValidateStatus(false);
    }
  };

  // RETURN MAIN FUNCTION
  return (
    <>
      <div className={style.googleComponent}>
        <div className={style.googleFromSection}>
          <div className={style.icone}>
            <GoogleSVGIcon />
          </div>
          <label className={style.emailTypeName}>Google</label>
          <span className={style.descriptionforConnect}>
            Connect your Gmail account
          </span>
          <Form
            layout="vertical"
            name="basic"
            size="large"
            requiredMark={false}
            onFinish={(value) => {
              if (!loading) {
                onFinish(value);
              }
            }}
            form={form}
            onValuesChange={(value) => {
              onCheckFormChangeValue(value);
            }}
            autoFocus={false}
            autoComplete="off"
          >
            <Form.Item
              label={"Sender Name"}
              name="sender_username"
              rules={[
                {
                  required: true,
                  message: "Please enter name!",
                },
              ]}
            >
              <Input placeholder="Enter Name" />
            </Form.Item>

            {showUserNameField ? (
              <Form.Item
                className="connect-email-google-form"
                label={
                  <div className={style.checkBoxFieldLabel}>
                    <span>Username</span>
                    <label className={style.description}>
                      <Checkbox
                        checked={showUserNameField}
                        onChange={(e) => {
                          setShowUserNameField(e.target.checked);
                        }}
                      >
                        Use different username
                      </Checkbox>
                    </label>
                  </div>
                }
                name="sender_different_username"
                rules={[
                  {
                    required: true,
                    message: "Please enter user name!",
                  },
                ]}
              >
                <Input placeholder="Enter username" />
              </Form.Item>
            ) : (
              ""
            )}

            <Form.Item
              className="connect-email-google-form"
              label={
                <div className={style.checkBoxFieldLabel}>
                  <span>Email Address</span>
                  {!showUserNameField ? (
                    <label className={style.description}>
                      <Checkbox
                        checked={showUserNameField}
                        onChange={(e) => {
                          setShowUserNameField(e.target.checked);
                          if (
                            !form.getFieldValue("sender_different_username")
                          ) {
                            setFormValidateStatus(false);
                          }
                        }}
                      >
                        Use different username
                      </Checkbox>
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              }
              name="sender_email"
              rules={[
                {
                  required: true,
                  message: "Please enter your email!",
                },
                {
                  type: "email",
                  message: "Please enter valid email!",
                },
              ]}
            >
              <Input placeholder="Enter Email" />
            </Form.Item>
            <Form.Item
              label="App Password"
              name="sender_password"
              rules={[
                {
                  required: true,
                  message: "Please enter your app password!",
                },
              ]}
            >
              <Input.Password placeholder="Enter App Password" />
            </Form.Item>
          </Form>

          <div className={style.buttonsContainer}>
            <div
              onClick={() => {
                navigate("/account");
              }}
              className={style.cancelButton}
            >
              Cancel
            </div>
            <button
              type="submit"
              onClick={form.submit}
              style={{
                opacity: formValidateStatus ? "100%" : "35%",
              }}
            >
              {loading ? <Spin /> : "Connect"}
            </button>
          </div>
        </div>
        <div className={style.googleInfoComponent}>
          <div
            className={`${style.instractions} ${
              testSMTPValidation == "fail" || testIMAPValidation == "fail"
                ? style.failInstractions
                : ""
            }`}
          >
            <span>Instructions to connect SMTP/IMAP</span>{" "}
            <label>1. Make sure SMTP is enabled for your email account.</label>{" "}
            <label>
              2. Disable 2FA (multi-factor authentication). If your provider
              allows, use app-password instead of regular mailbox password.
            </label>{" "}
            <label>
              3. Use correct SMTP details like host & port to connect your email
              account. Click here to know SMTP details for popular email
              providers.
            </label>
            <label>4. Make sure SMTP is enabled for your email account.</label>
            <label>
              5. Disable 2FA (multi-factor authentication). If your provider
              allows, use app-password instead of regular mailbox password.{" "}
            </label>
            <label>
              6. Use correct SMTP details like host & port to connect your email
              account. Click here to know SMTP details for popular email
              providers.
            </label>
          </div>

          <div>
            <button
              onClick={() => {
                if (formValidateStatus && !testSMTPLoading) {
                  onClickSMTPTest();
                }
              }}
              style={{
                opacity: formValidateStatus ? "100%" : "35%",
              }}
            >
              {testSMTPLoading ? <Spin /> : "Test SMTP"}
            </button>
            <button
              style={{
                opacity: formValidateStatus ? "100%" : "35%",
              }}
              onClick={() => {
                if (formValidateStatus && !testIMAPLoading) {
                  onClickIMAPTest();
                }
              }}
            >
              {testIMAPLoading ? <Spin /> : "Test IMAP"}
            </button>
          </div>

          {testSMTPValidation == "succcess" ? (
            <span className={style.successComponent}>
              <img src={successSVG} />
              <span className={style.success}>
                SMTP has been connected successfully!
              </span>
            </span>
          ) : testSMTPValidation == "fail" ? (
            <span id={style.fail} className={style.successComponent}>
              <img src={failSVG} />
              <span className={style.fail}>SMTP Failed to connect</span>
            </span>
          ) : (
            ""
          )}

          {testIMAPValidation == "succcess" ? (
            <span className={style.successComponent}>
              <img src={successSVG} />
              <span className={style.success}>
                IMAP has been connected successfully!
              </span>
            </span>
          ) : testIMAPValidation == "fail" ? (
            <span className={style.successComponent}>
              <img src={failSVG} />
              <span className={style.fail}>IMAP Failed to connect</span>
            </span>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default ConnectGoogle;
