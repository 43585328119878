import { message } from "antd";
import { DataService } from "../../config/DataService";
import { API } from "../../config/api/Index";

// CONNECT MICROSOFT API CALL
export const MicroSoftConnect = async () => {
  try {
    const res = await DataService.get(API.test.microsoftconnect);
    if (res.data.status) {
      return res.data.data;
    } else {
      message.error(res.data.message);
      return false;
    }
  } catch (err) {
    return false;
  }
};

// DELETE MICROSOFT API CALL
export const EmailConnectDelete = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(API.email.delete + "/" + id);
      if (response.data.status) {
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// DISCONNECT EMAIL API CALL
export const EmailDisconnect = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(API.email.disconnect + "/" + id, {
        is_connect: false,
      });
      if (response.data.status) {
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// AGAIN CONFIRM MESSAGE SHOW API CALL
export const updateAgainMessageShow = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        API.email.updateAgainShow,
        payload
      );
      if (response.data.status) {
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// EMAIL WARMUP ON API CALL
export const EmailActiveWarmupOn = (id, value) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        API.email.updateWarmup + "/" + id,
        {
          email_warmup: value,
        }
      );
      if (response.data.status) {
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
