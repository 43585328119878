import React, { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import "./SourseCodeModal.css";
import { useMutationEmailDelete } from "../../../state/EmailConnect/Emailconnect.hook";
import Input from "antd/es/input/Input";

// DEFINE MAIN FUNCTION
const SourseCodeModal = ({
  sourseCodeModal,
  onClose,
  onSubmit,
  selectedValue,
}) => {
  // DEFINE ALL STATES
  const [form] = Form.useForm();
  const { mutate: connectMicroSoft } = useMutationEmailDelete(
    selectedValue?.id,
    onSubmit
  );
  const [htmlCode, setHtmlCode] = useState("");

  useEffect(() => {
    if (selectedValue) {
      form.setFieldsValue({ htmlCode: selectedValue });
      setHtmlCode(selectedValue);
    }
  }, []);
  // DEFINE FUNCTION FOR HTML CODE SUBMIT
  const handleSubmit = () => {
    onSubmit(htmlCode);
    onClose();
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <div>
      <Modal
        open={sourseCodeModal}
        className={"sourseModal"}
        footer={null}
        centered
        width={1000}
        onCancel={() => {
          onClose();
        }}
        maskStyle={{
          backdropFilter: "blur(8px) saturate(35%)",
          backgroundColor: "rgba(16, 24, 40, 0.35)",
        }}
      >
        <div className="sourseModalContent">
          <div className="modalHeader">
            <span>Source code</span>
          </div>
          <Form layout="vertical" form={form}>
            <Form.Item name="htmlCode">
              <Input.TextArea
                placeholder="Write your code here"
                value={htmlCode}
                onChange={(e) => setHtmlCode(e.target.value)}
              ></Input.TextArea>
            </Form.Item>
          </Form>
        </div>
        <div className="sourseModalFooter">
          <button
            onClick={() => {
              handleSubmit();
            }}
          >
            Confirm
          </button>
          <button
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default SourseCodeModal;
