import React, { useEffect, useState } from "react";
import { Form, Input, Spin } from "antd";
import logo from "../../assets/logo.svg";
import { useNavigate } from "react-router-dom";
import { logOut, resetPassword } from "../../redux/authentication/action";
import { useDispatch } from "react-redux";
import style from "./AuthFiles.module.css";

// DEFINE MAIN FUNCTION
const ResetPassword = () => {
  // DEFINE ALL STATES
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const [formValidateStatus, setFormValidateStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  // DEFINE USEEFFECT FOR CHECK URL IN TOKEN OR NOT
  useEffect(() => {
    if (!urlParams.get("token")) {
      navigate("/login");
    } else {
      clearData();
    }
  }, []);

  // DEFINE LOGOUT DATA
  const clearData = async () => {
    await dispatch(logOut(false));
  };

  // DEFINE FUNCTION FOR FORM SUBMIT
  const onFinish = async (values) => {
    setLoading(true);
    let response = await dispatch(
      resetPassword({ ...values, token: urlParams.get("token") })
    );
    if (response) {
      navigate("/login");
      setLoading(false);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  };

  // DEFINE FUNCTION FOR FORM ONCHANGE VALUES VALIDATIONS CHECK
  const onCheckFormChangeValue = (changedValues, allValues) => {
    if (
      !Object.values(allValues).includes(undefined) &&
      !Object.values(allValues).includes("")
    ) {
      const regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+{}|:"<>?`\-=[\]\\;',./]{8,64}$/;
      if (!allValues.new_password || regex.test(allValues.new_password)) {
        if (allValues.new_password == allValues.confirm_password) {
          setFormValidateStatus(true);
        } else {
          setFormValidateStatus(false);
        }
      } else {
        setFormValidateStatus(false);
      }
    } else {
      setFormValidateStatus(false);
    }
  };

  // DEFINE FUNCTION FOR CUSTOM VALIDATION PASSWORD
  const validatePassword = (rule, value, callback) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+{}|:"<>?`\-=[\]\\;',./]{8,64}$/;
    if (!value || regex.test(value)) {
      callback();
    } else {
      callback(
        "please enter strog password, one uppercase character, one numerical character, one lowercase character, characters length between 8 - 64"
      );
    }
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <div className={style.resetPasswordComponent}>
        <div className={style.detailsComponent}>
          <img src={logo} alt="logo" />
          <h2 className={style.title}>Set New Password 🔒</h2>
          <p className={style.description}>Let’s set a strong new password</p>
        </div>
        <Form
          layout="vertical"
          name="basic"
          size="large"
          requiredMark={false}
          form={form}
          onFinish={(value) => {
            if (!loading) {
              onFinish(value);
            }
          }}
          onValuesChange={(value, allValues) => {
            onCheckFormChangeValue(value, allValues);
          }}
          autoComplete="off"
        >
          <Form.Item
            label="New Password"
            name="new_password"
            rules={[
              {
                required: true,
                message: "Please input your New Password!",
              },
              { validator: validatePassword },
            ]}
            hasFeedback
          >
            <Input.Password placeholder="Enter New Password" />
          </Form.Item>
          <Form.Item
            label="Confirm New Password"
            name="confirm_password"
            rules={[
              {
                required: true,
                message: "Please input your Confirm New Password!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The two passwords that you entered do not match!"
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Enter Confirm New Password" />
          </Form.Item>

          <Form.Item className={style.submitButton}>
            <button
              type="submit"
              typeof="submit"
              style={{
                opacity: formValidateStatus ? "100%" : "35%",
              }}
            >
              {loading ? <Spin /> : "Done"}
            </button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default ResetPassword;
