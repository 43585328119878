import { Button, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import { getItem } from "../../utility/localStorageControl";
import { useDispatch } from "react-redux";
import logo from "../../assets/logo.svg";
import selectedSVG from "../../assets/svg/selected.svg";
import style from "./AuthFiles.module.css";
import { updateFinalQuestions } from "../../redux/authentication/action";

// DEFINE MAIN FUNCTION
const SignUpAboutQuestions = () => {
  // DEFINE ALL STATES
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [loading, setLoading] = useState(false);
  const [optionSelect, setOptionSelect] = useState("");

  // DEFINE USEEFFECT
  useEffect(() => {
    setWidth(ref.current.offsetWidth);
    const getwidth = () => {
      setWidth(ref.current.offsetWidth);
    };
    window.addEventListener("resize", getwidth);
    return () => window.removeEventListener("resize", getwidth);
  }, []);

  // DEFINE USEEFFECT FOR FORM VALUES VALIDATE
  useEffect(() => {
    if (getItem("userDetails")) {
      if (getItem("userDetails")?.signup_step == 2) {
        setOptionSelect(
          getItem("userDetails")?.about_us_from
            ? getItem("userDetails")?.about_us_from
            : ""
        );
      } else {
        navigate("/signup");
      }
    } else {
      navigate("/signup");
    }
  }, []);

  // DEFINE FUNCTION FOR SELECTED VALUE SUBMIT
  const onSubmit = async (value) => {
    setLoading(true);
    await dispatch(
      updateFinalQuestions({
        about_us_from: value,
      })
    );
    setLoading(false);
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <div className={style.questionComponent}>
        <header ref={ref} className={style.questionHeader}>
          <div className={style.backbutton}>
            <Button
              icon={<ArrowLeftOutlined />}
              size="large"
              onClick={() => {
                navigate(-1);
              }}
            >
              {" "}
              Back
            </Button>
            <div>
              <div className={style.logo}>
                <img src={logo} />
                MailOptimal
              </div>
            </div>
          </div>
        </header>
        <div
          className="signup-question-progress"
          style={{ borderBottom: "4px solid #F75F18", width: "100%" }}
        ></div>
        <div
          // style={{ maxHeight: "1200px", overflow: "auto" }}
          className={style.scrollDiv}
        >
          <div className={style.questionsSection}>
            <div className={style.section}>
              <h3 className={style.title}>How did you hear about us?</h3>
              <div className={style.questionButtons}>
                <button
                  onClick={() => {
                    setOptionSelect("Google Search");
                  }}
                  className={
                    optionSelect == "Google Search" ? style.selected : ""
                  }
                >
                  {optionSelect == "Google Search" ? (
                    <img src={selectedSVG} />
                  ) : (
                    ""
                  )}
                  <span>Google Search</span>
                </button>
                <button
                  onClick={() => {
                    setOptionSelect("Facebook community");
                  }}
                  className={
                    optionSelect == "Facebook community" ? style.selected : ""
                  }
                >
                  {optionSelect == "Facebook community" ? (
                    <img src={selectedSVG} />
                  ) : (
                    ""
                  )}
                  <span>Facebook community</span>
                </button>
                <button
                  onClick={() => {
                    setOptionSelect("LinkedIn");
                  }}
                  className={optionSelect == "LinkedIn" ? style.selected : ""}
                >
                  {optionSelect == "LinkedIn" ? <img src={selectedSVG} /> : ""}
                  <span>LinkedIn</span>
                </button>
                <button
                  onClick={() => {
                    setOptionSelect("Cold email");
                  }}
                  className={optionSelect == "Cold email" ? style.selected : ""}
                >
                  {optionSelect == "Cold email" ? (
                    <img src={selectedSVG} />
                  ) : (
                    ""
                  )}
                  <span>Cold email</span>
                </button>
                <button
                  onClick={() => {
                    setOptionSelect("Referral from colleague/friend");
                  }}
                  className={
                    optionSelect == "Referral from colleague/friend"
                      ? style.selected
                      : ""
                  }
                >
                  {optionSelect == "Referral from colleague/friend" ? (
                    <img src={selectedSVG} />
                  ) : (
                    ""
                  )}
                  <span>Referral from colleague/friend</span>
                </button>
                <button
                  onClick={() => {
                    setOptionSelect("Blog");
                  }}
                  className={optionSelect == "Blog" ? style.selected : ""}
                >
                  {optionSelect == "Blog" ? <img src={selectedSVG} /> : ""}
                  <span>Blog</span>
                </button>
                <button
                  onClick={() => {
                    setOptionSelect("Other");
                  }}
                  className={optionSelect == "Other" ? style.selected : ""}
                >
                  {optionSelect == "Other" ? <img src={selectedSVG} /> : ""}
                  <span>Other</span>
                </button>
                <button
                  onClick={() => {
                    if (optionSelect && !loading) {
                      onSubmit(optionSelect);
                    }
                  }}
                  style={{
                    opacity: optionSelect !== "" ? "100%" : "35%",
                  }}
                  type="submit"
                >
                  {loading ? <Spin /> : "Complete Sign Up"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default SignUpAboutQuestions;
