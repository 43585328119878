import React from "react";

const WarmUpToolTipSVGIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33398 7.29819L7.37043 7.28065C7.48441 7.22446 7.61232 7.20167 7.73902 7.215C7.86572 7.22832 7.98589 7.2772 8.08531 7.35583C8.18473 7.43447 8.25924 7.53957 8.3 7.6587C8.34076 7.77782 8.34608 7.90598 8.31532 8.028L7.68598 10.5156C7.655 10.6377 7.66015 10.766 7.70082 10.8853C7.74149 11.0046 7.81598 11.1098 7.91544 11.1886C8.0149 11.2673 8.13517 11.3163 8.26198 11.3296C8.38879 11.343 8.51681 11.3202 8.63087 11.2639L8.66732 11.2455M8.00065 4.66669H8.00776V4.6737H8.00065V4.66669Z"
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.00065 14.6666H10.0007C13.334 14.6666 14.6673 13.3333 14.6673 9.99998V5.99998C14.6673 2.66665 13.334 1.33331 10.0007 1.33331H6.00065C2.66732 1.33331 1.33398 2.66665 1.33398 5.99998V9.99998C1.33398 13.3333 2.66732 14.6666 6.00065 14.6666Z"
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WarmUpToolTipSVGIcon;
