import Cookies from "js-cookie";
import { DataService } from "../../config/DataService";
import { API } from "../../config/api/Index";
import { getItem, setItem } from "../../utility/localStorageControl";
import { message, notification } from "antd";
import rightIcon from "../../assets/Email/notification-right.png";

export const updateProfileQuery = async (payloads, id) => {
  try {
    const loggedIn = await DataService.put(
      API.auth.updateProfile + id,
      payloads
    );
    if (loggedIn.data.status) {
      Cookies.set("logedIn");
      setItem("userDetails", loggedIn.data.data);
      console.log("login data", loggedIn.data.data)
      notification.success({
        message: (
          <span className="notificationText">{loggedIn.data.message}</span>
        ),
        description: (
          <span className="startBorder" style={{ height: "65px" }}></span>
        ),
        icon: <img src={rightIcon} alt="right-icon" width={20} height={20} />,
        className: "custom-notification",
        duration: 3,
        style: { width: "362px", height: "65px" },
      });
      // dispatch({
      //   type: API.auth.login,
      //   login: true,
      //   userDetails: loggedIn.data.data,
      // });
      return true;
    } else {
      message.error(loggedIn.data.message);
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const resetPasswordQuery = async (data) => {
  let payloads = {
    new_password: data?.new_password,
    confirm_password: data?.confirm_password,
    token: getItem("access_token"),
  };
  try {
    const loggedIn = await DataService.post(API.auth.resetPassword, payloads);
    if (loggedIn.data.status) {
      return true;
    } else {
      message.error(loggedIn.data.message);
      return false;
    }
  } catch (err) {
    return false;
  }
};
