import React from "react";

const DashBoardIcon = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_533_118487)">
        <path
          opacity="0.4"
          d="M9.02685 12.2218L9.02684 12.2218C8.96291 12.0045 9.02329 11.7688 9.18461 11.6095C9.18462 11.6094 9.18464 11.6094 9.18465 11.6094L9.25491 11.6806C9.38996 11.5471 9.58726 11.4982 9.76867 11.5539L9.02685 12.2218ZM9.02685 12.2218C9.02685 12.2218 9.02685 12.2218 9.02687 12.2219C9.02699 12.2223 9.02758 12.2244 9.02864 12.2282L9.03368 12.2471C9.03798 12.2636 9.04404 12.2876 9.05126 12.3187C9.0657 12.3808 9.08477 12.4708 9.1037 12.5836C9.14158 12.8094 9.17877 13.1258 9.1774 13.4925C9.17466 14.227 9.0174 15.1559 8.40977 15.9661C7.51983 17.1527 7.09536 18.1803 7.09531 19.1945C7.09531 21.899 9.29535 24.0991 11.9997 24.0991C14.7041 24.0991 16.9042 21.8991 16.9041 19.1948C16.9041 17.9083 16.4714 16.705 16.0328 15.8148C15.5938 14.9238 15.1454 14.3397 15.1066 14.2898C14.9589 14.0999 14.714 14.0136 14.4799 14.0687M9.02685 12.2218L14.4799 14.0687M14.4799 14.0687C14.2456 14.1237 14.0648 14.3104 14.0172 14.5462C13.9431 14.912 13.7176 15.6721 13.3465 16.2509L14.4799 14.0687ZM10.8466 13.7213L10.8466 13.7213L12.4902 17.942L12.4309 18.0225C12.4309 18.0225 12.4309 18.0225 12.4309 18.0225C12.5952 18.1436 12.8089 18.1754 13.0012 18.1073C13.9074 17.787 14.4713 16.8901 14.8027 16.1195C15.2199 16.9073 15.6729 18.0316 15.6729 19.1947C15.6729 21.22 14.0251 22.8679 11.9997 22.8679C9.97442 22.8679 8.32657 21.2199 8.32657 19.1945C8.32661 18.4868 8.66785 17.6741 9.39477 16.7049C10.2621 15.5485 10.4366 14.2567 10.4106 13.3025C10.5531 13.4246 10.7006 13.5638 10.8466 13.7213Z"
          strokeWidth="0.2"
        />
        <path
          d="M6.64329 0.688439L6.64328 0.688414C6.57692 0.460901 6.64705 0.215079 6.82378 0.0570346L6.64329 0.688439ZM6.64329 0.688439C6.64328 0.688421 6.64362 0.689589 6.64431 0.692049L6.64726 0.702635C6.64981 0.711899 6.6535 0.725526 6.65817 0.743339C6.66751 0.778964 6.68074 0.831313 6.69656 0.898947C6.7282 1.03423 6.77015 1.23058 6.81191 1.47652C6.89544 1.96854 6.97803 2.65818 6.97585 3.45366C6.97008 5.55316 6.40877 7.35672 5.31204 8.81904C3.60976 11.0887 2.80639 13.0248 2.80625 14.9062C2.80625 19.9758 6.93024 24.1 11.9995 24.1C17.0687 24.1 21.1927 19.976 21.1927 14.9068C21.1927 12.3222 20.2486 9.89487 19.4599 8.31742C18.6089 6.61556 17.7651 5.52336 17.7273 5.47477L17.7273 5.47473C17.5794 5.28465 17.3345 5.1986 17.1003 5.25358L17.1002 5.25359C16.8659 5.30873 16.6852 5.49561 16.6377 5.73158L16.6377 5.73159C16.6344 5.74843 16.4487 6.64638 16.0315 7.70658C15.6365 8.71038 15.0407 9.84181 14.2093 10.5177L6.64329 0.688439ZM8.0956 1.72716C8.66942 2.11492 9.39669 2.68409 10.1097 3.45669C12.1344 5.65087 13.0998 8.3707 12.9768 11.5472C12.9689 11.7511 13.0626 11.9457 13.2268 12.0667C13.3911 12.1878 13.6047 12.2196 13.7971 12.1515C14.877 11.7698 15.6951 10.8902 16.3008 9.92185C16.8554 9.03531 17.2379 8.06505 17.4834 7.3088C18.4326 8.84023 19.9614 11.7921 19.9614 14.9068C19.9614 19.2969 16.3897 22.8687 11.9995 22.8687C7.6093 22.8687 4.0375 19.2967 4.0375 14.9063C4.03764 13.3431 4.76902 11.5951 6.29704 9.55774C7.54824 7.88955 8.20719 5.76697 8.20719 3.42239C8.20719 2.77952 8.15834 2.20282 8.0956 1.72716Z"
          strokeWidth="0.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_533_118487">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DashBoardIcon;
