import React, { useState } from "react";
import style from "./Deliverability-test.module.css";
import { Card, Col, Row, Divider } from "antd";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PurchaseCreditModal from "../Upgrade-subscription/PurchaseCreditModal/PurchaseCreditModal";
import { useSelector } from "react-redux";
const DeliverabilityTest = ({ totalCredit }) => {
  // DEFINE ALL STATES
  const navigate = useNavigate();
  const [purchaseCreditModal, setPurchaseCreditModal] = useState(false);
  const updatedUserdetails = useSelector((state) => state.auth.userDetails);
  console.log("updatedUserdetails", updatedUserdetails);
  console.log(
    "updatedUserdetails",
    updatedUserdetails?.userCreditCount?.credit_count
  );

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <Col span={12}>
        <Card bordered={false} className={style.mainCardContainer}>
          <Row justify={"space-between"}>
            <div className={style.cardTitleUsage}>
              Deliverability Test Credits
            </div>
            <button
              className={style.cardUpgradeButton}
              size="large"
              onClick={() => {
                setPurchaseCreditModal(true);
              }}
            >
              Purchase Credits
            </button>
          </Row>

          <div className={style.mainCreditContainer}>
            <div>
              {updatedUserdetails?.pending_deliverability_tests
                ? updatedUserdetails?.pending_deliverability_tests
                : 0}
            </div>
            <label>
              Visit{" "}
              <Link to={"/deliverability-test"}>
                <span>Deliverability Test</span>
              </Link>{" "}
              to utilize these credits or purchase more to add credits
            </label>
          </div>
        </Card>
      </Col>
      {purchaseCreditModal && (
        <PurchaseCreditModal
          purchaseCreditModal={purchaseCreditModal}
          onClose={() => {
            setPurchaseCreditModal(false);
          }}
        />
      )}
    </>
  );
};
export default DeliverabilityTest;
