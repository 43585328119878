import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import { privateHelmateTagWords } from "../config/CommonFuntion";
import Subscription from "../containers/admin/Subscriptions/Subscription";
import Templates from "../containers/admin/Templates";
import Deliverability from "../containers/admin/Deliverability";
import Setting from "../containers/admin/setting/Setting";
import Dashboard from "../containers/admin/dashboard/Dashboard";
import ConnectGoogle from "../component/connectAccount/connectGoogle/ConnectGoogle";
import AccountList from "../component/connectAccount/accountList/AccountList";
import ConnectSMTP from "../component/connectAccount/connectSMTP/ConnectSMTP";
import UpgradeSubscription from "../containers/admin/Subscriptions/Upgrade-subscription/Upgrade-subscription";
import CancelSubscription from "../containers/admin/Subscriptions/Upgrade-subscription/Cancel-subscription/Cancel-subscription";
import VerifyAccount from "../containers/Auth/VerifyAccount";
import AddTemplates from "../component/Template/AddTemplate/AddTemplate";
import ChoosePlan from "../containers/admin/Subscriptions/ChoosePlan/ChoosePlan";
import ResetPassword from "../containers/Auth/ResetPassword";
import DeliverabilityTable from "../containers/admin/deliverability/DeliverabilityTable";
import DeliverabilityEmailContent from "../containers/admin/deliverability/DeliverabilityEmailContent";
import { getItem } from "../utility/localStorageControl";
import UserLogin from "../containers/Auth/UserLogin";
import PublicDeliverability from "../containers/admin/PublicDeliverability";

// DEFINE MAIN FUNCTION
const PrivateRoute = ({ width }) => {
  // DEFINE MAIN FUNCTION RETURN

  return (
    <>
      <Helmet>
        <title>{privateHelmateTagWords(window.location.pathname)}</title>
        <meta
          name="description"
          content="Email Warming: Providing mail automation services."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Routes>
        <Route path="/userLogin/:token" element={<UserLogin width={width} />} />
        <Route path="/email-warmup" element={<Dashboard width={width} />} />
        <Route
          path={`/email-warmup/:id/report`}
          element={<Dashboard width={width} />}
        />
        <Route
          path={`/email-warmup/:id/setting`}
          element={<Dashboard width={width} />}
        />

        <Route path="/templates" element={<Templates width={width} />} />
        <Route path="/templates/*" element={<AddTemplates width={width} />} />

        <Route
          path="/verify-account"
          element={<VerifyAccount width={width} />}
        />
        <Route
          path="/resets-password"
          element={<ResetPassword width={width} />}
        />
        <Route
          path="/resets-password"
          element={<ResetPassword width={width} />}
        />
        <Route
          path="/deliverability-test/:id"
          element={<Deliverability width={width} />}
        />
        <Route
          path="/deliverability-test"
          element={<DeliverabilityTable width={width} />}
        />
        <Route
          path="/deliverability-test-public/:id"
          element={<Deliverability width={width} />}
        />
        <Route
          path="/deliverability/create-new"
          element={<DeliverabilityEmailContent width={width} />}
        />
        <Route
          path="/billing-and-subscription"
          element={<UpgradeSubscription width={width} />}
        />
        <Route path="/test" element={<Subscription width={width} />} />

        <Route
          path="/billing-and-subscription/upgrade-subscription"
          element={<UpgradeSubscription width={width} />}
        />
        <Route
          path="/subscription/cancel-subscription"
          element={<CancelSubscription width={width} />}
        />
        <Route
          path="/billing-and-subscription/plans"
          element={<ChoosePlan />}
        />
        <Route path="/setting/profile" element={<Setting width={width} />} />
        <Route path="/setting/password" element={<Setting width={width} />} />
        <Route path="/new-email" element={<AccountList width={width} />} />
        <Route
          path="/new-email/google"
          element={<ConnectGoogle width={width} />}
        />
        <Route
          path="/new-email/google/:id"
          element={<ConnectGoogle width={width} />}
        />
        <Route path="/new-email/smtp" element={<ConnectSMTP width={width} />} />
        <Route
          path="/new-email/smtp/:id"
          element={<ConnectSMTP width={width} />}
        />
        <Route
          exact
          path="/*"
          element={<Navigate to="/email-warmup" width={width} />}
        />
      </Routes>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default PrivateRoute;
