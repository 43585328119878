// DEFINE FUNCTION FOR WORD FIRST LETTER CAPITAL
export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
};

// DEFINE FUNCTION FOR WORD FIRST LETTER CAPITAL
export const capitalizeFirstWordLetter = (firstString, lastString) => {
  return (
    firstString?.charAt(0).toUpperCase() + lastString?.charAt(0).toUpperCase()
  );
};

// DEFINE FUNCTION FOR WORD FIRST LETTER CAPITAL
export const capitalizeFirstAndWordLetter = (firstString) => {
  if (firstString !== "") {
    return firstString
      ?.split(" ") // Split the name string into words
      .slice(0, 2)
      ?.filter((word) => word.length > 0) // Filter out empty words
      ?.map((word) => word[0].toUpperCase()) // Map each word to its first character (uppercase)
      ?.join(""); // Join the initials into a single string
  } else {
    return "";
  }
};

// DEFINE FUNCTION FOR PRIVATE ROUTES IN FIRST LETTER CAPITAL
export const privateHelmateTagWords = (firstString) => {
  if (firstString !== "") {
    let string = firstString?.split("/")[1];
    if (firstString == "/" || firstString.includes("account")) {
      return "Email Warmup";
    } else if (string.includes("subscription")) {
      return "Subscription";
    } else if (string.includes("deliverability")) {
      return "Deliverability Test";
    } else {
      return string
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }
  } else {
    return "";
  }
};

// DEFINE FUNCTION FOR WORD FIRST LETTER CAPITAL AND WORD LAST LETTER
export function capitalizeFirstLetterWord(str) {
  if (!str || typeof str !== "string") {
    return "";
  }

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() + " ";
}

// DEFINE FUNCTION FOR DATE BASED DIFFERENT BETWEEN TO CURRENT DATE TO THIS DATE
export const formatDateFromNow = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const diff = now - date;
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);

  if (months > 0) {
    return `${months} month${months > 1 ? "s" : ""} ago`;
  } else if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
  }
};

// DEFINE FUNCTION FOR CHECK OBJECT
export const checkValuesExistOnObject = (obj, type) => {
  for (let key in obj) {
    if (key.includes(type)) {
      if (obj[key] === undefined || obj[key] === "") {
        return false;
      }
    }
  }
  return true;
};

// DEFINE FUNCTION FOR TIMEZON LIST
const moment = require("moment-timezone");

export const TimeZonList = moment.tz.names().map((timezone) => ({
  value: timezone,
  label: `${timezone} ${moment.tz(timezone).format("z (Z)")}`,
}));

// DEFINE FUNCTION FOR API IN PASS QUERY PARAMETERS ENCODED KEY AND VALUES
export const encodeQueryParams = (params) => {
  const queryItems = [];
  for (let key in params) {
    if (
      params.hasOwnProperty(key) &&
      params[key] !== "" &&
      typeof params[key] !== "undefined"
    ) {
      queryItems.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      );
    }
  }
  return queryItems.length > 0 ? `?${queryItems.join("&")}` : "";
};

export const searchDataOfArray = (searchValue, list, keys) => {
  if (searchValue?.trim?.() !== "") {
    if (keys && keys.length) {
      const filteredData = list.filter((item) => {
        let a = "";
        keys.map((el) => {
          a += item[el];
        });
        return a.toLowerCase().includes(searchValue.toLowerCase());
      });
      return filteredData;
    } else {
      const filteredData = list.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      return filteredData;
    }
  }
  return list;
};
export function convertUTCToReadableTime(utcTimestamp) {
  // Given UTC timestamp
  let utcDate = new Date(utcTimestamp);

  // Convert to IST by adding 5 hours and 30 minutes
  let istOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
  let istDate = new Date(utcDate.getTime() + istOffset);

  // Format IST date in 'YYYY-MM-DDTHH:mm:ss.sss+05:30' format
  let year = istDate.getFullYear();
  let month = String(istDate.getMonth() + 1).padStart(2, '0');
  let day = String(istDate.getDate()).padStart(2, '0');



  // IST formatted timestamp
  let istTimestamp = `${day}-${month}-${year}`;

  return istTimestamp;
}
// DEFINE FUNCTION FOR GET TODAY DATE
export function getTodayDate() {
  let today = new Date();
  let year = today.getFullYear();
  let month = String(today.getMonth() + 1).padStart(2, '0');
  let day = String(today.getDate()).padStart(2, '0');

  // Formatted date in 'YYYY-MM-DD' format
  let formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
}

// DEFINE FUNCTION FOR CALCULATE DAY REMAINING DD-MM-YYYY
export function daysRemaining(date1Str, date2Str) {
  function parseDate(dateStr) {
    const parts = dateStr.split('-');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Months are 0-based in JavaScript Date
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
  }

  // Parse the input dates
  const date1 = parseDate(date1Str);
  const date2 = parseDate(date2Str);

  // Calculate the difference in time (in milliseconds)
  const timeDifference = date2.getTime() - date1.getTime();

  // Convert the time difference from milliseconds to days
  const daysDifference = timeDifference / (1000 * 3600 * 24);

  // Return the number of days remaining
  return Math.ceil(daysDifference);
}

// DEFINE FUNCTION FOR FIRST WORD CAPITAL LETTER IN SENTENCE
export function capitalizeFirstWord(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}
export function truncateString(str) {
  // Split the string into words
  // Check if the string has more than 50 words
  if (str.length > 40) {
    // Return the first 50 letters followed by '...'
    return str.slice(0, 40) + '...';
  }

  // If the string has 50 or fewer words, return it as is
  return str;
}