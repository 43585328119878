import React, { useEffect, useRef, useState } from "react";
import "./Template.css";
import style from "../../../containers/admin/deliverability/deliverabilityTable.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Input,
  Row,
  Switch,
  Table,
  Dropdown,
  Spin,
  Pagination,
  notification,
  Popover,
} from "antd";
import { getItem, setItem } from "../../../utility/localStorageControl";
import img from "../../../assets/signup/unsuccessful-search.png";
import WarmupConfirm from "../../../component/dashboardModal/WarmupConfirmModal/WarmupConfirm";
import ActiveEmailModal from "../../../component/dashboardModal/ActiveEmailModal/ActiveEmailModal";
import DeleteEmailModal from "../../../component/dashboardModal/DeleteEmailModal/DeleteEmailModal";
import DeleteIcon from "../../../assets/dashboard/trash.png";
import rightIcon from "../../../assets/Email/notification-right.png";
import editIcon from "../../../assets/edit.png";
import {
  EmailActiveWarmupOn,
  EmailConnectDelete,
  updateAgainMessageShow,
} from "../../../state/EmailConnect/Emailconnect.api";
import SuccessSVGIcon from "../../../assets/dashboard/SuccessSVGIcon";
import FailSVGIcon from "../../../assets/dashboard/FailSVGIcon";
import {
  templateDelete,
  templateList,
  templateStatusUpdate,
} from "../../../redux/templates/action";
import UpgradePlanModel from "../../dashboardModal/UpgradePlanModel/UpgradePlanModel";
import ReactSpeedometer from "react-d3-speedometer";

// DEFINE MAIN FUNCTION
const Template = () => {
  const userDetail = useSelector((state) => state?.auth?.userDetails);
  console.log("userDetail", userDetail);
  const isSubscribe = userDetail.subscribed;
  const isFreeTrialExpires = userDetail?.free_trial;
  console.log("isFreeTrialExpires", isFreeTrialExpires);
  const isAccessToTemplate =
    userDetail?.subscriptions?.subscriptionPlan?.subscription_plan_type;
  console.log("isAccessToTemplate", isAccessToTemplate);

  // DEFINE ALL STATES
  let isMount = useRef(true);
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = getItem("userDetails");
  const [pageSize, setPageSize] = useState(5);
  const [searchData, setSearchData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [tableLoading, setTableLoading] = useState(true);
  const [listLoading, setListLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedValue, setSelectedValue] = useState({});
  const [activeEmailModal, setActiveEmailModal] = useState(false);
  const [deleteEmailModal, setDeleteEmailModal] = useState(false);
  const [upgradePlanModel, setUpgradePlanModel] = useState(false);
  const [activeModalLoading, setActiveModalLoading] = useState(false);
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [WarmupConfirmModal, setWarmupConfirModal] = useState(false);
  const [tableSortingValues, setTableSortingValues] = useState({
    usage: "",
    sender_email_account_count: "",
    deliverability: "",
  });

  // DEFINE USEEFFECT WITH SEARCH DATA
  useEffect(() => {
    if (isMount.current) {
      list(currentPage, pageSize, searchData, "", "", "", true);
      isMount.current = false;
    } else {
      const delayDebounceFn = setTimeout(() => {
        list(currentPage, pageSize, searchData, "", "", "", false);
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchData, window.location.pathname]);

  // DEFINE FUNCTION FOR GANDEL CLICK OUTSIDE OF TABLE
  const handleClickOutside = (event) => {
    if (tableRef.current && !tableRef.current.contains(event.target)) {
      setSelectedRow([]);
    }
  };

  // DEFINE USEEFFECT FOR CHECK COMPONENT MOUNTS
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // DEFINE ALL LISTS WITH REDUX
  let listOfTemplate = useSelector((state) => state?.template?.templateList);
  console.log("listOfTemplate", listOfTemplate?.length);

  // const needlewidth = () => {
  //   if (listOfTemplate?.length > 0) {
  //     for (let i = 0; i < listOfTemplate.length; i++) {
  //       if (listOfTemplate.length > 0) {
  //         const speedometerNedle = document.getElementsByClassName("pointer");

  //         // Check if speedometerNedle[speedometerNedleIndex] exists and is an element
  //         if (
  //           speedometerNedle.length > i &&
  //           speedometerNedle[i].querySelector
  //         ) {
  //           const pathElement = speedometerNedle[i].querySelector("path");

  //           // Check if pathElement is valid and has setAttribute method
  //           if (pathElement && pathElement.setAttribute) {
  //             const newDAttributeValue =
  //               "M 2.5 0 C 1.667 -12.5 0.833 -25 0 -25 C -0.833 -25 -1.667 0 -2.5 0 C -1.667 0 -0.833 2.5 0 2.5 C 0.833 2.5 1.667 1.25 2.5 0";
  //             pathElement.setAttribute("d", newDAttributeValue);
  //             console.log("Updated path d attribute:", newDAttributeValue);
  //           } else {
  //             console.error(
  //               "pathElement or its setAttribute method is not valid."
  //             );
  //           }
  //         } else {
  //           console.error(
  //             `speedometerNedle[${i}] or its querySelector method is not valid.`
  //           );
  //         }
  //       } else {
  //         console.log("No users in the list.");
  //       }
  //     }
  //   }
  // };
  let countsOfTemplate = useSelector(
    (state) => state.template.templateListCounts
  );
  const NeddleWidth = () => {
    if (listOfTemplate?.length > 0) {
      for (let i = 0; i < 20; i++) {
        if (listOfTemplate.length > 0) {
          const speedometerNedle = document.getElementsByClassName("pointer");

          // Check if speedometerNedle[speedometerNedleIndex] exists and is an element
          if (
            speedometerNedle.length > i &&
            speedometerNedle[i]?.querySelector
          ) {
            const pathElement = speedometerNedle[i].querySelector("path");

            // Check if pathElement is valid and has setAttribute method
            if (pathElement && pathElement.setAttribute) {
              const newDAttributeValue =
                "M 2.5 0 C 1.667 -12.5 0.833 -25 0 -25 C -0.833 -25 -1.667 0 -2.5 0 C -1.667 0 -0.833 2.5 0 2.5 C 0.833 2.5 1.667 1.25 2.5 0";
              pathElement.setAttribute("d", newDAttributeValue);
              console.log("Updated path d attribute:", newDAttributeValue);
            } else {
              console.error(
                "pathElement or its setAttribute method is not valid."
              );
            }
          } else {
            console.error(
              `speedometerNedle[${i}] or its querySelector method is not valid.`
            );
          }
        } else {
          console.log("No users in the list.");
        }
      }
    }
  };

  useEffect(() => {
    // setListLoading(true);
    setTableLoading(true);
    setTimeout(() => {
      NeddleWidth();
    }, 300);

    setTableLoading(false);
  }, [countsOfTemplate, tableSortingValues]);

  // DEFINE FUNCTION FOR CONNECTED EMAIL LIST
  const list = async (
    page,
    current,
    search,
    usage,
    sender_email_account_count,
    deliverability,
    loaderStatus
  ) => {
    if (loaderStatus) {
      setListLoading(true);
    }
    setTableLoading(true);
    let queryStrings = {
      page: page,
      limit: current,
      search: search,
      usage: usage,
      sender_email_account_count: sender_email_account_count,
      deliverability: deliverability,
    };
    const response = await dispatch(templateList(queryStrings));
    if (response) {
      if (loaderStatus) {
        setListLoading(false);
      }
      setTableLoading(false);
    } else {
      if (loaderStatus) {
        setListLoading(false);
      }
      setTableLoading(false);
    }
  };
  // useEffect(() => {
  //   needlewidth();
  // }, [listOfTemplate]);

  // DEFINE FUNCTION FOR DELETE EMAIL API CALL
  const DeleteEmailWarmup = async (id) => {
    setDeleteModalLoading(true);
    const response = await dispatch(templateDelete(id));
    if (response) {
      setDeleteModalLoading(false);
      setDeleteEmailModal(false);
      setSelectedValue({});
      notification.success({
        message: (
          <span className="notificationText">
            Email has been deleted successfully
          </span>
        ),
        description: <span className="startBorder"></span>,
        icon: <img src={rightIcon} alt="right-icon" width={20} height={20} />,
        className: "custom-notification",
        duration: 3,
        style: { width: "362px", height: "68px" },
      });
      list(currentPage, pageSize, searchData, "", "", "", false);
    } else {
      setDeleteModalLoading(false);
    }
  };

  // DEFINE TABLE COLUMNS
  const columns = [
    {
      dataIndex: "column1",
      key: "column1",
      className: "custom-header",
      render: (text, item) => (
        <Switch
          onChange={(value) => {
            if (value == true) {
              if (userDetails?.message_dont_show == false) {
                setActiveEmailModal(true);
                setSelectedValue(item);
              } else {
                setActiveEmailModal(false);
                setSelectedValue(item);
                ActiveEmailWarmup(item?.id, true, false);
              }
              setSelectedValue(item);
              setWarmupConfirModal(false);
            } else {
              setWarmupConfirModal(true);
              setSelectedValue(item);
              setActiveEmailModal(false);
            }
          }}
          value={item?.status == "active" ? true : false}
          style={{
            backgroundColor: item?.status == "active" ? "#F2F4F7" : "",
          }}
        />
      ),
    },
    {
      title: <span>NAME</span>,
      dataIndex: "title",
      key: "title",
      className: "custom-header",
      render: (icon, value) => {
        return <span className="columData">{value?.title}</span>;
      },
    },
    {
      title: <span>SUBJECT </span>,
      dataIndex: "subject",
      key: "subject",
      className: "custom-header",
      width: 300,
      render: (icon, value) => {
        return <span className="columData">{value?.subject}</span>;
      },
    },
    {
      title: (
        <span
          onClick={() => {
            setTableSortingValues({
              ...tableSortingValues,
              sender_email_account_count:
                tableSortingValues.sender_email_account_count == "" ||
                tableSortingValues.sender_email_account_count == "DESC"
                  ? "ASC"
                  : "DESC",
            });
            list(
              currentPage,
              pageSize,
              searchData,
              "",
              tableSortingValues.sender_email_account_count == "" ||
                tableSortingValues.sender_email_account_count == "DESC"
                ? "ASC"
                : "DESC",
              "",
              false
            );
          }}
        >
          MAILBOXES{" "}
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.0835 8.74967L7.00016 11.6663L9.91683 8.74967M4.0835 5.24967L7.00016 2.33301L9.91683 5.24967"
              stroke="#98A2B3"
              strokeWidth="1.16667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      ),
      dataIndex: "sender_email_account_id",
      key: "sender_email_account_id",
      className: "custom-header",
      render: (icon, value) => {
        return (
          <span className="columData">
            {value?.sender_email_account_id?.length > 0
              ? value?.sender_email_account_id[0]["sender_email"]
              : ""}{" "}
            <Popover
              placement="top"
              overlayClassName="template_tabel_email_list"
              content={
                <div>
                  {value?.sender_email_account_id?.map((value, index) => {
                    return (
                      <p key={index} index={index}>
                        {value?.sender_email}
                      </p>
                    );
                  })}
                </div>
              }
            >
              {" "}
              <div className="template_tabel_email_length">
                {value?.sender_email_account_count}
              </div>
            </Popover>
          </span>
        );
      },
    },
    {
      title: (
        <span
          onClick={() => {
            setTableSortingValues({
              ...tableSortingValues,
              usage:
                tableSortingValues.usage == "" ||
                tableSortingValues.usage == "DESC"
                  ? "ASC"
                  : "DESC",
            });
            list(
              currentPage,
              pageSize,
              searchData,
              tableSortingValues.usage == "" ||
                tableSortingValues.usage == "DESC"
                ? "ASC"
                : "DESC",
              "",
              "",
              false
            );
          }}
        >
          USAGE{" "}
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.0835 8.74967L7.00016 11.6663L9.91683 8.74967M4.0835 5.24967L7.00016 2.33301L9.91683 5.24967"
              stroke="#98A2B3"
              strokeWidth="1.16667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      ),
      dataIndex: "usage",
      key: "usage",
      className: "custom-header",
      render: (icon, value) => {
        return <span className="columData">{value?.usage}</span>;
      },
    },
    {
      title: (
        <span
          onClick={() => {
            setTableSortingValues({
              ...tableSortingValues,
              deliverability:
                tableSortingValues.deliverability == "" ||
                tableSortingValues.deliverability == "DESC"
                  ? "ASC"
                  : "DESC",
            });
            list(
              currentPage,
              pageSize,
              searchData,
              "",
              "",
              tableSortingValues.deliverability == "" ||
                tableSortingValues.deliverability == "DESC"
                ? "ASC"
                : "DESC",
              false
            );
          }}
        >
          DELIVERABILITY{" "}
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.0835 8.74967L7.00016 11.6663L9.91683 8.74967M4.0835 5.24967L7.00016 2.33301L9.91683 5.24967"
              stroke="#98A2B3"
              strokeWidth="1.16667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      ),
      dataIndex: "deliverability",
      key: "deliverability",
      className: "custom-header",
      render: (icon, value) => {
        return (
          <div className="deliverabilityMeterContainer deliverabilityData">
            <div style={{ height: "60px" }}>
              <ReactSpeedometer
                maxSegmentLabels={3}
                segments={3}
                maxValue={100}
                width={100}
                ringWidth={6}
                // height={45}
                currentValueText=""
                value={value?.deliverability ? value?.deliverability : 0}
                needleColor="#272525"
                needleHeightRatio={0.5}
                paddingHorizontal={0}
                paddingVertical={0}
                labelFontSize={0}
                valueTextFontSize={0}
                segmentColors={["#DA6868", "#E3D02B", "#5BCD7C"]}
              />
            </div>
            <div className="part"></div>
            <span
              style={{
                color:
                  value?.deliverability < 33
                    ? "#E31F1F"
                    : value?.deliverability < 66
                    ? "#D37C0C"
                    : "Green",
              }}
            >
              {value?.deliverability}%
            </span>
          </div>
        );
      },
    },
    {
      dataIndex: "column1",
      key: "more",
      className: "custom-header",
      render: (icon, value) => {
        return (
          <Dropdown
            overlayClassName="customTableDropDown"
            className="customDropDown"
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <div className="dropdownText">
                      <p>Edit Template</p>
                    </div>
                  ),
                  icon: <img src={editIcon} alt="setting" />,
                  onClick: () => {
                    setItem("templateTitle", value?.title);
                    navigate(`/templates/${value?.id}`);
                  },
                },
                {
                  key: "2",
                  label: (
                    <div className="dropdownText">
                      <p>Delete</p>
                    </div>
                  ),
                  icon: <img src={DeleteIcon} alt="setting" />,
                  onClick: () => {
                    setDeleteEmailModal(true);
                    setSelectedValue(value);
                  },
                },
              ],
            }}
          >
            <span className="columData">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </Dropdown>
        );
      },
    },
  ];

  // DEFINE FUNCTION FOR TABLE PAGE CHANGE
  const onPageChange = async (page, current) => {
    setCurrentPage(page);
    setPageSize(current);
    list(page, current, searchData, "", "", "", false);
  };

  // DEFINE FUNCTION FOR ACTIVE EMAIL API CALL
  const ActiveEmailWarmup = async (id, status, againShow) => {
    setActiveModalLoading(true);
    if (againShow) {
      let payload = {
        message_dont_show: againShow,
      };
      const response = await dispatch(updateAgainMessageShow(payload));
      if (response) {
        setItem("userDetails", {
          ...getItem("userDetails"),
          message_dont_show: true,
        });
      }
    }
    const response = await dispatch(templateStatusUpdate(id, status));
    if (response) {
      setWarmupConfirModal(false);
      setActiveEmailModal(false);
      setSelectedValue({});
      list(currentPage, pageSize, searchData, "", "", "", false);
      setActiveModalLoading(false);
    } else {
      setActiveModalLoading(false);
    }
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      {listLoading ? (
        <div className="no-data-component">
          <Spin />
        </div>
      ) : (
        <>
          {listOfTemplate?.length > 0 || searchData !== undefined ? (
            <div className={"templateListTable"}>
              <Card>
                <Row
                  justify={"space-between"}
                  gutter={[20, 20]}
                  align={"middle"}
                  className="card-main-row"
                >
                  <div>
                    <Input
                      onChange={(event) => {
                        setSearchData(event.target.value);
                      }}
                      prefix={
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.58317 17.5003C13.9554 17.5003 17.4998 13.9559 17.4998 9.58366C17.4998 5.2114 13.9554 1.66699 9.58317 1.66699C5.21092 1.66699 1.6665 5.2114 1.6665 9.58366C1.6665 13.9559 5.21092 17.5003 9.58317 17.5003Z"
                            stroke="#98A2B3"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            opacity="0.4"
                            d="M18.3332 18.3337L16.6665 16.667"
                            stroke="#98A2B3"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      placeholder="Search"
                      value={searchData}
                    />
                  </div>
                  <div className="add-button-container">
                    <button
                      onClick={() => {
                        if (
                          (isSubscribe && isAccessToTemplate !== "starter") ||
                          isFreeTrialExpires
                        ) {
                          navigate("/templates/create-new");
                          setItem(
                            "templateTitle",
                            `Untitled Template ${
                              countsOfTemplate?.totalCount + 1
                            }`
                          );
                          // alert("not access to create templates");
                        } else {
                          // setDeleteEmailModal(true);
                          setUpgradePlanModel(true);
                          // <UpgradePlanModel />;
                        }
                      }}
                    >
                      <span>
                        {(isSubscribe && isAccessToTemplate !== "starter") ||
                        isFreeTrialExpires
                          ? "+ Create new Template"
                          : " Upgrade Subscription"}
                      </span>
                    </button>
                  </div>
                </Row>
                <div ref={tableRef}>
                  <Table
                    className="templateListTable"
                    columns={columns}
                    dataSource={listOfTemplate}
                    loading={tableLoading}
                    scroll={{
                      x: "1150px",
                    }}
                    pagination={false}
                    onRow={(record, index) => ({
                      onClick: () => setSelectedRow(index),
                    })}
                    rowClassName={(record, index) =>
                      index === selectedRow ? "selected-row" : ""
                    }
                  />
                </div>
              </Card>
              <div className={style.tablePagenation}>
                <Pagination
                  total={countsOfTemplate.totalCount}
                  showSizeChanger
                  pageSizeOptions={[10, 20, 50, 100]}
                  defaultPageSize={pageSize}
                  defaultCurrent={currentPage}
                  onChange={onPageChange}
                />
                <span className={style.table_counts}>{`${
                  currentPage * pageSize - (pageSize - 1)
                }-${currentPage * pageSize} of ${
                  countsOfTemplate.totalCount
                }`}</span>
              </div>
            </div>
          ) : (isSubscribe && isAccessToTemplate == "starter") ||
            (!isSubscribe && !isFreeTrialExpires) ? (
            <div className="mainContainer">
              <div>
                <img src={img} alt="imgae" />
                <h2>Upgrade Plan</h2>
                <p>For creating template Please Upgrade Your plan</p>
                <button
                  size="large"
                  onClick={() => {
                    navigate("/billing-and-subscription/plans");
                  }}
                >
                  <span>Upgrade Your plan {/* Create new Template */}</span>
                </button>
              </div>
            </div>
          ) : (
            <div className="mainContainer">
              <div>
                <img src={img} alt="imgae" />
                <h2>No Template found</h2>
                <p>
                  You haven't made any templates yet! Get started by creating
                  templates. Click the buttons below to send emails using your
                  templates.
                </p>
                <button
                  size="large"
                  onClick={() => {
                    setItem("templateTitle", "Untitled Template 1");
                    navigate("/templates/create-new");
                  }}
                >
                  <span>Create new Template</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {activeEmailModal && (
        <ActiveEmailModal
          activeEmailModal={activeEmailModal}
          selectedValue={selectedValue}
          onClose={() => {
            setActiveEmailModal(false);
          }}
          onSubmit={ActiveEmailWarmup}
          loading={activeModalLoading}
          type="template"
        />
      )}

      {WarmupConfirmModal && (
        <WarmupConfirm
          WarmupConfirmModal={WarmupConfirmModal}
          selectedValue={selectedValue}
          onClose={() => {
            setWarmupConfirModal(false);
          }}
          onSubmit={ActiveEmailWarmup}
          loading={activeModalLoading}
          type="template"
        />
      )}

      {deleteEmailModal && (
        <DeleteEmailModal
          deleteEmailModal={deleteEmailModal}
          selectedValue={selectedValue}
          onClose={() => {
            setDeleteEmailModal(false);
            setSelectedValue({});
          }}
          onSubmit={DeleteEmailWarmup}
          loading={deleteModalLoading}
          type="template"
        />
      )}

      {upgradePlanModel && (
        <UpgradePlanModel
          deleteEmailModal={upgradePlanModel}
          onClose={() => {
            setUpgradePlanModel(false);
          }}
          type="template"
        />
      )}
    </>
  );
};
// DEFINE MAIN FUNCTION EXPORT
export default Template;
