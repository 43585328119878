//DEFINE ALL EXTERNAL IMPORT
import React, { useState } from "react";
import { Col, Collapse, Modal, Row } from "antd";
import { FaCheck, FaCheckCircle, FaExclamationTriangle } from "react-icons/fa";
import { RxCrossCircled, RxFile } from "react-icons/rx";
import styles from "./deliverability.module.css";
import RoundedButtion from "../../../component/deliverabilityTest/RoundedButtion";
import { RxCross2, RxExclamationTriangle } from "react-icons/rx";
import { MdCheck } from "react-icons/md";

// DEFINE MAIN FUNCTION
const DomainIPBlackList = ({ deleverybityData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalSubTitle, setModalSubTitle] = useState("");
  const blackListResult = deleverybityData && deleverybityData.blackListResult;
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const checkStatus = (item) => {
    if (
      blackListResult &&
      blackListResult?.status == "true" &&
      item == "Domain"
    ) {
      return true;
    }
  };

  const Lable = ({ name }) => {
    return (
      <div className={styles.DomainIpDetailsLabel}>
        <div className={styles.DomainSetupDetailsLabel}>
          <h3>{name} </h3>
          <RoundedButtion className={checkStatus(name) ? "green" : "red"}>
            {checkStatus(name) ? <MdCheck fontSize={"medium"} /> : <FaExclamationTriangle />}
            {checkStatus(name) ? "Safe" : "BlackListed"}
          </RoundedButtion>
        </div>
        <div
          className={styles.blacklistBtn}
          onClick={(e) => {
            e.stopPropagation();
            setIsModalOpen(true);
            setModalTitle(
              name == "Domain" ? `Domain Blacklists` : "IP Blacklists"
            );
            setModalSubTitle(
              name == "Domain"
                ? `We check your domain against the following blacklists:`
                : `We check your sender IPs against the following blacklists:`
            );
          }}
        >
          <RxFile />
          <p>BlackLists we check</p>
        </div>
      </div>
    );
  };

  const Children = ({ name }) => {
    if (checkStatus(name)) {
      return (
        <>
          <div>
            <div className={styles.domainSetupChildrenContainer}>
              {checkStatus(name) ? (
                <FaCheckCircle className={styles.checkIcons} />
              ) : (
                <RxCrossCircled className={styles.checkIcons} />
              )}
              <div className={styles.textContainer}>
                <h5>
                  {name == "Domain"
                    ? checkStatus(name)
                      ? "Your domain is safe!"
                      : "Your domain is blacklisted"
                    : checkStatus(name)
                      ? "Your sender IPs are safe!"
                      : "Your sender IPs are blacklisted"}
                </h5>
                {name == "Domain" ? (
                  <p>
                    Domain blacklists track domains involved in malicious
                    activities. When your domain is blacklisted, it negatively
                    affects your deliverability.
                    <span>
                      Learn how to remove your domain from blacklists.
                    </span>
                  </p>
                ) : name == "Sender IP" ? (
                  <p>
                    Your email server uses these IPs for outgoing emails. When
                    some of the IPs are blacklisted, your emails may have a
                    lower chance reaching the inbox.
                    <span>
                      Learn how to remove your sender IPs from blacklists.
                    </span>
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            <div className={styles.domainSetupChildrenRedContainer}>
              <RxCrossCircled className={styles.checkIcons} />
              <div className={styles.textContainer}>
                <h5>
                  Your {name} record is{" "}
                  {checkStatus(name) ? "valid" : "invalid"}!
                </h5>
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  const itemsNest = [
    {
      key: "1",
      label: <Lable name="Domain" />,
      children: <Children name="Domain" />,
    },
    // {
    //   key: "2",
    //   label: <Lable name="Sender IP" />,
    //   children: <Children name="Sender IP" />,
    // },
  ];

  return (
    <>
      <Collapse accordion items={itemsNest} expandIconPosition="right" />

      <Modal
        title={modalTitle}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={900}
      >
        <p>{modalSubTitle}</p>
        <div className={styles.blacklistedDataList}>
          <Row gutter={[8, 16]}>
            {blackListResult ? (
              blackListResult &&
              blackListResult?.data?.totalCheckingDomain?.map((item, index) => {
                return (
                  <Col xl={6} md={8} sm={12} xs={24} key={index}>
                    <p>{item}</p>
                  </Col>
                );
              })
            ) : (
              <p>No blacklists found.</p>
            )}
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default DomainIPBlackList;
