import React from "react";
import style from "./Free-plan-usage.module.css";
import { Card, Col, Row, Divider } from "antd";
import { useNavigate } from "react-router-dom";
const FreePlanUsageSubscription = () => {
  // DEFINE ALL STATES
  const navigate = useNavigate();

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <Col span={12}>
        <Card bordered={false} className={style.mainCardContainer}>
          <div className={style.cardTitleUsage}>Free Plan Usage</div>
          <div className={style.mainContainer}>
            <label>
              <span>
                <b>Monthly Warmup Emails</b>
              </span>
              <span>
                <b>5/50</b> Remaining (10% used)
              </span>
            </label>
            <div className={style.mainprogress}>
              <div className={style.progress}></div>
            </div>
            <label>
              <span>
                <b>45</b>(90% Remaining)
              </span>
            </label>
          </div>
        </Card>
      </Col>
    </>
  );
};
export default FreePlanUsageSubscription;
