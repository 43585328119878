//DEFINE  ALL EXTERNAL IMPORT
import React from "react";
import { Col, Progress, Skeleton } from "antd";
import { FaCheckCircle } from "react-icons/fa";

// DEFINE ALL iNTERNAL IMPORT
import emoji from "../../../assets/deleverability/emoji.png";
import styles from "./deliverability.module.css";
import excellentEmoji from "../../../assets/deleverability/Heart Eyes Emoji.png";
import veryGoodEmoji from "../../../assets/deleverability/Blushed Smiling Emoji.png";
import goodEmoji from "../../../assets/deleverability/Slightly Smiling Emoji.png";
import AverageEmoji from "../../../assets/deleverability/Neutral Emoji.png";
import poorEmoji from "../../../assets/deleverability/Unamused Emoji.png";
import terribleEmoji from "../../../assets/deleverability/Sad Emoji.png";
import { useLocation } from "react-router";

// DEFINE MAIN FUNCTIONS

const EmailSetUpScore = ({ score }) => {
  const location = useLocation()
  // DEFINE MAIN FUNCTIONS RETURN
  return (
    <Col xs={24} sm={location.pathname.includes('/deliverability-test-public') ? 12 : 12}>
      <div className={styles.emailSetupMainContaienr}>
        <h3>Domain Health Score</h3>
        <div className={styles.progressContainer}>
          <Progress
            type="circle"
            strokeWidth={10}
            trailColor={"#EAECF0"}
            strokeColor={
              score >= 90
                ? "#47CD89"
                : score <= 90 && score >= 81
                  ? "rgb(135 196 26)"
                  : score <= 80 && score >= 61
                    ? "#dbe01d"
                    : score <= 60 && score >= 41
                      ? "#F5C330"
                      : score <= 40 && score >= 20
                        ? "#ff912b"
                        : "#F97066"
            }
            percent={score}
            format={(percent) => (
              <>
                <div className={styles.circleProgressText}>
                  <span>{percent}%</span>
                  {score !== 0 ? (
                    <p
                      className={
                        score >= 90
                          ? `${styles.excellentText}`
                          : score <= 90 && score >= 81
                            ? `${styles.veryGoodText}`
                            : score <= 80 && score >= 61
                              ? `${styles.goodText}`
                              : score <= 60 && score >= 41
                                ? `${styles.AverageText}`
                                : score <= 40 && score >= 20
                                  ? `${styles.poorText}`
                                  : `${styles.terribleText}`
                      }
                    >
                      <img
                        src={
                          score >= 90
                            ? excellentEmoji
                            : score <= 90 && score >= 81
                              ? veryGoodEmoji
                              : score <= 80 && score >= 61
                                ? goodEmoji
                                : score <= 60 && score >= 41
                                  ? AverageEmoji
                                  : score <= 40 && score >= 20
                                    ? poorEmoji
                                    : terribleEmoji
                        }
                        alt="emoji"
                        width={20}
                        height={20}
                      />
                      <span>
                        {score >= 90
                          ? "Excellent"
                          : score <= 90 && score >= 81
                            ? "Very good"
                            : score <= 80 && score >= 61
                              ? "Good"
                              : score <= 60 && score >= 41
                                ? "Average"
                                : score <= 40 && score >= 20
                                  ? "Poor"
                                  : "Terrible"}
                      </span>
                    </p>
                  ) : (
                    <div className={styles.inProgressTezxt}>
                      <p>In progress...</p>
                    </div>
                  )}
                </div>
              </>
            )}
            size={170}
          />
          {score !== 0 ? (
            <button
              className={`${styles.inboxplacementbtn} 
            ${score >= 90
                  ? `${styles.excellentText}`
                  : score <= 90 && score >= 81
                    ? `${styles.veryGoodText}`
                    : score <= 80 && score >= 61
                      ? `${styles.goodText}`
                      : score <= 60 && score >= 41
                        ? `${styles.AverageText}`
                        : score <= 40 && score >= 20
                          ? `${styles.poorText}`
                          : `${styles.terribleText}`
                }`}
            >
              <FaCheckCircle
                className={styles.checkIcons}
                color={
                  score >= 90
                    ? "#47CD89"
                    : score <= 90 && score >= 81
                      ? "rgb(135 196 26)"
                      : score <= 80 && score >= 61
                        ? "#dbe01d"
                        : score <= 60 && score >= 41
                          ? "#e0aa0b"
                          : score <= 40 && score >= 20
                            ? "#ff912b"
                            : "#F97066"
                }
              />
              <span>
                {score >= 90
                  ? "Your setup looks great. Keep it up"
                  : score <= 90 && score >= 81
                    ? "Your setup is adequate and can be improved further"
                    : score <= 80 && score >= 61
                      ? "Your setup is acceptable but we suggest you to take actions"
                      : score <= 60 && score >= 41
                        ? "You setup needs attention. Take actions soon"
                        : score <= 40 && score >= 20
                          ? "Your setup is completely broken. Fix it as soon as possible"
                          : "Don't send emails with this setup health."}
              </span>
            </button>
          ) : (
            <button className={styles.inProgressTestButton} disabled={true}>
              <Skeleton.Button
                active={true}
                size="small"
                block={true}
                className={styles.inProgressLine}
              />
            </button>
          )}
        </div>
      </div>
    </Col>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default EmailSetUpScore;
