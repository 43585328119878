import { Checkbox, Modal, Spin } from "antd";
import React, { useState } from "react";
import "./ActiveEmailModal.css";
import { LoadingOutlined } from "@ant-design/icons";
import { getItem } from "../../../utility/localStorageControl";

// DEFINE MAIN FUNCTION
const ActiveEmailModal = ({
  activeEmailModal,
  onClose,
  selectedValue,
  onSubmit,
  loading,
  type,
}) => {
  // DEFINE ALL STATES
  const [messageShow, setMessageShow] = useState(
    getItem("userDetails")?.message_dont_show
  );

  // DEFINE ALL STATES
  return (
    <div>
      <Modal
        open={activeEmailModal}
        className={`activeModal ${type == "template" ? "template_modal" : ""}`}
        footer={null}
        centered
        width={450}
        onCancel={() => {
          onClose();
        }}
        maskStyle={{
          backdropFilter: "blur(8px) saturate(35%)",
          backgroundColor: "rgba(16, 24, 40, 0.35)",
        }}
      >
        <div className="activeModalContent">
          <div className="activeModalHeader">
            <span>
              {type == "template"
                ? "Active Confirmtion"
                : "Activating email warm-up for this email account?"}
            </span>
          </div>
          <div className="activeModalDesc">
            <p>
              {type == "template"
                ? "Are you sure you want to active on this template?"
                : "Enabling warm-up would allow Mail Optimal to send warm-up emails from this email account, and you will also receive warm-up emails from other Mail Optimal users."}
            </p>
          </div>
          <span className="connect-email-google-form">
            <Checkbox
              onChange={(event) => {
                setMessageShow(event.target.checked);
              }}
              className="customCheck"
            >
              <span>Don’t show me this message again</span>
            </Checkbox>
          </span>
        </div>
        <div className="ActiveModalFooter">
          <button
            onClick={() => {
              if (!loading) {
                onSubmit(selectedValue?.id, true, messageShow);
              }
            }}
          >
            {loading ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      color: "white",
                    }}
                    spin
                  />
                }
              />
            ) : (
              "Enable"
            )}
          </button>
          <button
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

// DEFINE EXPORT MAIN FUNCTION
export default ActiveEmailModal;
