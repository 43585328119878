import { message } from "antd";
import { DataService } from "../../config/DataService";
import { API } from "../../config/api/Index";
import { setItem } from "../../utility/localStorageControl";

export const createDeliverabilityTestApi = (payload) => {
  return async (dispatch) => {
    try {
      const resp = await DataService.post(
        API.deliverabilityTest.create,
        payload
      );
      console.log("response: ", resp)
      if (resp.data.status) {
        return { status: true, data: resp.data.data };
      } else {
        message.error(resp.data.message);
        console.log("response: ", resp.data)
        return { status: false };
      }
    } catch (error) {
      console.log("error: ", error)
      console.log("error: ", error?.response)
      console.log("error.message", error.message, typeof error.message)

      // if (error.message == "Network Error") {
      //   console.log("Network Error")
      //   setTimeout(function () {
      //     console.log("Retrying...");
      //     dispatch(createDeliverabilityTestApi(payload))
      //   }, 5000)

      // }
      // if (error)
      //   return { status: false };
    }
  };
};

export const getDeliverabilityTestListApi = (params) => {
  return async (dispatch) => {
    let qry = ``;

    if (params.limit && params.page) {
      // &createdAt=${params?.createAt}&setup_score=${params?.setup_score}&placement_score=${params?.placement_score}
      qry += `?page=${params.page}&limit=${params.limit}&search=${params.search}&createdAt=${params.createdAt}&setup_score=${params?.setup_score}&placement_score=${params?.placement_score}`;
    }
    if (params.email_account_id) {
      qry += `&email_account_id=${params.email_account_id}`;
    }
    // sorting
    try {
      const resp = await DataService.get(API.deliverabilityTest.list + qry);

      console.log("getDeliverabilityTestListApi resp", resp);

      if (resp.data.status) {
        console.log("resp.data.data", resp.data.data);
        dispatch({
          type: API.deliverabilityTest.list,
          deliverabilityTestList: resp.data,
        });
        return true;
      } else {
        message.error(resp.data.message);
        return { status: false };
      }
    } catch (error) {
      console.log("createDeliverabilityTest error", error);
      return { status: false };
    }
  };
};

// DELETE MICROSOFT API CALL
export const EmailDeliverabilityTemplateDelete = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(
        API.deliverabilityTest.delete + "/" + id
      );
      if (response.data.status) {
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
export const deliverabilityTestDataById = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        API.deliverabilityTest.getById + "/" + id
      );
      if (response.data.status) {
        return response.data.data;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
