import { Modal, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import "./DeleteEmailModal.css";

// DEFINE MAIN FUNCTION
const DeleteEmailModal = ({
  deleteEmailModal,
  onClose,
  onSubmit,
  selectedValue,
  loading,
  type,
}) => {
  // DEFINE MAIN FUNCTION RETURN
  return (
    <div>
      <Modal
        open={deleteEmailModal}
        className={`deleteModal ${
          type == "template" || type == "type" ? "template_modal" : ""
        }`}
        footer={null}
        centered
        width={450}
        onCancel={() => {
          onClose();
        }}
        maskStyle={{
          backdropFilter: "blur(8px) saturate(35%)",
          backgroundColor: "rgba(16, 24, 40, 0.35)",
        }}
      >
        <div className="deleteModalContent">
          <div className="modalHeader">
            {type == "warmup" ? (
              <span>Do you want to delete this email account?</span>
            ) : type == "test" ? (
              <span>Delete Deliverability Test</span>
            ) : (
              <span>Delete Template</span>
            )}
          </div>

          <div className="modalDesc">
            {type == "warmup" ? (
              <span>
                If warm-up is enabled for this account, them it would
                <br />
                be disabled and removed from the email account pool.
              </span>
            ) : (
              ""
            )}

            <span>
              {" "}
              {type == "warmup"
                ? "Instead of deleting. You can disconnect your account."
                : type == "test"
                ? "Do you want to delete this deliverability test?"
                : "Do you want to delete this template?"}
            </span>
          </div>
        </div>
        <div className="deleteModalFooter">
          <button
            onClick={() => {
              if (!loading) {
                onSubmit(selectedValue?.id);
              }
            }}
          >
            {loading ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      color: "white",
                    }}
                    spin
                  />
                }
              />
            ) : (
              "Delete"
            )}
          </button>
          <button
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default DeleteEmailModal;
