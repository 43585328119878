import React from "react";
import style from "./ConnectAccount.module.css";
import { useNavigate } from "react-router-dom";
import rightDirectionSVG from "../../../assets/svg/rightSide.svg";
import { useMicrosoftConnect } from "../../../state/EmailConnect/Emailconnect.hook";
import GoogleSVGIcon from "../../../assets/dashboard/GoogleSVGIcon";
import MicrisoftSVGIcon from "../../../assets/dashboard/MicrisoftSVGIcon";
import SMTPSVGIcon from "../../../assets/dashboard/SMTPSVGIcon";
import { setItem } from "../../../utility/localStorageControl";

// DEFINE MAIN FUNCTION
const AccountList = () => {
  // DEFINE ALL STATES
  const navigate = useNavigate();

  const navigateToMicroSoft = (data) => {
    window.location.href = data.url;
  };

  const { mutate: connectMicroSoft } = useMicrosoftConnect(navigateToMicroSoft);

  // RETURN MAIN FUNCTION
  return (
    <div className={style.emailConnectListComponent}>
      <div
        className={style.emailContent}
        onClick={() => {
          navigate("/new-email/google");
          setItem("userInfo", false);
        }}
      >
        <div className={style.icone}>
          <div>
            <GoogleSVGIcon />
          </div>
          <div></div>
          <div>
            <span>Google</span>
            <span>Gmail & Google Workspace</span>
          </div>
        </div>
        <img src={rightDirectionSVG} />
      </div>
      <div
        className={style.emailContent}
        onClick={() => {
          connectMicroSoft();
        }}
      >
        <div className={style.icone} o>
          <div>
            <MicrisoftSVGIcon />
          </div>
          <div></div>
          <div>
            <span>Microsoft</span>
            <span>Exchange, O365, Outlook & Hotmail</span>
          </div>
        </div>
        <img src={rightDirectionSVG} />
      </div>
      <div
        className={style.emailContent}
        onClick={() => {
          navigate("/new-email/smtp");
          setItem("userInfo", false);
        }}
      >
        <div className={style.icone}>
          <div>
            <SMTPSVGIcon />
          </div>
          <div></div>
          <div>
            <span>SMTP/IMAP</span>
            <span>Any other Email Service provider account</span>
          </div>
        </div>
        <img src={rightDirectionSVG} />
      </div>
    </div>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default AccountList;
