import React, { useEffect, useState } from "react";
import logo from "../../assets/logo.svg";
import { Layout, Popover } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { capitalizeFirstWordLetter } from "../../config/CommonFuntion";
import { logOut } from "../../redux/authentication/action";
import logOutSVG from "../../assets/svg/logOut.svg";
import DashBoardIcon from "../../assets/svg/DashBoardIcon";
import TemplateSvgIcons from "../../assets/svg/TemplateSvgIcons";
import DeliverabilitySvgIcons from "../../assets/svg/DeliverabilitySvgIcons";
import SubscriptionSvgIcons from "../../assets/svg/SubscriptionSvgIcons";
import SettingSvgIcons from "../../assets/svg/SettingSvgIcons";
import { getItem } from "../../utility/localStorageControl";

// DEFINE MAIN FUNCTION
const LeftMenu = ({ collapsed }) => {
  // DEIFNE ALL STATES
  const { Sider } = Layout;
  const [getId, setgetId] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (getItem("userDetails")) {
      setgetId(getItem("userDetails")?.subscribed);
      console.log(getId);
    }
  }, []);
  // DEFINE REDUX IN GET DATA
  let userDetails = useSelector((state) => state.auth.userDetails);
  let showLayoutStatus = location.pathname.includes("connect");

  // DEFINE LOGOUT FUNCTION
  const onLogOutClick = async () => {
    await dispatch(logOut(true));
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <Sider
      theme="light"
      style={{
        minHeight: showLayoutStatus
          ? "100vh"
          : userDetails?.status === "pending"
          ? "calc(100vh - 70px)"
          : "100vh",
      }}
      collapsed={collapsed}
    >
      <div className="sidebar-logo">
        <img
          onClick={() => {
            navigate("/email-warmup");
          }}
          src={logo}
          alt="logo"
        />
      </div>

      <div className="sidebar-top-menus">
        <Popover
          overlayClassName="sidebar"
          placement="right"
          title={"Dashboard"}
        >
          <div
            className={`${
              location.pathname.includes("/email-warmup") ||
              location.pathname.includes("/new-email")
                ? "top-menu-selected"
                : ""
            }`}
            onClick={() => {
              navigate("/email-warmup");
            }}
          >
            <DashBoardIcon className={"svgIcons"} />
          </div>
        </Popover>

        <Popover
          overlayClassName="sidebar"
          placement="right"
          title={"Templates"}
        >
          <div
            className={`${
              location.pathname.includes("templates") ? "top-menu-selected" : ""
            }`}
            onClick={() => {
              navigate("/templates");
            }}
          >
            <TemplateSvgIcons className={"templatesvgIcons"} />
          </div>
        </Popover>

        <Popover
          overlayClassName="sidebar"
          placement="right"
          title={"Deliverability Test"}
        >
          <div
            className={`${
              location.pathname.includes("deliverability")
                ? "top-menu-selected"
                : ""
            }`}
            onClick={() => {
              navigate("/deliverability-test");
            }}
          >
            <DeliverabilitySvgIcons className={"deliverabilitySvgIcons"} />
          </div>
        </Popover>

        <Popover
          overlayClassName="sidebar"
          placement="right"
          title={"Subscription"}
        >
          <div
            className={`${
              location.pathname.includes("billing-and-subscription")
                ? "top-menu-selected"
                : ""
            }`}
            onClick={() => {
              navigate("/billing-and-subscription");
            }}
          >
            <SubscriptionSvgIcons className={"subscriptionSvgIcons"} />
          </div>
        </Popover>
      </div>

      <div className="sidebar-bottom-menus">
        <Popover
          overlayClassName="sidebar"
          placement="right"
          title={"Settings"}
        >
          <div
            className={`setting${
              location.pathname.includes("/setting") ? "-selected" : ""
            }`}
            onClick={() => {
              navigate("/setting/profile");
            }}
          >
            <SettingSvgIcons className={"settingSvgIcons"} />
          </div>
        </Popover>
        <Popover
          overlayClassName="sidebar"
          placement="right"
          trigger={"click"}
          content={
            <div>
              <span
                onClick={() => {
                  onLogOutClick();
                }}
                className="log-out-menu"
              >
                <img src={logOutSVG} />
                Logout
              </span>
            </div>
          }
        >
          <div className="profile">
            {capitalizeFirstWordLetter(
              userDetails?.first_name,
              userDetails?.last_name
            )}
          </div>
        </Popover>
      </div>
    </Sider>
  );
};
// DEFINE MAIN FUNCTION EXPORT
export default LeftMenu;
