import React, { useEffect, useRef, useState } from "react";
import AuthLayout from "./component/authLayout/Layout";
import DashBoardLayout from "./component/dashBoardLayout/Layout";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { getUserDetail } from "./state/User/user.api";
import { getItem } from "./utility/localStorageControl";
import PublicDeliverability from "./containers/admin/PublicDeliverability";


// DEFINE MAIN FUNCTION
const App = () => {
  // DEFINE ALL STATES
  let auth = useSelector((state) => {
    if (state?.userDetails && Object.keys(state.userDetails).length) {
      return state.userDetails;
    } else if (getItem("userDetails")) {
      return getItem("userDetails");
    } else {
      return {};
    }
  });
  // const auth = getItem("userDetails");
  let dispatch = useDispatch();
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const { data, isLoading } = useQuery({
    queryKey: ["userDetail"],
    queryFn: () => getUserDetail(getItem("userDetails")?.id, dispatch),
    enabled: getItem("userDetails")?.id ? true : false,
  });
  console.log("data", data);

  // DEFINE USEEFFECT
  useEffect(() => {
    // setWidth(ref.current.offsetWidth);
    const getwidth = () => {
      // setWidth(ref.current.offsetWidth);
    };
    window.addEventListener("resize", getwidth);
    return () => window.removeEventListener("resize", getwidth);
  }, []);

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      {/* <div ref={ref}> */}
      <BrowserRouter>

        {auth?.signup_step == "3" ? (
          <DashBoardLayout width={width} />
        ) : (
          <AuthLayout width={width} />
        )}

      </BrowserRouter>
      {/* </div> */}
    </>
  );
};
// DEFINE MAIN FUNCTION EXPORT
export default App;
