import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row, Select, Spin, notification } from "antd";
import style from "./Setting.module.css";
import {
  TimeZonList,
  capitalizeFirstLetter,
} from "../../../config/CommonFuntion";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../../redux/authentication/action";
import { useNavigate } from "react-router-dom";
import successSVG from "../../../assets/svg/success.svg";
import cancelSVG from "../../../assets/svg/cancel.svg";
import {
  useMutationPassWordUpdate,
  useMutationProfileUpdate,
} from "../../../state/setting/setting.hook.js";
import ProfileUserIcons from "../../../assets/svg/ProfileUserIcons.js";
import PasswordUserIcon from "../../../assets/svg/PasswordUserIcon.js";
import rightIcon from "../../../assets/Email/notification-right.png";

// DEFINE MAIN FUNCTION
const Setting = () => {
  // DEFINE ALL STATES
  const [form] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showValidationComponent, setShowValidationComponent] = useState(false);
  const [formValidateStatus, setFormValidateStatus] = useState(false);
  const [passwordFormValidateStatus, setPasswordFormValidateStatus] =
    useState(false);
  const [selectedComponent, setSelectedComponent] = useState("profile");
  const [
    showPasswordValidationsComponent,
    setShowPasswordValidationsComponent,
  ] = useState({
    upperCase: false,
    number: false,
    lowerCase: false,
    length: false,
  });

  // DEFINE ALL REDUX DATA
  let authDetails = useSelector((state) => state.auth.userDetails);

  //DEFINE USEMUTATION FOR PROFILEUPDATE API
  const { mutate: onFinish, loading } = useMutationProfileUpdate(
    authDetails?.id
  );

  // DEFINE FUNCTION FOR SUCCESS MESSAGE CHANGE
  const successPasswordChange = (success) => {
    if (success) {
      notification.success({
        message: (
          <span className="notificationText">
            Password has been changed successfully
          </span>
        ),
        description: (
          <span className="startBorder" style={{ height: "88px" }}></span>
        ),
        icon: <img src={rightIcon} alt="right-icon" width={20} height={20} />,
        className: "custom-notification",
        duration: 3,
        style: { width: "362px", height: "88px" },
      });
      passwordForm.resetFields();
      setShowValidationComponent(false);
    } else {
      setShowValidationComponent(false);
    }
  };

  //DEFINE USEMUTATION FOR RESETPASSWORD API
  const { mutate: onPasswordFormFinish, passwordLoading } =
    useMutationPassWordUpdate(successPasswordChange);

  // DEFINE USEEFFECT FOR FORM VALIDATION
  useEffect(() => {
    form.setFieldsValue(authDetails);
    onCheckFormChangeValue("", {
      email: authDetails.email,
      first_name: authDetails.first_name,
      last_name: authDetails.last_name,
      time_zone: authDetails.time_zone,
    });
  }, []);

  // DEFINE FUNCTION FOR FORM ONCHANGE VALUES VALIDATIONS CHECK
  const onCheckFormChangeValue = (changedValues, allValues) => {
    if (
      !Object.values(allValues).includes(undefined) &&
      !Object.values(allValues).includes("")
    ) {
      setFormValidateStatus(true);
    } else {
      setFormValidateStatus(false);
    }
  };

  // DEFINE FUNCTION FOR PASSWORD FORM ONCHANGE VALUES VALIDATIONS CHECK
  const onCheckPasswordFormChangeValue = (changedValues, allValues) => {
    if (
      !Object.values(allValues).includes(undefined) &&
      !Object.values(allValues).includes("")
    ) {
      const regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+{}|:"<>?`\-=[\]\\;',./]{8,64}$/;
      if (!allValues.new_password || regex.test(allValues.new_password)) {
        if (allValues.new_password == allValues.confirm_password) {
          setPasswordFormValidateStatus(true);
        } else {
          setPasswordFormValidateStatus(false);
        }
      } else {
        setPasswordFormValidateStatus(false);
      }
    } else {
      setPasswordFormValidateStatus(false);
    }
  };

  // DEFINE FUNCTION FOR FORGOT PASSWORD BUTTON CLICK
  const onForgotPasswordClick = async () => {
    let response = await dispatch(logOut(false));
    if (response) {
      navigate("/reset-password");
    }
  };

  // DEFINE FUNCTION FOR CUSTOM VALIDATION PASSWORD
  const validatePassword = (rule, value, callback) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+{}|:"<>?`\-=[\]\\;',./]{8,64}$/;
    if (!value || regex.test(value)) {
      callback();
      setShowValidationComponent(true);
    } else {
      setShowValidationComponent(false);

      callback("Please enter strog password");
    }
    const regexUpperCase = /[A-Z]/;
    const regexLowerCase = /[a-z]/;
    const regexNumber = /\d/;
    const lengthValid = value.length >= 8 && value.length <= 64;
    setShowPasswordValidationsComponent({
      upperCase: regexUpperCase.test(value),
      number: regexNumber.test(value),
      lowerCase: regexLowerCase.test(value),
      length: lengthValid,
    });
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <Row gutter={25}>
        <Col xxl={6} xl={6} lg={6} md={12} sm={24} xs={24}>
          <div
            className={style.sidebarContainer}
            style={{
              minHeight: authDetails?.status == "pending" ? "76vh" : "85vh",
            }}
          >
            <div
              onClick={() => {
                setShowValidationComponent(false);
                setSelectedComponent("profile");
                navigate('/setting/profile')
              }}
              className={selectedComponent == "profile" ? style.selected : ""}
            >
              <ProfileUserIcons className={style.icons} />
              <span>Profile</span>
            </div>
            <div
              onClick={() => {
                setShowValidationComponent(false);
                setSelectedComponent("password");
                navigate('/setting/password')
              }}
              className={selectedComponent == "password" ? style.selected : ""}
            >
              <PasswordUserIcon className={style.icons} />
              <span>Password</span>
            </div>
          </div>
        </Col>
        <Col xxl={18} xl={18} lg={18} md={12} sm={24} xs={24}>
          <div
            style={{
              minHeight: authDetails?.status == "pending" ? "76vh" : "85vh",
            }}
            className={style.mainContainer}
          >
            <div className={style.settingHeader}>
              {capitalizeFirstLetter(selectedComponent)}
            </div>
            {selectedComponent == "profile" ? (
              <Form
                layout="vertical"
                name="basic"
                size="large"
                requiredMark={false}
                form={form}
                onFinish={(value) => {
                  if (!loading) {
                    onFinish(value);
                  }
                }}
                onValuesChange={(value, allValues) => {
                  onCheckFormChangeValue(value, allValues);
                }}
                autoComplete="off"
              >
                <Row gutter={12}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label="First Name"
                      name="first_name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your first name!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter First Name" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label="Last Name"
                      name="last_name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your last name!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Last Name" />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  label="Email Address"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your password!",
                    },
                    {
                      type: "email",
                      message: "Please enter valid email!",
                    },
                  ]}
                >
                  <Input
                    className="disabled-input"
                    disabled
                    placeholder="Enter Email"
                  />
                </Form.Item>
                <Form.Item
                  label="Timezone"
                  name="time_zone"
                  rules={[
                    {
                      required: false,
                      message: "Please select your timezone!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select Timezone"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={TimeZonList}
                  />
                </Form.Item>
                <Form.Item>
                  <button
                    type="submit"
                    style={{
                      opacity: formValidateStatus ? "100%" : "35%",
                    }}
                  >
                    {loading ? <Spin /> : "Save Changes"}
                  </button>
                </Form.Item>
              </Form>
            ) : (
              <>
                <Form
                  layout="vertical"
                  name="basic"
                  className={style.passwordForm}
                  size="large"
                  requiredMark={false}
                  form={passwordForm}
                  onFinish={(value) => {
                    if (!passwordLoading) {
                      onPasswordFormFinish(value);
                    }
                  }}
                  onValuesChange={(value, allValues) => {
                    onCheckPasswordFormChangeValue(value, allValues);
                  }}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Old Password"
                    name="old_password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your old password!",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Enter Old Password" />
                  </Form.Item>
                  <Form.Item
                    label="New Password"
                    name="new_password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your new password!",
                      },
                      { validator: validatePassword },
                    ]}
                    hasFeedback
                  >
                    <Input.Password placeholder="Enter New Password" />
                  </Form.Item>
                  <Form.Item
                    label="Confirm New Password"
                    name="confirm_password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your confirm new password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (
                            !value ||
                            getFieldValue("new_password") === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            "The two passwords that you entered do not match!"
                          );
                        },
                      }),
                    ]}
                    hasFeedback
                  >
                    <Input.Password placeholder="Enter Confirm New Password" />
                  </Form.Item>
                  <div
                    onClick={() => {
                      onForgotPasswordClick();
                    }}
                    className={style.forgotPasswordDescription}
                  >
                    <span>Forgot Password?</span>
                  </div>
                  {showValidationComponent ? (
                    <>
                      <div className={style.passwordValidations}>
                        <p>Password Must Contain</p>
                        <span>
                          {showPasswordValidationsComponent?.upperCase ? (
                            <img src={successSVG} />
                          ) : (
                            <img src={cancelSVG} />
                          )}
                          <label>One uppercase character</label>
                        </span>
                        <span>
                          {showPasswordValidationsComponent?.number ? (
                            <img src={successSVG} />
                          ) : (
                            <img src={cancelSVG} />
                          )}
                          <label>One numerical character</label>
                        </span>
                        <span>
                          {showPasswordValidationsComponent?.lowerCase ? (
                            <img src={successSVG} />
                          ) : (
                            <img src={cancelSVG} />
                          )}
                          <label>One lowercase character</label>
                        </span>
                        <span>
                          {showPasswordValidationsComponent?.length ? (
                            <img src={successSVG} />
                          ) : (
                            <img src={cancelSVG} />
                          )}
                          <label>Characters between 8 - 64</label>
                        </span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <Form.Item>
                    <button
                      type="submit"
                      typeof="submit"
                      style={{
                        opacity: passwordFormValidateStatus ? "100%" : "35%",
                      }}
                    >
                      {passwordLoading ? <Spin /> : "Save Changes"}
                    </button>
                  </Form.Item>
                </Form>
              </>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default Setting;
