export const domainList = [
  {
    id: 1,
    name: "SPF",
  },
  {
    id: 2,
    name: "DKIM",
  },
  {
    id: 3,
    name: "DMARC",
  },
  {
    id: 4,
    name: "rDNS",
  },
  {
    id: 5,
    name: "MX",
  },
  // {
  //   id: 6,
  //   name: "A Record",
  // },
];

export const dataInbox = [
  {
    id: 1,
    name: "Inbox",
    email: "johndoe123@gmail.com",
  },
  {
    id: 2,
    name: "Inbox",
    email: "johndoe123@gmail.com",
  },
  {
    id: 3,
    name: "Inbox",
    email: "johndoe123@gmail.com",
  },
  {
    id: 4,
    name: "Inbox",
    email: "johndoe123@gmail.com",
  },
  {
    id: 5,
    name: "Inbox",
    email: "johndoe123@gmail.com",
  },
  {
    id: 6,
    name: "Inbox",
    email: "johndoe123@gmail.com",
  },
  {
    id: 7,
    name: "Inbox",
    email: "johndoe123@gmail.com",
  },
  {
    id: 8,
    name: "Inbox",
    email: "johndoe123@gmail.com",
  },
  {
    id: 9,
    name: "Inbox",
    email: "johndoe123@gmail.com",
  },
  {
    id: 10,
    name: "Inbox",
    email: "johndoe123@gmail.com",
  },
  {
    id: 11,
    name: "Inbox",
    email: "johndoe123@gmail.com",
  },
  {
    id: 12,
    name: "Inbox",
    email: "johndoe123@gmail.com",
  },
];

export const EmailBoxData = [
  {
    id: 1,
    name: "Inbox",
    percentage: "95%",
  },
  {
    id: 2,
    name: "Categories",
    percentage: "0%",
  },
  {
    id: 3,
    name: "Spam",
    percentage: "3%",
  },
  {
    id: 4,
    name: "Not delivered",
    percentage: "3%",
  },
];
