// DEFINE EXTERNAL IMPORT
import { Slider, Table, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// DEFINE INTERNAL IMPORT
import styles from "../ChoosePlan.module.css";
import StarterIcons from "../../../../../assets/subscription/Starter.png";
import GrowthIcons from "../../../../../assets/subscription/Growth.png";
import BusinessIcons from "../../../../../assets/subscription/Business.png";
import EnterpriseIcons from "../../../../../assets/subscription/Enterprise.png";
import chackIcons from "../../../../../assets/subscription/checkIcons.png";
import dashIcons from "../../../../../assets/subscription/dashIcons.png";
import { createSubscription } from "../../../../../redux/subscription-plan/action";
import { getItem } from "../../../../../utility/localStorageControl";
import { capitalizeFirstLetter } from "../../../../../config/CommonFuntion";

// DEFINE TITLE FUNCTION
const TitleComponents = ({ iconSrc, text }) => {
  // DEFINE TITLE FUNCTION RETURN
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {text === "Growth" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              minWidth: "100%",
            }}
          >
            <button className={styles.popularBtn}>
              <span>MOST POPULAR</span>
            </button>
          </div>
        ) : (
          ""
        )}
        <div className={styles.headerContaienr}>
          <div>
            <img src={iconSrc} alt="icons" />
          </div>
          <p>{text}</p>
        </div>
      </div>
    </>
  );
};

// DEFINE HEADER FUNCTION
const HeaderPricePlanComponent = ({
  plan_type,
  id,
  planData,
  btnText,
  passPlanId,
}) => {
  const dispatch = useDispatch();
  console.log("plandata", planData);

  const [getId, setgetId] = useState("");
  const [sliderValue, setSliderValue] = useState(10);
  const [createSubscriptionLoading, setCreateSubscriptionLoading] =
    useState(false);

  useEffect(() => {
    if (getItem("userDetails")) {
      setgetId(getItem("userDetails")?.subscriptions?.subscriptionPlan?.id);
    }
  }, [getItem("userDetails")?.subscriptions?.subscriptionPlan?.id]);

  const handleUpgrade = async (selectedPlan) => {
    console.log("selectedPlan==>", selectedPlan);
    if (selectedPlan?.subscription_plan_id !== getId) {
      setCreateSubscriptionLoading(true);
      console.log(
        "planData[0]?.id ? planData[0]?.id",
        planData[0]?.id ? planData[0]?.id : id
      );
      if (selectedPlan || id) {
        let payload = {
          subscription_id: selectedPlan?.subscription_plan_id,
          subscription_product_id: selectedPlan?.subscriptionPlanFeature?.id,
        };

        const response = await dispatch(createSubscription(payload));
        if (response.status) {
          window.location.replace(response?.data?.url);
          setCreateSubscriptionLoading(false);
        } else {
          setCreateSubscriptionLoading(false);
        }
      } else {
        console.log("No plan selected.");
      }
    }
  };

  const handleSliderChange = (value, updatedSelectedPlanIndex) => {
    console.log("value of data", value);
    setSliderValue(value);
    console.log("updatedSelectedPlanIndex", updatedSelectedPlanIndex);
    passPlanId(updatedSelectedPlanIndex);
  };
  const calculateSelectedPlanIndex = (value, planData) => {
    console.log("planData-------------->", planData);
    const index = Math.floor(value / (100 / planData?.length));
    passPlanId(index);
    return Math.min(Math.max(index, 0), planData?.length - 1);
  };
  let sortedPlanData = [...planData].sort((a, b) => a.amount - b.amount);
  if (planData.subscription_plan_type == "enterprise") {
    sortedPlanData = [...planData].sort((a, b) => a.amount - b.amount);
  }
  const updatedSelectedPlanIndex = calculateSelectedPlanIndex(
    sliderValue,
    sortedPlanData
  );
  const selectedPlan = sortedPlanData[updatedSelectedPlanIndex];
  console.log("selectedPlan----=================>", selectedPlan);
  // DEFINE HEADER FUNCTION RETURN
  return (
    <div className={styles.priceContainer}>
      <div>
        <h2>
          {selectedPlan.iso_code == "IN" ? "₹" : "$"}{" "}
          {selectedPlan?.subscriptionProduct?.amount}
        </h2>
        <p>/ {selectedPlan?.subscription_plan_validity}</p>
      </div>

      <Slider
        defaultValue={sliderValue}
        onChange={(val) => handleSliderChange(val, updatedSelectedPlanIndex)}
        tooltip={{ open: false }}
        className={`${
          plan_type == "enterprise" ? styles.slider : styles.sliderHidden
        }`}
      />

      <div
        className={`${styles.priceContainer} ${
          id == getId
            ? styles.growthSubscriptionBtn
            : // ?
              styles.btnMargin
          // : ""
        }`}
      >
        <button
          onClick={() => {
            if (!createSubscriptionLoading) {
              handleUpgrade(selectedPlan?.subscriptionProduct);
              console.log("selected plan", selectedPlan?.subscriptionProduct);
            }
          }}
          size="large"
          className={`${
            plan_type === "enterprise" ? styles.enterpriseBtn : ""
          }`}
        >
          <span>{createSubscriptionLoading ? <Spin /> : btnText}</span>
        </button>
      </div>
    </div>
  );
};

// DEFINE MAIN FUNCTION
const MonthlySubscription = (monthData) => {
  const [getId, setgetId] = useState("");
  const [planId, setPlaneId] = useState(0);
  console.log("planId", planId);
  useEffect(() => {
    if (getItem("userDetails")) {
      console.log(
        "first",
        getItem("userDetails")?.subscriptions?.subscriptionPlan?.id
      );
      setgetId(getItem("userDetails")?.subscriptions?.subscriptionPlan?.id);
    }
  }, [monthData]);

  const { starter, growth, business, enterprise } = monthData?.monthData || {};
  const icons = {
    Starter: StarterIcons,
    Growth: GrowthIcons,
    Business: BusinessIcons,
    Enterprise: EnterpriseIcons,
  };

  //   {
  //     key: "2",
  //     name: "Mailbox",
  //   },
  //   {
  //     key: "3",
  //     name: "Monthly Warmup Emails",
  //   },
  //   {
  //     key: "4",
  //     name: "Daily Sending Limit Per Mailbox",
  //   },
  //   {
  //     key: "5",
  //     name: "Email Deliverability Tests",
  //   },
  //   {
  //     key: "6",
  //     name: "Basic Warmup Settings",
  //   },
  //   {
  //     key: "7",
  //     name: "Advanced Warmup Settings",
  //   },
  //   {
  //     key: "8",
  //     name: "Custom Templates",
  //   },
  //   {
  //     key: "9",
  //     name: "Mark Email As Important Activity",
  //   },
  //   {
  //     key: "10",
  //     name: "Multi-Reply Threads",
  //   },
  //   {
  //     key: "11",
  //     name: "Email Deliverability Consultation",
  //   },
  //   {
  //     key: "12",
  //     name: "Domain Reputation Audit By Experts",
  //   },
  //   {
  //     key: "13",
  //     name: "Priority Support",
  //   },
  // ];

  const plans = [
    { type: "Starter", data: starter },
    { type: "Growth", data: growth },
    { type: "Business", data: business },
    { type: "Enterprise", data: enterprise },
  ];

  // console.log("featureData", featureData);
  console.log("plansdata", plans[3].data);
  function handleDataplanId(data) {
    setPlaneId(data);
  }
  const renderPlanComponent = (planData, btnText) => {
    if (!planData || planData.length === 0) return "";

    const { subscription_plan_type, id } = planData[0];
    console.log("id ", id);
    console.log("subscription_plan_type", subscription_plan_type);
    return (
      <HeaderPricePlanComponent
        plan_type={`${subscription_plan_type}`}
        id={`${id}`}
        planData={planData}
        btnText={btnText}
        passPlanId={handleDataplanId}
      />
    );
  };

  const renderFeatureData = (feature) => {
    if (feature === true) return <img src={chackIcons} alt={"icons"} />;
    if (!feature) return <img src={dashIcons} alt={"icons"} />;
    return feature;
  };

  const columns = [
    {
      title: "",
      dataIndex: "name",
      width: "19%",
      align: "start",
    },
    {
      title: <TitleComponents iconSrc={StarterIcons} text={"Starter"} />,
      dataIndex: "starter",
      width: "19%",
      align: "center",
    },
    {
      title: <TitleComponents iconSrc={GrowthIcons} text={"Growth"} />,
      dataIndex: "growth",
      width: "19%",
      align: "center",
    },
    {
      title: <TitleComponents iconSrc={BusinessIcons} text={"Business"} />,
      dataIndex: "business",
      width: "19%",
      align: "center",
    },
    {
      title: <TitleComponents iconSrc={EnterpriseIcons} text={"Enterprise"} />,
      dataIndex: "enterprise",
      width: "19%",
      align: "center",
    },
  ];
  console.log(monthData);

  const data = [
    {
      key: "0",
      name: "",
      ...plans.reduce(
        (acc, { type }) => ({
          ...acc,
          [type.toLowerCase()]:
            monthData &&
            monthData?.monthData?.[type.toLowerCase()]?.length > 0 ? (
              <TitleComponents iconSrc={icons[type]} text={type} />
            ) : (
              ""
            ),
        }),
        {}
      ),
    },
    {
      key: "1",
      name: "",
      ...plans.reduce(
        (acc, { type }) => ({
          ...acc,
          [type.toLowerCase()]: renderPlanComponent(
            monthData && monthData?.monthData?.[type.toLowerCase()],
            monthData?.monthData?.[type.toLowerCase()]?.[0]?.id == getId
              ? "Current Plan"
              : "Upgrade"
          ),
        }),
        {}
      ),
    },
    {
      key: "2",
      name: "Email Accounts",
      ...plans.reduce((acc, { type }) => {
        let myData =
          monthData?.monthData?.[type.toLowerCase()][0]?.subscriptionProduct
            ?.subscriptionPlanFeature?.mail_box;
        if (type.toLocaleLowerCase() == "enterprise") {
          myData =
            monthData?.monthData?.[type.toLowerCase()][planId]
              ?.subscriptionProduct?.subscriptionPlanFeature?.mail_box;
        }

        return {
          ...acc,
          [type.toLowerCase()]: renderFeatureData(
            capitalizeFirstLetter(myData)
          ),
        };
      }, {}),
    },
    {
      key: "3",
      name: "Warmup Emails / Month",
      ...plans.reduce((acc, { type }) => {
        let myData =
          monthData?.monthData?.[type.toLowerCase()][0]?.subscriptionProduct
            ?.subscriptionPlanFeature?.monthly_warmup_emails;
        if (type.toLocaleLowerCase() == "enterprise") {
          myData =
            monthData?.monthData?.[type.toLowerCase()][planId]
              ?.subscriptionProduct?.subscriptionPlanFeature
              ?.monthly_warmup_emails;
        }
        return {
          ...acc,
          [type.toLowerCase()]: renderFeatureData(myData),
        };
      }, {}),
    },
    {
      key: "3.1",
      name: "Daily Sending Limit Per Mailbox",
      ...plans.reduce((acc, { type }) => {
        let myData =
          monthData?.monthData?.[type.toLowerCase()][0]?.subscriptionProduct
            ?.subscriptionPlanFeature?.daily_sending_limit_per_mailbox;
        if (type.toLocaleLowerCase() == "enterprise") {
          myData =
            monthData?.monthData?.[type.toLowerCase()][planId]
              ?.subscriptionProduct?.subscriptionPlanFeature
              ?.daily_sending_limit_per_mailbox;
        }

        return {
          ...acc,
          [type.toLowerCase()]: renderFeatureData(myData),
        };
      }, {}),
    },
    {
      key: "4",
      name: "Email Deliverability Tests (Coming Soon)",
      ...plans.reduce((acc, { type }) => {
        let myData =
          monthData?.monthData?.[type.toLowerCase()][0]?.subscriptionProduct
            ?.subscriptionPlanFeature?.email_deliverability_tests;
        if (type.toLocaleLowerCase() == "enterprise") {
          myData =
            monthData?.monthData?.[type.toLowerCase()][planId]
              ?.subscriptionProduct?.subscriptionPlanFeature
              ?.email_deliverability_tests;
        }
        return {
          ...acc,
          [type.toLowerCase()]: renderFeatureData(myData),
        };
      }, {}),
    },
    {
      key: "5",
      name: "Warmup Mode Selection",
      ...plans.reduce((acc, { type }) => {
        let myData =
          monthData?.monthData?.[type.toLowerCase()][0]?.subscriptionProduct
            ?.subscriptionPlanFeature?.warmup_mode_selection;
        if (type.toLocaleLowerCase() == "enterprise") {
          myData =
            monthData?.monthData?.[type.toLowerCase()][planId]
              ?.subscriptionProduct?.subscriptionPlanFeature
              ?.warmup_mode_selection;
        }
        return {
          ...acc,
          [type.toLowerCase()]: renderFeatureData(monthData && myData),
        };
      }, {}),
    },
    {
      key: "6",
      name: "Basic Warmup Settings",
      ...plans.reduce((acc, { type }) => {
        let myData =
          monthData?.monthData?.[type.toLowerCase()][0]?.subscriptionProduct
            ?.subscriptionPlanFeature?.basic_warmup_settings;
        if (type.toLocaleLowerCase() == "enterprise") {
          myData =
            monthData?.monthData?.[type.toLowerCase()][planId]
              ?.subscriptionProduct?.subscriptionPlanFeature
              ?.basic_warmup_settings;
        }
        return {
          ...acc,
          [type.toLowerCase()]: renderFeatureData(myData),
        };
      }, {}),
    },
    {
      key: "6.1",
      name: "Spam Correction",
      ...plans.reduce((acc, { type }) => {
        let myData =
          monthData?.monthData?.[type.toLowerCase()][0]?.subscriptionProduct
            ?.subscriptionPlanFeature?.spam_collection;
        if (type.toLocaleLowerCase() == "enterprise") {
          myData =
            monthData?.monthData?.[type.toLowerCase()][planId]
              ?.subscriptionProduct?.subscriptionPlanFeature?.spam_collection;
        }
        return {
          ...acc,
          [type.toLowerCase()]: renderFeatureData(myData),
        };
      }, {}),
    },
    {
      key: "7",
      name: "Advanced Warmup Settings",
      ...plans.reduce((acc, { type }) => {
        let myData =
          monthData?.monthData?.[type.toLowerCase()][0]?.subscriptionProduct
            ?.subscriptionPlanFeature?.advanced_warmup_settings;
        if (type.toLocaleLowerCase() == "enterprise") {
          myData =
            monthData?.monthData?.[type.toLowerCase()][planId]
              ?.subscriptionProduct?.subscriptionPlanFeature
              ?.advanced_warmup_settings;
        }
        return {
          ...acc,
          [type.toLowerCase()]: renderFeatureData(myData),
        };
      }, {}),
    },
    {
      key: "8",
      name: "Custom Warmup Template",
      ...plans.reduce((acc, { type }) => {
        let myData =
          monthData?.monthData?.[type.toLowerCase()][0]?.subscriptionProduct
            ?.subscriptionPlanFeature?.custom_templates;
        if (type.toLocaleLowerCase() == "enterprise") {
          myData =
            monthData?.monthData?.[type.toLowerCase()][planId]
              ?.subscriptionProduct?.subscriptionPlanFeature?.custom_templates;
        }
        return {
          ...acc,
          [type.toLowerCase()]: renderFeatureData(myData),
        };
      }, {}),
    },
    {
      key: "9",
      name: "Mark As Important Activity",
      ...plans.reduce((acc, { type }) => {
        let myData =
          monthData?.monthData?.[type.toLowerCase()][0]?.subscriptionProduct
            ?.subscriptionPlanFeature?.mark_email_as_important_activity;
        if (type.toLocaleLowerCase() == "enterprise") {
          myData =
            monthData?.monthData?.[type.toLowerCase()][planId]
              ?.subscriptionProduct?.subscriptionPlanFeature
              ?.mark_email_as_important_activity;
        }
        return {
          ...acc,
          [type.toLowerCase()]: renderFeatureData(myData),
        };
      }, {}),
    },
    {
      key: "10",
      name: "Multi-Reply Warmup Threads",
      ...plans.reduce((acc, { type }) => {
        let myData =
          monthData?.monthData?.[type.toLowerCase()][0]?.subscriptionProduct
            ?.subscriptionPlanFeature?.multi_reply_threads;
        if (type.toLocaleLowerCase() == "enterprise") {
          myData =
            monthData?.monthData?.[type.toLowerCase()][planId]
              ?.subscriptionProduct?.subscriptionPlanFeature
              ?.multi_reply_threads;
        }
        return {
          ...acc,
          [type.toLowerCase()]: renderFeatureData(myData),
        };
      }, {}),
    },
    {
      key: "11",
      name: "Email Deliverability Consultation",
      ...plans.reduce((acc, { type }) => {
        let myData =
          monthData?.monthData?.[type.toLowerCase()][0]?.subscriptionProduct
            ?.subscriptionPlanFeature?.email_deliverability_consultation;
        if (type.toLocaleLowerCase() == "enterprise") {
          myData =
            monthData?.monthData?.[type.toLowerCase()][planId]
              ?.subscriptionProduct?.subscriptionPlanFeature
              ?.email_deliverability_consultation;
        }
        return {
          ...acc,
          [type.toLowerCase()]: renderFeatureData(myData),
        };
      }, {}),
    },
    {
      key: "12",
      name: "Domain Reputation Audit By Experts",
      ...plans.reduce((acc, { type }) => {
        let myData =
          monthData?.monthData?.[type.toLowerCase()][0]?.subscriptionProduct
            ?.subscriptionPlanFeature?.domain_reputation_audit_by_experts;
        if (type.toLocaleLowerCase() == "enterprise") {
          myData =
            monthData?.monthData?.[type.toLowerCase()][planId]
              ?.subscriptionProduct?.subscriptionPlanFeature
              ?.domain_reputation_audit_by_experts;
        }
        return {
          ...acc,
          [type.toLowerCase()]: renderFeatureData(myData),
        };
      }, {}),
    },
    {
      key: "13",
      name: "Priority Support",
      ...plans.reduce((acc, { type }) => {
        let myData =
          monthData?.monthData?.[type.toLowerCase()][0]?.subscriptionProduct
            ?.subscriptionPlanFeature?.priority_support;
        if (type.toLocaleLowerCase() == "enterprise") {
          myData =
            monthData?.monthData?.[type.toLowerCase()][planId]
              ?.subscriptionProduct?.subscriptionPlanFeature?.priority_support;
        }
        return {
          ...acc,
          [type.toLowerCase()]: renderFeatureData(myData),
        };
      }, {}),
    },
  ];

  // DEFINE MAIN FUNCTION RETURN
  return (
    <div>
      {" "}
      <Table
        columns={columns}
        dataSource={data}
        showHeader={false}
        size="large"
        pagination={false}
        rowHoverable={false}
        className={styles.pricePlanTable}
      />
      <div className={styles.footer}>
        <p>
          🤔 Have questions? We have your questions answered.{" "}
          <span>View FAQs</span>
        </p>
      </div>
    </div>
  );
};

// DEFINE MAIN FUNCTION EXPORT

export default MonthlySubscription;
