import React from "react";
import styles from "./RoundedBtn.module.css";

// DEFINE MAIN FUNCTION
const RoundedButtion = ({ children, className = "" }) => {
  // DEFINE MAIN FUNCTION RETURN
  console.log("className", className);
  return (
    <button
      className={`${className == "green" ? styles.btn : className == 'warning' ? styles.warningBtn : styles.redbtn
        } ${className}`}
    >
      {children}
    </button>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default RoundedButtion;
