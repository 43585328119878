import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Spin, message } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { logOut, login, userLogin } from "../../redux/authentication/action";
import { useDispatch } from "react-redux";
import logo from "../../assets/logo.svg";

import style from "./AuthFiles.module.css";
// DEFINE MAIN FUNCTION
const UserLogin = () => {
  // DEFINE ALL STATES
  let secretKey = "user@123";
  const { token } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formValidateStatus, setFormValidateStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  // DEFINE FUNCTION FOR FORM SUBMIT
  const decryptObject = (encryptedString) => {
    try {
      const decryptedString = atob(encryptedString);
      let decryptedObject = "";

      for (let i = 0; i < decryptedString.length; i++) {
        decryptedObject += String.fromCharCode(
          decryptedString.charCodeAt(i) ^
            secretKey.charCodeAt(i % secretKey.length)
        );
      }

      return JSON.parse(decryptedObject);
    } catch (error) {
      console.error("Error decoding the string:", error);
      return null; // Handle the error appropriately in your application
    }
  };
  const onFinish = async (values) => {
    setLoading(true);
    // await dispatch(logOut(true));
    let user = await decryptObject(token);
    if (user && user.userId) {
      let response = await dispatch(userLogin(user));
      if (response.status) {
        if (response.data !== "") {
          navigate(response?.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }
  };

  const didMount = useRef(true);
  useEffect(() => {
    if (token && didMount.current) {
      onFinish();
    } else {
      didMount.current = false;
    }
  }, []);

  return (
    <>
      <div className={style.spinContainer}>
        <Spin className={style.nodatacomponent} />
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default UserLogin;
