import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Col, Form, Input, Row, Select, Spin, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Editor } from "ckeditor5-custom-build/build/ckeditor";
import closeIcon from "../../../assets/Templates/close-circle.png";
import GoogleSVGIcon from "../../../assets/dashboard/GoogleSVGIcon";
import SMTPSVGIcon from "../../../assets/dashboard/SMTPSVGIcon";
import MicrisoftSVGIcon from "../../../assets/dashboard/MicrisoftSVGIcon";
import PictureIcon from "../../../assets/dashboard/Content.svg";
import CodeIcon from "../../../assets/dashboard/Arrows.svg";
import SourseCodeModal from "../../dashboardModal/SourseCodeModal/SourseCodeModal";
import PictureEmailModal from "../../dashboardModal/PictureEmailModal/PictureEmailModal";
import {
  connectedEmailList,
  personalizationsList,
} from "../../../redux/dashboard/action";
import {
  createTemplate,
  editTemplate,
  getTemplateDetails,
} from "../../../redux/templates/action";
import { getItem, removeItem } from "../../../utility/localStorageControl";
import style from "../../../containers/admin/deliverability/DeliverabilityEmailContent.module.css";
import "./AddTemplate.css";

// DEFINE MAIN FUNCTION
const AddTemplates = () => {
  let listOfPersonalizations = useSelector(
    (state) => state.dashboard.personalizationList
  );
  let listOfEmail = useSelector(
    (state) => state.dashboard.emailListForTemplate
  );

  // DEFINE ALL STATES
  const [editor, setEditor] = useState();
  const [pictureEmailModal, setPictureEMailModal] = useState(false);
  const [sourseCodeModal, setSourseCodeModal] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const saveButtonRef = useRef();
  const [form] = Form.useForm();
  const [showDropDown, setShowDropDown] = useState(false);
  const [addedEmails, setAddedEmails] = useState([]);
  const [formValidations, setFormValidations] = useState({
    email: false,
    subject: false,
    body: false,
  });
  const [emailListLoading, setEmailListLoading] = useState(true);
  const [createTemplateLoading, setCreateTemplateLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState("");
  const selectContainerRef = useRef(null);

  // DEFINE USEEFFECT
  useEffect(() => {
    if (addedEmails.length > 0) {
      setFormValidations({ ...formValidations, email: true });
    } else {
      setFormValidations({ ...formValidations, email: false });
    }
  }, [addedEmails]);

  // DEFINE USEEFFECT
  useEffect(() => {
    if (!Object.values(formValidations).includes(false)) {
      if (saveButtonRef.current) {
        saveButtonRef.current.style.opacity = "100%";
      }
    } else {
      if (saveButtonRef.current) {
        saveButtonRef.current.style.opacity = "35%";
      }
    }
  }, [formValidations]);

  // DEFINE USEEFFECT
  useEffect(() => {
    emaillList();
    personalizationList();
  }, []);

  // DEFINE USEEFFECT
  useEffect(() => {
    if (editor && listOfPersonalizations) {
      insertInputField(editor, listOfPersonalizations);
    }
    return () => {
      if (selectContainerRef.current) {
        selectContainerRef.current.remove();
      }
    };
  }, [editor, listOfPersonalizations]);

  // DEFINE FUNCTION FOR TEMPLATE DETAILS API CALL
  const templateDetails = async (value, emailList) => {
    const response = await dispatch(getTemplateDetails(value));
    if (response.status) {
      setFormValidations({
        email: true,
        subject: true,
        body: true,
      });
      setData(response.data.body);
      form.setFieldsValue({ subject: response.data.subject });
      let filteredArray = emailList.filter(({ value: value }) =>
        response.data.sender_email_account_id.some(({ id: id }) => id == value)
      );
      let updatedArray = filteredArray.map((value) => ({
        id: value.value,
        email: value.label,
      }));
      setAddedEmails(updatedArray);
      setSelectedEmails(updatedArray);
    } else {
      navigate("/templates");
    }
  };

  // DEFINE FUNCTION FOR CONNECTED EMAIL LIST
  const emaillList = async () => {
    setEmailListLoading(true);
    let queryStrings = {
      page: "",
      limit: "",
      search: "",
      userId: getItem("userDetails")?.id,
      warmup_status: "",
      warmup_mode: "",
      sent_today: "",
      receive_today: "",
      deliverability: "",
      pagination: false,
    };
    const response = await dispatch(connectedEmailList(queryStrings));
    if (response.status) {
      if (location.pathname !== "/templates/create-new") {
        templateDetails(location.pathname.split("/").pop(), response.data);
      }
      setEmailListLoading(false);
    } else {
      setEmailListLoading(false);
    }
  };

  // DEFINE FUNCTION FOR CONNECTED EMAIL LIST
  const personalizationList = async () => {
    await dispatch(personalizationsList());
  };

  // DEFINE FUNCTION FOR HTML CODE HANDLE
  const handleHtmlCode = (codeFile) => {
    setData(codeFile);
  };

  // DFFINE FUNCTION FOR CKEDITOR CONFIGRATION
  const insertInputField = (editor, data) => {
    console.log(selectContainerRef.current);

    const toolbarElement = editor.ui.view.toolbar.element;
    const selectContainer = document.createElement("div");
    selectContainer.classList.add("ck", "ck-toolbar__items");
    if (selectContainerRef.current) {
      selectContainerRef.current.remove();
    }
    const select = (
      <>
        <img
          src={PictureIcon}
          className={style.pictureIcon}
          onClick={() => {
            setPictureEMailModal(true);
            setSelectedValue();
          }}
          alt="imageUpload"
        />
        <img
          src={CodeIcon}
          className={style.pictureIcon}
          onClick={() => {
            setSourseCodeModal(true);
          }}
          alt="codeIcon"
        />
        <div className={style.templatePlugin}>
          <Select
            size="large"
            placeholder="Personalization"
            className={style.selectEmail2}
            style={{ width: "auto" }}
            popupClassName="selectPopup"
            value={null}
            listHeight={200}
            onChange={(value) => {
              console.log("value", value);
              setData((prevData) => {
                console.log("prev data", prevData);
                if (prevData == undefined) {
                  return value;
                } else {
                  const tempDiv = document.createElement("div");
                  tempDiv.innerHTML = prevData;
                  let lastTextNode = findLastTextNode(tempDiv);
                  if (lastTextNode == false) {
                    return (tempDiv.innerHTML += ` ${value}`);
                  } else {
                    lastTextNode.textContent += ` ${value}`;
                    return tempDiv.innerHTML;
                  }
                }
              });
            }}
          >
            {data?.map((item, index) => {
              return (
                <Select.Option
                  className="selectPopupOption"
                  value={item?.personalization}
                  key={index}
                >
                  <Tooltip placement="top" title={item?.personalization}>
                    <span className={style.selectTemplatename}>
                      {item?.personalization}
                    </span>
                  </Tooltip>
                </Select.Option>
              );
            })}
          </Select>
        </div>
        <button
          className={"template_submit_button"}
          ref={saveButtonRef}
          onClick={() => {
            form.submit();
          }}
          style={{ opacity: "35%" }}
        >
          {createTemplateLoading ? (
            <Spin indicator={<LoadingOutlined spin />} />
          ) : (
            "Save"
          )}
        </button>
      </>
    );

    ReactDOM.render(select, selectContainer);
    toolbarElement.appendChild(selectContainer);
    selectContainerRef.current = selectContainer;
  };

  // Function to recursively find the last text node within a DOM element
  const findLastTextNode = (element) => {
    const childNodes = element.childNodes;
    const lastChild = childNodes[childNodes.length - 1];
    if (lastChild) {
      if (lastChild.nodeType === Node.TEXT_NODE) {
        return lastChild; // Found the last text node
      } else {
        return findLastTextNode(lastChild); // Recursively search deeper for the last text node
      }
    } else {
      return false;
    }
  };

  // DEFINE FUNCTION FOR IMAGE URL
  const handleImageSelection = (urlFile) => {
    let imageUrl =
      `<img style="width:300px"  src= ` + URL.createObjectURL(urlFile) + ` />`;
    setData((prevData) => {
      if (prevData == undefined) {
        return imageUrl;
      } else {
        return prevData + imageUrl;
      }
    });
  };

  // DEFINE FUNCTION FOR CHECK ARRAY OBJECTS IN SAME VALUES IS EXITS OR NOT
  function checkIdExistence(array1, array2) {
    const idsToCheck = array1.map((item) => item.id);
    const valuesInArray2 = array2.map((item) => item.value);
    if (
      (JSON.stringify(idsToCheck) === JSON.stringify(valuesInArray2)) ==
      false
    ) {
      return (
        JSON.stringify(idsToCheck.reverse()) === JSON.stringify(valuesInArray2)
      );
    } else {
      return JSON.stringify(idsToCheck) === JSON.stringify(valuesInArray2);
    }
  }

  // DEFINE FUNCTION FOR FORM SUBMIT
  const onFormSubmit = async (value) => {
    if (!Object.values(formValidations).includes(false)) {
      setCreateTemplateLoading(true);
      const startIndex = data.indexOf("<body>");
      const endIndex = data.indexOf("</body>");
      let bodyString = data;
      if (startIndex !== -1 && endIndex !== -1) {
        const bodyContent = data.substring(startIndex + 6, endIndex);
        bodyString = bodyContent;
      } else {
        bodyString = data;
      }
      let paylod = {
        body: bodyString,
        subject: value.subject,
        title: getItem("templateTitle")
          ? getItem("templateTitle")
          : "Untitled Template 1",
        sender_email_account_id: addedEmails.map((value) => ({
          id: value.id,
        })),
      };
      if (location.pathname == "/templates/create-new") {
        let response = await dispatch(createTemplate(paylod));
        if (response) {
          navigate("/templates");
          removeItem("templateTitle");
          setCreateTemplateLoading(false);
        } else {
          removeItem("templateTitle");
          setCreateTemplateLoading(false);
        }
      } else {
        let response = await dispatch(
          editTemplate(paylod, location.pathname.split("/").pop())
        );
        if (response) {
          navigate("/templates");
          removeItem("templateTitle");
          setCreateTemplateLoading(false);
        } else {
          removeItem("templateTitle");
          setCreateTemplateLoading(false);
        }
      }
    }
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <div className="templateContainer">
        <Form
          onFinish={(value) => {
            if (!createTemplateLoading) {
              onFormSubmit(value);
            }
          }}
          form={form}
          layout="vertical"
        >
          <Row gutter={[20, 20]}>
            <Col xxl={5} xl={6} lg={6} md={8} sm={24} xs={24}>
              <div className="leftContainer">
                <Form.Item className="formItem">
                  <Select
                    placeholder="Select your mailbox"
                    className="selectMail"
                    popupClassName="selectPopup"
                    value={null}
                    showSearch={false}
                    open={showDropDown}
                    onDropdownVisibleChange={(value) => {
                      setSelectedEmails(addedEmails);
                      setShowDropDown(value);
                    }}
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <div className="selctBarFooter">
                          <button
                            onClick={() => {
                              setSelectedEmails(addedEmails);
                              setShowDropDown(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            style={{
                              opacity:
                                selectedEmails.length > 0 ? "100%" : "35%",
                            }}
                            onClick={() => {
                              if (selectedEmails.length > 0) {
                                setAddedEmails(selectedEmails);
                                setShowDropDown(false);
                              }
                            }}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    )}
                    mode="multiple"
                    loading={emailListLoading}
                  >
                    {!emailListLoading && listOfEmail.length > 0 ? (
                      <Select.Option className="selectPopupOption" value="all">
                        <Checkbox
                          checked={checkIdExistence(
                            selectedEmails,
                            listOfEmail
                          )}
                          onChange={(event) => {
                            if (event.target.checked) {
                              setSelectedEmails(
                                listOfEmail.map((value) => ({
                                  id: value.value,
                                  email: value.label,
                                }))
                              );
                            } else {
                              setSelectedEmails([]);
                            }
                          }}
                        >
                          Select All
                        </Checkbox>
                      </Select.Option>
                    ) : (
                      ""
                    )}
                    {listOfEmail.length > 0
                      ? listOfEmail.map((item, index) => {
                          return (
                            <Select.Option
                              className="selectPopupOption"
                              value={item?.value}
                              key={index}
                            >
                              <Checkbox
                                checked={
                                  selectedEmails.find(
                                    (value) => value.id == item?.value
                                  )
                                    ? true
                                    : false
                                }
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    if (
                                      !selectedEmails.find(
                                        (value) => value.id == item?.value
                                      )
                                    ) {
                                      setSelectedEmails((prevItems) => [
                                        ...prevItems,
                                        { id: item?.value, email: item?.label },
                                      ]);
                                    }
                                  } else {
                                    if (
                                      selectedEmails.find(
                                        (value) => value.id == item?.value
                                      )
                                    ) {
                                      setSelectedEmails((prevItems) =>
                                        prevItems.filter(
                                          (value) => value.id !== item?.value
                                        )
                                      );
                                    }
                                  }
                                }}
                              >
                                <div>
                                  <span className="selectMailname">
                                    {item?.name}
                                  </span>
                                  <div>
                                    <span className="selectMailtext">
                                      {item?.label}
                                      {item?.emailType == "google" ? (
                                        <GoogleSVGIcon />
                                      ) : item?.emailType == "smtp_imap" ? (
                                        <SMTPSVGIcon />
                                      ) : (
                                        <MicrisoftSVGIcon />
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </Checkbox>
                            </Select.Option>
                          );
                        })
                      : ""}
                  </Select>
                </Form.Item>
                <div className="selectList">
                  {addedEmails?.map((value, index) => {
                    return (
                      <>
                        <div key={index} index={index}>
                          <span>{value?.email}</span>
                          <img
                            onClick={() => {
                              if (
                                addedEmails.find((item) => item.id == value?.id)
                              ) {
                                setAddedEmails((prevItems) =>
                                  prevItems.filter(
                                    (item) => item.id !== value?.id
                                  )
                                );
                                setSelectedEmails((prevItems) =>
                                  prevItems.filter(
                                    (item) => item.id !== value?.id
                                  )
                                );
                              }
                            }}
                            src={closeIcon}
                            alt="close-icon"
                            width={18}
                            height={18}
                          />
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </Col>
            <Col xxl={19} xl={18} lg={18} md={16} sm={24} xs={24}>
              <div className="rightContainer">
                <Form.Item name={"subject"} className="subjectInput">
                  <Input
                    placeholder="Enter Subject"
                    className="custom-placeholder"
                    onChange={(event) => {
                      setFormValidations({
                        ...formValidations,
                        subject: event.target.value == "" ? false : true,
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item name={"description"} className="descriptionInput">
                  <CKEditor
                    // onFocus={(foo) => console.log(foo)}
                    editor={Editor}
                    data={data}
                    onReady={(editor) => {
                      setEditor(editor);
                    }}
                    onChange={(event, editor) => {
                      let filterData = editor.getData();
                      let replaceData = filterData
                        .replace(/&lt;/g, "<")
                        .replace(/&gt;/g, ">");
                      setData(replaceData);
                      setFormValidations({
                        ...formValidations,
                        body:
                          replaceData == "" || replaceData == "<p>&nbsp;</p>"
                            ? false
                            : true,
                      });
                    }}
                    config={{
                      placeholder: "Your message",
                      removePlugins: ["Title", "MediaEmbedToolbar"],
                      toolbar: {
                        items: [
                          "fontFamily",
                          "FontSize",
                          "bold",
                          "italic",
                          "Underline",
                          "alignment:left",
                          "alignment:right",
                          "alignment:center",
                          "alignment:justify",
                          "bulletedList",
                          "numberedList",
                          "link",
                        ],
                      },
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      {pictureEmailModal && (
        <PictureEmailModal
          pictureEmailModal={pictureEmailModal}
          selectedValue={selectedValue}
          onClose={() => {
            setPictureEMailModal(false);
            setSelectedValue({});
          }}
          onSubmit={handleImageSelection}
        />
      )}
      {sourseCodeModal && (
        <SourseCodeModal
          sourseCodeModal={sourseCodeModal}
          selectedValue={data}
          onClose={() => {
            setSourseCodeModal(false);
            setSelectedValue({});
          }}
          onSubmit={handleHtmlCode}
        />
      )}
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default AddTemplates;
