// DEFINE ALL EXTERNAL IMPORT
import React, { useEffect, useState } from "react";
import { Col, notification, Row } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IoIosLink } from "react-icons/io";
import { IoMdCheckmark } from "react-icons/io";

// DEFINE ALL INTERNAL IMPORT
import EmailSetUpScore from "./deliverability/EmailSetUpScore";
import InboxPlacementScore from "./deliverability/InboxPlacementScore";
import styles from "../admin/deliverability/deliverability.module.css";
import DeliverabilityDetails from "./deliverability/DeliverabilityDetails";
import SubscriptionButtion from "../../component/subscription/SubscriptionButtion";
import greenCheck from "../../assets/deleverability/greenCheck.png";
import GoogleSVGIcon from "../../assets/dashboard/GoogleSVGIcon";
import SMTPSVGIcon from "../../assets/dashboard/SMTPSVGIcon";
import MicrisoftSVGIcon from "../../assets/dashboard/MicrisoftSVGIcon";
import { getItem, setItem } from "../../utility/localStorageControl";
import rightIcon from "../../assets/Email/notification-right.png";
import { EmailActiveWarmupOn } from "../../state/EmailConnect/Emailconnect.api";
import { capitalizeFirstWord } from "../../config/CommonFuntion";
import { deliverabilityTestDataById } from "../../redux/deliverabilityTest/action";

const Deliverability = () => {
  // DEFINE ALL STATES
  const { id: emailId } = useParams();
  const location = useLocation();
  const [deleverybityData, setDeleverybityData] = useState();
  const [loader, setLoader] = useState(false);
  const [copy, setCopy] = useState(false);
  const [id, setId] = useState(null);
  const [warmupTrue, setWarmupTrue] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const data = location.state;
    if (data) {
      setDeleverybityData(data);
      setId(data?.email_account_id);
      console.log("datadatadatadatadatadatadatadatadata", data);
    }
  }, []);
  const fetchDataDeliverabilityDataById = async () => {
    setLoader(true);
    try {
      if (emailId) {
        const result = await dispatch(deliverabilityTestDataById(emailId));
        if (result) {
          console.log("fetchDataDeliverabilityTest success");
          setDeleverybityData(result);
          setLoader(false);
        } else {
          console.log("fetchDataDeliverabilityTest failed");
          setLoader(false);
        }
      }
    } catch (error) {
      console.log("error", error);
      setLoader(false);
    }
  };
  useEffect(() => {
    fetchDataDeliverabilityDataById();
  }, [emailId]);
  // DEFINE FUNCTION FOR WARMUP ON
  const warmupOn = async () => {
    setLoader(true);
    try {
      if (id) {
        const result = await dispatch(EmailActiveWarmupOn(id, true));
        if (result) {
          console.log("warmupOn success");
          setLoader(false);
          // navigate("/email-warmup");
          setWarmupTrue(true);
          notification.success({
            message: (
              <span className="notificationText">
                Successfully warmup start for this email
              </span>
            ),
            description: <span className="startBorder"></span>,
            icon: (
              <img src={rightIcon} alt="right-icon" width={20} height={20} />
            ),
            className: "custom-notification",
            duration: 3,
            style: { width: "362px", height: "88px" },
          });
        } else {
          console.log("warmupOn failed");
        }
      }
    } catch (error) {
      console.log("error ", error);
      return false;
    }
    setLoader(false);
  };
  const handleCopy = () => {
    navigator.clipboard.writeText(
      `https://app.mailoptimal.com/deliverability-test-public/${deleverybityData?.id}`
    );
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 2000);
  };
  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <div className={styles.headerTitleSubject}>
        <p>{capitalizeFirstWord(deleverybityData?.subject)}</p>
      </div>
      <Row justify={"space-between"} className={styles.userInfo}>
        <Col>
          <div className={styles.username}>
            <h4>{deleverybityData?.emailAccount?.sender_username}</h4>
            <div>
              {deleverybityData?.emailAccount?.type == "google" ? (
                <GoogleSVGIcon className={styles.EmailProviderIcons} />
              ) : deleverybityData?.emailAccount?.type == "smtp_imap" ? (
                <SMTPSVGIcon className={styles.EmailProviderIcons} />
              ) : (
                <MicrisoftSVGIcon className={styles.EmailProviderIcons} />
              )}
            </div>
          </div>

          <div className={styles.userMain}>
            <a href="mailto:tienlapspktnd@gmail.com">
              {deleverybityData?.emailAccount?.sender_email}
            </a>
            <img src={greenCheck} alt="" />
          </div>
        </Col>

        <Col className={styles.rightBtnContainer}>
          {copy ? (
            <p>
              <span>
                <IoMdCheckmark size={20} />
              </span>
              <span>Public Link Copied</span>
            </p>
          ) : (
            <p onClick={handleCopy}>
              <span>
                <IoIosLink size={20} />
              </span>
              <span>Copy Public link</span>
            </p>
          )}

          {deleverybityData?.emailAccount?.warmup_status == "active" ? (
            ""
          ) : !warmupTrue ? (
            <SubscriptionButtion
              loading={loader}
              onClick={warmupOn}
              btnText={"Start Warm-Up"}
            />
          ) : (
            ""
          )}
        </Col>
      </Row>
      <Row gutter={{ xs: 12, sm: 24 }}>
        <EmailSetUpScore score={deleverybityData?.domainHealthScore} />
        <InboxPlacementScore score={deleverybityData?.inboxPlacementScore} />
      </Row>
      <Row className={styles.collepseMainContainer}>
        <DeliverabilityDetails deleverybityData={deleverybityData} />
      </Row>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default Deliverability;
