import { combineReducers } from "redux";
import AuthReducer from "./authentication/reducers";
import dashboardReducer from "./dashboard/reducers";
import creditPlanReducer from "./credit-plan/reducers";
import TemplateReducer from "./templates/reducers";
import subscriptionPlanReducer from "./subscription-plan/reducers";
import ConnectAccountReducer from "./connect-account/reducers";
import deliverabilityTestReducer from "./deliverabilityTest/reducer";

// DEFINE ROOT REDUCERS FUNCTIONS
const rootReducers = combineReducers({
  auth: AuthReducer,
  dashboard: dashboardReducer,
  creditPlan: creditPlanReducer,
  template: TemplateReducer,
  subscriptionPlan: subscriptionPlanReducer,
  ConnectAccountReducer: ConnectAccountReducer,
  deliverabilityTest: deliverabilityTestReducer,
});

// DEFINE MAIN FUNCTION RETURN
export default rootReducers;
