// ALL API END POINTS OBJECT SET-UP
const API = {
  // DEFINE AUTHENTICATION API ROUTE
  auth: {
    signUp: "auth/signup",
    updateQuestion: "auth/update/status/",
    updateFianlQuestion: "auth/update/status/final/",
    login: "auth/login",
    userLogin: "auth/user-login",
    forgotPassword: "auth/forgot-password",
    resetPassword: "auth/reset-password",
    resendVerifyAccount: "auth/resend-verification-link",
    verifyEmail: "auth/check-user-exits",
    verify: "auth/verify",
    updateProfile: "users/",
    userDetail: "users/",
  },

  dashboard: {
    emailList: "emailAccount/account/get-list",
    newEmailList: "emailAccount/account/list",
    emailById: "emailAccount/account/get-by-id",
    warmupUpdateSetting: "emailAccount/account/settings/update/",
    genarateCapcha: "emailAccount/connect/account/generate/warmuptag",
    emailAnalytics: "analytics/get/",
    deliverabilityAnalytics: "analytics/deliverability/",
    templateList: "templates/emailAccount/list",
  },

  test: {
    smtp: "testMailCredentials/test-smtp",
    imap: "testMailCredentials/test-imap",
    connect: "emailAccount/connect/account",
    editConnect: "emailAccount/account/update/",
    microsoftconnect: "emailAccount/microsoft/sign",
    warmupSetting: "emailAccount/connection/settings",
  },

  email: {
    delete: "emailAccount",
    disconnect: "emailAccount/account/disconnect",
    updateAgainShow: "auth/account/message/noshow",
    updateWarmup: "emailAccount/account/status/update",
  },

  subscription: {
    creditPlanList: "credit-plan",
    purchaseCredit: "user-credits",
    subscriptionPlanList: "subscription-plans",
    create: "subscriptions/create",
    invoice: "subscriptions/viewInvoice"
  },

  template: {
    create: "templates/create",
    update: "templates/update/",
    list: "templates/list",
    updateStatus: "templates/status/update",
    delete: "templates/delete",
    details: "templates/get",
  },

  personalization: {
    list: "personalization/list",
  },
  deliverabilityTest: {
    create: "DeliverabilityTest/create",
    list: "DeliverabilityTest/list",
    delete: "DeliverabilityTest/delete",
    getById: 'DeliverabilityTest/get'
  },
};

// EXPORT API ROUTES OBJECT DEFINE
export { API };
