import React from "react";
import PublicRoute from "../../routes/PublicRoute";
import { useLocation } from "react-router-dom";
import "./authStyle.css";
// DEFINE MAIN FUNCTION

const AuthLayout = ({ width }) => {
  // DEFINE ALL STATES
  let location = useLocation();
  let authPath =
    location.pathname == "/" ||
    location.pathname == "/signup" ||
    location.pathname == "/email-varification" ||
    location.pathname == "/reset-password" ||
    location.pathname == "/login" ||
    location.pathname == "/login/ltd-activation" ||
    location.pathname == "/signup/ltd-activation" ||
    location.pathname == "/verify-account";

  let authQuestionPath = location.pathname == "/onboarding";

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      {authPath ? (
        <div className="authContainer">
          <div
            style={{
              height:
                location.pathname == "/verify-account" ? "min-content" : "",
            }}
            className={`authComponent  ${location.pathname == "/email-varification"
                ? "email_varification_component"
                : ""
              }`}
          >
            <PublicRoute width={width} />
          </div>
        </div>
      ) : authQuestionPath ? (
        <div className="signup-question-container">
          <PublicRoute />
        </div>
      ) : (
        <PublicRoute />
      )}
    </>
  );
};
// DEFINE MAIN FUNCTION EXPORT
export default AuthLayout;
