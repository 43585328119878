import { API } from "../../config/api/Index";

const initState = {
  deliverabilityTestList: [],
};

const deliverabilityTestReducer = (state = initState, action) => {
  switch (action.type) {
    case API.deliverabilityTest.list:
      return {
        ...state,
        deliverabilityTestList: action.deliverabilityTestList,
      };
    default:
      return state;
  }
};

export default deliverabilityTestReducer;
