// DEFINE ALL EXTERNAL IMPORT
import React from "react";
import { Col, Collapse, Row, Skeleton } from "antd";
import { FaCheck, FaCheckCircle } from "react-icons/fa";
import { PiWarningCircleLight } from "react-icons/pi";
import { LuFileSymlink } from "react-icons/lu";



// DEFINE ALL INTERANL IMPORT
import styles from "./deliverability.module.css";
import RoundedButtion from "../../../component/deliverabilityTest/RoundedButtion";
import { IoWarningSharp } from "react-icons/io5";
import { capitalizeFirstWord } from "../../../config/CommonFuntion";
import { MdCheck } from "react-icons/md";

// DEFINE TABLE COMPONENTs
const LableComponnets = ({ title, deliverabilityData }) => {
  return (
    <>
      <div className={styles.DomainSetupDetailsLabel}>
        <h3>{title}</h3>
        {title == 'Spam words' ? deliverabilityData ? (
          <RoundedButtion
            className={
              deliverabilityData?.emailContentResult?.status == 'true' ? "warning" : "green"
            }
          >
            {deliverabilityData?.emailContentResult?.status == 'true' ? (

              <PiWarningCircleLight className={styles.checkIconsBtn} />
            ) : (
              <MdCheck fontSize={"medium"} />
            )}

            {deliverabilityData?.emailContentResult?.status == 'true'
              ? `Spam words found :  ${deliverabilityData?.emailContentResult?.data?.countSpamWord}`
              : 'Spam-free'}

          </RoundedButtion>
        ) : (
          <Skeleton.Button
            active={true}
            size="default"
            shape="round"
            style={{ width: "100px" }}
          />
        ) : ''}
      </div>
    </>
  );
};

// DEFINE FUNCTION FOR CHILDREN COMPONENT
const ChildrenComponnets = ({ deliverabilityData, title }) => {
  console.log("deliverabilityData", deliverabilityData)
  return (
    <>
      <div>
        {title == 'Spam words' ? (deliverabilityData?.emailContentResult?.status == 'true' ? <div className={styles.domainSetupChildrenContainerWarning}>
          <PiWarningCircleLight className={styles.checkIcons} />
          <div className={styles.textContainer}>
            <h5>Rewrite your email to avoid spam words</h5>
            <p>
              Spam words can affect your deliverability and sender reputation, causing your emails to be marked as spam.
              <span> Learn More</span>
            </p>
          </div>
        </div> : <div className={styles.domainSetupChildrenContainer}>
          <FaCheckCircle className={styles.checkIcons} />
          <div className={styles.textContainer}>
            <h5>Your email is spam-free!</h5>
            <p>
              Spam words can affect your deliverability and sender reputation, causing your emails to be marked as spam.
              <span> Learn More</span>
            </p>
          </div>
        </div>) : ''}

        {/* <p className={styles.detailsText}>Your domain's SPF record:</p> */}
        <div className={styles.detailsDescriptionContainer} style={{ marginTop: "16px" }}>
          {title == 'Spam words' || title == 'Text' && <div className={styles.titleEmailContent}>
            <h3 >Subject :</h3>
            <span>{deliverabilityData?.subject ? capitalizeFirstWord(deliverabilityData?.subject) : '-'}</span>
          </div>}

          {title === "Spam words" ? deliverabilityData?.emailContentResult?.data?.spamWordBody ?
            (
              <div dangerouslySetInnerHTML={{ __html: deliverabilityData?.emailContentResult?.data?.spamWordBody }}></div>
            )
            : '-' :
            title === "Source" ?
              Array.isArray(deliverabilityData?.emailContentResult?.data?.source) ?
                deliverabilityData?.emailContentResult?.data?.source?.map((data, i) => (
                  <div key={i}>
                    <p className={styles.paragraph_of_line}>{data?.line}</p>
                  </div>
                )) : '-'
              : title == 'Text' ? <div dangerouslySetInnerHTML={{
                __html: deliverabilityData?.emailContentResult?.data?.onlyTextFiled
              }}></div> : title == 'HTML' ? <div dangerouslySetInnerHTML={{
                __html: deliverabilityData?.body
              }}></div> : title == 'Files and links' ? <>
                {deliverabilityData?.emailContentResult?.data?.files_links == '' ? <>
                  <div className={styles.noFileAndLinks}>
                    <LuFileSymlink size={80} />
                    <h5>No content to review
                    </h5>
                    <p>It looks like your email doesn’t have any files or links.</p>
                  </div>
                </> : <div dangerouslySetInnerHTML={{
                  __html: deliverabilityData?.emailContentResult?.data?.files_links
                }}></div>}

              </> : ''}

        </div>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION
const EmailContentDetails = ({ deleverybityData }) => {
  console.log("deliverabilityData", deleverybityData?.emailContentResult)
  // DEFINE ALL STATES HERE

  // DEFINE NESTED COLLAPSE  ITEMS OF EMAIL CONTENT
  const emailItemsNest = [
    {
      key: "1",
      label: <LableComponnets title="Spam words" deliverabilityData={deleverybityData} />,
      children: <ChildrenComponnets title="Spam words" deliverabilityData={deleverybityData} />,
    },
    {
      key: "2",
      label: <LableComponnets title="Files and links" />,
      children: <ChildrenComponnets title="Files and links" deliverabilityData={deleverybityData} />,
    },
    {
      key: "3",
      label: <LableComponnets title="Text" />,
      children: <ChildrenComponnets title="Text" deliverabilityData={deleverybityData} />,
    },
    {
      key: "4",
      label: <LableComponnets title="HTML" />,
      children: <ChildrenComponnets title="HTML" deliverabilityData={deleverybityData} />,
    },
    {
      key: "5",
      label: <LableComponnets title="Source" />,
      children: <ChildrenComponnets title="Source" deliverabilityData={deleverybityData} />,
    },
    // {
    //   key: "6",
    //   label: <LableComponnets />,
    //   children: <ChildrenComponnets />,
    // },
  ];
  console.log("deleverybityData?.emailContentResult?.messageSize", deleverybityData?.emailContentResult.data.messageSize)

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <Row gutter={12}>
        <Col span={24}>
          <div className={styles.emailContactsContainer}>
            <p>Message size:</p>
            <h2>{deleverybityData?.emailContentResult?.data?.messageSize ? deleverybityData?.emailContentResult?.data?.messageSize : ''} (Plain text:{deleverybityData?.emailContentResult?.data?.plainTextSize ? deleverybityData?.emailContentResult?.data?.plainTextSize : ''}, HTML: {deleverybityData?.emailContentResult?.data?.htmlSize ? deleverybityData?.emailContentResult?.data?.htmlSize : ''})</h2>
          </div>
        </Col>
        {/* <Col span={12}>
          <div className={styles.emailContactsContainer}>
            <p>Files size:</p>
            <h2>0 B (Files: 0)</h2>
          </div>
        </Col> */}
      </Row>
      <Collapse
        bordered={false}
        defaultActiveKey="1"
        items={emailItemsNest}
        className="nestedDomainSetUpContainer"
        expandIconPosition="right"
      />
    </>
  );
};

// MAIN FUNCTION EXPORTS
export default EmailContentDetails;
