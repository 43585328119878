import React, { useEffect, useState } from "react";
import logo from "../../assets/logo.svg";
import { useNavigate } from "react-router-dom";
import { verifyUserAccount } from "../../redux/authentication/action";
import { useDispatch } from "react-redux";
import { Spin } from "antd";
import style from "./AuthFiles.module.css";

// DEFINE MAIN FUNCTION
const VerifyAccount = () => {
  // DEFINE ALL STATES
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [resendOTPLoading, setResendOTPLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);

  // DEFINE USEEFFECT FOR CHECK URL IN TOKEN OR NOT
  useEffect(() => {
    if (!urlParams.get("token")) {
      navigate("/");
    }
  }, []);

  // DEFINE FUNCTION FOR FORM SUBMIT
  const onFinish = async () => {
    setResendOTPLoading(true);
    let response = await dispatch(
      verifyUserAccount({ status: true, token: urlParams.get("token") })
    );
    if (response.status) {
      if (response.data !== "") {
        navigate(response?.data);
        setResendOTPLoading(false);
      } else {
        setResendOTPLoading(false);
      }
    } else {
      setResendOTPLoading(false);
    }
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <div className={style.verifyComponent}>
        <div className={style.detailsComponent}>
          <img src={logo} alt="logo" />
          <h2 className={style.title}>Account Verification</h2>
          <p className={style.description}>
            Thank you for signing up!
            <br /> Please account verify by clicking the button below.
          </p>
        </div>
        <button
          type="submit"
          typeof="submit"
          style={{
            opacity: resendOTPLoading ? "35%" : "100%",
          }}
          onClick={() => {
            if (!resendOTPLoading) {
              onFinish();
            }
          }}
        >
          {resendOTPLoading ? <Spin /> : "Verify"}
        </button>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default VerifyAccount;
