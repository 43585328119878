// DEFINE EXTERNAL IMPORT
import React from "react";
import { FaCheck, FaClock, FaClosedCaptioning, FaCross } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { MdCheck } from "react-icons/md";

// DEFINE  INTERNAL IMPORT
import { domainList } from "../../../data";
import smsEditIcons from "../../../assets/deleverability/sms-edit.png";
import styles from "./deliverability.module.css";
import RoundedButtion from "../../../component/deliverabilityTest/RoundedButtion";
import { Skeleton } from "antd";

// DEFINE MAIN FUNCTION
const DomainSetUpLableComponnets = ({ deleverybityData }) => {
  const checkStatus = (item) => {
    if (!deleverybityData) return;
    const resultMap = {
      MX: 'mxResult',
      rDNS: 'rdnsResult',
      DKIM: 'dkimResult',
      SPF: 'spfResult',
      DMARC: 'dmarcResult'
    };

    const result = deleverybityData[resultMap[item]];
    if (!result) return;

    const status = result.status;

    if (status === 'true' || (item === 'SPF' && status === true)) return 'true';
    if (status === 'missing') return 'missing';
    if (status === 'false' || status === 'error') return 'false';
  };
  return (
    <>
      <div className={styles.collpseItemContainer}>
        <div className={styles.collpseItemDetails}>
          <img src={smsEditIcons} alt="sms edit icons" />
        </div>
        <div className={styles.itemDetailsContaienr}>
          <h3>Domain Setup</h3>
          <div className={styles.domainBtnContainer}>
            {deleverybityData ? (
              domainList &&
              domainList.map((item) => {
                return (
                  <RoundedButtion
                    key={item?.id}
                    className={
                      checkStatus(item.name) == "true" ? "green" : "red"
                    }
                  >
                    {checkStatus(item.name) == "true" ? (
                      <MdCheck fontSize={"medium"} />
                    ) : (
                      <RxCross2 />
                    )}
                    {item?.name}
                  </RoundedButtion>
                );
              })
            ) : (
              <Skeleton.Button active={true} size="default" shape="round" />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default DomainSetUpLableComponnets;
