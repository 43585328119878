import { API } from "../../config/api/Index";
import { encodeQueryParams } from "../../config/CommonFuntion";
import { DataService } from "../../config/DataService";
import { message } from "antd";

// CONNECTED EMAIL API CALL
export const connectedEmailList = (queryStrings) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        API.dashboard.emailList + encodeQueryParams(queryStrings)
      );
      if (response.data.status) {
        dispatch({
          type: API.dashboard.emailList,
          emailList: response.data.data,
          emailListForTemplate: response.data.data.map((value) => ({
            value: value.id,
            label: value.sender_email,
            name: value.sender_username,
            emailType: value.type,
          })),
          emailListCounts: {
            currentPage: response.data.currentPage,
            totalCount: response.data.totalCount,
            totalPages: response.data.totalPages,
          },
        });
        return {
          status: true,
          data: response.data.data.map((value) => ({
            value: value.id,
            label: value.sender_email,
            name: value.sender_username,
            emailType: value.type,
          })),
        };
      } else {
        return { status: false };
      }
    } catch (err) {
      return { status: false };
    }
  };
};


// PERSONALIZATIONS LIST API CALL
export const personalizationsList = (queryStrings) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        API.personalization.list + encodeQueryParams(queryStrings)
      );
      if (response.data.status) {
        dispatch({
          type: API.personalization.list,
          personalizationList: response.data.data,
        });
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
export const getEmailDataById = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.dashboard.emailById}/${id}`
      );
      return response.data.data;
    } catch (err) {
      return false;
    }
  };
};
export const getAllEmailList = (payload) => {
  return async (dispatch) => {
    try {
      const query = `?userId=${payload?.userId}`
      const response = await DataService.get(
        `${API.dashboard.newEmailList}${query}`
      );
      dispatch({
        type: API.dashboard.newEmailList,
        newEmailList: response.data.data,
      })
      console.log("res", response)
      return response.data;
    } catch (err) {
      return false;
    }
  };
};
// EMAIL ANALYTICS DATA API CALL
export const getEmailAnalyticsData = (id, type) => {
  console.log("type: ", type);
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        API.dashboard.emailAnalytics + id + "?type=" + type
      );
      console.log("response of use reducer", response);
      // if (response.status == 200) {
      dispatch({
        type: API.dashboard.emailAnalytics,
        emailAnalyticsDetails: response.data.data,
        typeData: {
          [type]: Object.keys(response.data.data[type]).map((key) => {
            return {
              name: key,
              send_today: response.data.data[type][key].send_today,
              receive_today: response.data.data[type][key].receive_today,
            };
          }),
          total: response.data.data.total,
        },
      });
      return response.data.data;
      // } else {
      //   return false;
      // }
    } catch (err) {
      return false;
    }
  };
};
export const getDeliverabilityAnalyticsData = (id, type, esp_specific) => {
  console.log("type: ", type);
  console.log("esp_specific", esp_specific);
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        API.dashboard.deliverabilityAnalytics +
        id +
        "?type=" +
        type +
        "&esp_specific=" +
        esp_specific
      );
      console.log("response of use reducer", response);
      // if (response.status == 200) {
      dispatch({
        type: API.dashboard.deliverabilityAnalytics,
        deliverabilityAnalyticsDetails: response.data.data,
        typeData: {
          [type]: Object.keys(response.data.data[type]).map((key) => {
            return {
              name: key,
              send_today: response.data.data[type][key].send_today,
              landed_in_primary:
                response.data.data[type][key].landed_in_primary,
              landed_in_spam: response.data.data[type][key].landed_in_spam,
              deliverability: response.data.data[type][key].deliverability,
            };
          }),
          total: response.data.data.total,
        },
      });
      return response.data.data;
      // } else {
      //   return false;
      // }
    } catch (err) {
      return false;
    }
  };
};

// UPDATE EMAIL WARMP SETTING DATA API CALL
export const updateWarmUpSetting = (payloads, id) => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.put(
        API.dashboard.warmupUpdateSetting + id,
        payloads
      );
      if (loggedIn.data.status) {
        return true;
      } else {
        message.error(loggedIn.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// REGENARATE CAPCHA FOR EMAIL WARMP SETTING DATA API CALL
export const genarateCapcha = () => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.post(API.dashboard.genarateCapcha);
      if (loggedIn.data.status) {
        return { status: true, data: loggedIn.data.data.warmup_tag };
      } else {
        message.error(loggedIn.data.message);
        return { status: false };
      }
    } catch (err) {
      return { status: false };
    }
  };
};

// TEMPLATE LIST BASED ON ID DATA API CALL
export const getIdBasedTemplateList = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        API.dashboard.templateList + "?emailAccountId=" + id
      );
      if (response.data.status) {
        dispatch({
          type: API.dashboard.templateList,
          emailTemplateList: response.data.data,
        });
        return response.data.data;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  };
};
