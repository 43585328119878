import React, { useState } from "react";
import { Form, Input, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../redux/authentication/action";
import { useDispatch } from "react-redux";
import { setItem } from "../../utility/localStorageControl";
import logo from "../../assets/logo.svg";
import style from "./AuthFiles.module.css";

// DEFINE MAIN FUNCTION
const ForgotPassword = () => {
  // DEFINE ALL STATES
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formValidateStatus, setFormValidateStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  // DEFINE FUNCTION FOR SUBMIT
  const onFinish = async (values) => {
    setLoading(true);
    let response = await dispatch(forgotPassword(values));
    if (response) {
      setItem("varification-email", values.email);
      navigate("/email-varification");
      setLoading(false);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  };

  // DEFINE FUNCTION FOR FORM ONCHANGE VALUES VALIDATIONS CHECK
  const onCheckFormChangeValue = (changedValues, allValues) => {
    if (
      !Object.values(allValues).includes(undefined) &&
      !Object.values(allValues).includes("")
    ) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!allValues.email || emailRegex.test(allValues.email)) {
        setFormValidateStatus(true);
      } else {
        setFormValidateStatus(false);
      }
    } else {
      setFormValidateStatus(false);
    }
  };

  // DEFINE FUNCTION FOR CUSTOM VALIDATION EMAIL
  const validateEmail = (_, value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value || emailRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter valid email!");
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <div className={style.forgotPasswordComponent}>
        <div className={style.detailsComponent}>
          <img src={logo} />
          <h2 className={style.title}>Forgot Password?</h2>
          <p className={style.description}>
            You'll get an email to reset your password
          </p>
        </div>
        <Form
          layout="vertical"
          name="basic"
          requiredMark={false}
          size="large"
          onFinish={(value) => {
            if (!loading) {
              onFinish(value);
            }
          }}
          form={form}
          onValuesChange={(value, allValues) => {
            onCheckFormChangeValue(value, allValues);
          }}
          autoComplete="off"
        >
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
              { validator: validateEmail },
            ]}
          >
            <Input placeholder="Enter Email" />
          </Form.Item>
          <Form.Item className={style.submitButton}>
            <button
              type="submit"
              className="signUpButton loadingButton"
              style={{
                opacity: formValidateStatus ? "100%" : "35%",
              }}
            >
              {loading ? <Spin /> : "Send Recovery Mail"}
            </button>
          </Form.Item>
        </Form>
        <button
          onClick={() => {
            navigate("/login");
          }}
          type="button"
          className={style.backButton}
        >
          Back to Log In
        </button>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default ForgotPassword;
