import React from "react";
import style from "./Subscription-usage.module.css";
import { Card, Col, Row, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const SubscriptionUsage = () => {
  // DEFINE ALL STATES
  const navigate = useNavigate();
  let userDetails = useSelector((state) => state.auth.userDetails);

  console.log("userDetails userDetails", userDetails?.subscribed);

  let subscribeUser = userDetails?.subscribed;
  let percentage = 100;
  // DEFINE MAIN FUNCTION RETURN
  const usedPercentage =
    (userDetails.current_sending_mail_count * 100) /
    userDetails.maximum_email_send_count;
  const displayPercentage = usedPercentage > 100 ? 100 : usedPercentage;
  return (
    <>
      <Col span={12}>
        <Card bordered={false} className={style.mainCardContainer}>
          <div className={style.cardTitleUsage}>
            {subscribeUser ? "Subscription Usage :" : "Free Plan Usage"}
          </div>
          <div className={style.mainContainer}>
            <div className="progress-container"></div>
            <label>
              <span>
                <b>
                  {userDetails?.subscribed
                    ? userDetails?.subscriptions?.subscriptionPlan
                        ?.subscription_plan_validity == "month"
                      ? "Monthly"
                      : "Monthly"
                    : "Monthly"}{" "}
                  Warmup Emails
                </b>
              </span>
              <span>
                {userDetails.current_sending_mail_count >
                userDetails.maximum_email_send_count ? (
                  <b>
                    {userDetails.maximum_email_send_count}/
                    {userDetails.maximum_email_send_count} (
                    {Number(
                      (userDetails.maximum_email_send_count * 100) /
                        userDetails.maximum_email_send_count
                    ).toFixed(2)}
                    % Used)
                  </b>
                ) : (
                  <b>
                    {userDetails.current_sending_mail_count}/
                    {userDetails.maximum_email_send_count} (
                    {Number(
                      (userDetails.current_sending_mail_count * 100) /
                        userDetails.maximum_email_send_count
                    ).toFixed(2)}
                    % Used)
                  </b>
                )}
              </span>
            </label>
            <div className={style.mainprogress}>
              <div
                style={{
                  width: `${Number(displayPercentage).toFixed(2)}%`,
                }}
                className={style.progress}
              ></div>
            </div>
            <label>
              <span>
                {/* {`${percentage-Number(userDetails.current_sending_mail_count * 100 / userDetails.maximum_email_send_count).toFixed(2)}`} */}
                {userDetails.current_sending_mail_count >
                userDetails.maximum_email_send_count ? (
                  <b>
                    {userDetails.maximum_email_send_count}/
                    {userDetails.maximum_email_send_count} (
                    {`${Number(percentage - displayPercentage).toFixed(2)}%`}{" "}
                    Remaining)
                  </b>
                ) : (
                  <b>
                    {userDetails.current_sending_mail_count}/
                    {userDetails.maximum_email_send_count} (
                    {`${Number(percentage - displayPercentage).toFixed(2)}%`}{" "}
                    Remaining)
                  </b>
                )}{" "}
              </span>
            </label>
          </div>
        </Card>
      </Col>
    </>
  );
};
export default SubscriptionUsage;
