import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import {
  EmailActiveWarmupOn,
  EmailConnectDelete,
  MicroSoftConnect,
} from "./Emailconnect.api";

export const useMicrosoftConnect = (navigateToMicroSoft) => {
  return useMutation({
    mutationFn: () => MicroSoftConnect(),
    onError: (err) => {
      if (err?.response?.data?.message) {
        message.error(err?.response?.data?.message);
      } else {
        message.error(err);
      }
    },
    onSuccess: (res) => {
      if (res) {
        navigateToMicroSoft(res);
      }
    },
  });
};

export const useMutationEmailDelete = (id, onSubmit) => {
  return useMutation({
    mutationFn: () => EmailConnectDelete(id),
    onError: (err) => {
      if (err?.response?.data?.message) {
        message.error(err?.response?.data?.message);
      } else {
        message.error(err);
      }
    },
    onSuccess: (res) => {
      if (res) {
        onSubmit(res);
      }
    },
  });
};

export const useMutationEmailWarmupActive = (id, value, onSubmit) => {
  return useMutation({
    mutationFn: () => EmailActiveWarmupOn(id, value),
    onError: (err) => {
      if (err?.response?.data?.message) {
        message.error(err?.response?.data?.message);
      } else {
        message.error(err);
      }
    },
    onSuccess: (res) => {
      if (res) {
        onSubmit(res);
      }
    },
  });
};
