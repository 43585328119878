import axios from "axios";
import { API } from "../../config/api/Index";
import { DataService } from "../../config/DataService";
import { message } from "antd";

// CREDIT PLAN API CALL
export const creditPlanList = () => {
  return async (dispatch) => {
    let responseIsoCode = await axios.get(
      "https://scripts.pubnation.com/flooring/countryLookup"
    );
    try {
      if (responseIsoCode.status == 200) {
        const response = await DataService.get(
          `${API.subscription.creditPlanList}/US`
        );
        if (response.data.status) {
          dispatch({
            type: API.subscription.creditPlanList,
            creditPlanList: response.data.data,
          });
          return true;
        } else {
          return false;
        }
      } else {
        const response = await DataService.get(
          `${API.subscription.creditPlanList}/US`
        );
        if (response.data.status) {
          dispatch({
            type: API.subscription.creditPlanList,
            creditPlanList: response.data.data,
          });
          return true;
        } else {
          return false;
        }
      }
    } catch (err) {
      return false;
    }
  };
};

// PURCHASE CREDIT PLAN API CALL
export const purchaseCredit = (id) => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.post(
        API.subscription.purchaseCredit,
        id
      );
      if (loggedIn.data.status) {
        return { status: true, data: loggedIn.data.data };
      } else {
        message.error(loggedIn.data.message);
        return { status: false };
      }
    } catch (err) {
      return { status: false };
    }
  };
};
