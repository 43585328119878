// DEFINE ALL EXTERNAL IMPORT
import React from "react";
import { Col, Skeleton } from "antd";
import { Collapse } from "antd";
import { FaCheck } from "react-icons/fa6";
import { IoWarningSharp } from "react-icons/io5";
import { MdCheck } from "react-icons/md";


// DEFINE ALL INTERNAL IMPORT
import InboxPlacementComponents from "./InboxPlacement";
import DomainSetupDetails from "./DomainSetupDetails";
import EmailContentDetails from "./EmailContentDetails";
import styles from "./deliverability.module.css";
import inboxIcons from "../../../assets/deleverability/direct-inbox.png";
import emailContactIcons from "../../../assets/deleverability/sms.png";
import DomainSetUpLableComponnets from "./DomainSetUpLableComponnets";
import RoundedButtion from "../../../component/deliverabilityTest/RoundedButtion";
import DomainIPBlackList from "./DomainIPBlackList";
import { RxCross2 } from "react-icons/rx";
import { PiWarningCircleLight } from "react-icons/pi";

const LableComponnets = ({ imgSrc, title, btn }) => {
  return (
    <>
      <div className={styles.collpseItemContainer}>
        <div className={styles.collpseItemDetails}>
          <img src={imgSrc} alt="sms edit icons" />
        </div>
        <div className={styles.itemDetailsInboxContaienr}>
          <h3>{title}</h3>
          {btn}
        </div>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION
const DeliverabilityDetails = ({ deleverybityData }) => {
  console.log("status", deleverybityData?.emailContentResult?.status)
  const onChange = (key) => {
    console.log(key);
  };
  // DEFINE ALL ITEMS OF
  const items = [
    {
      key: "1",
      label: <DomainSetUpLableComponnets deleverybityData={deleverybityData} />,
      children: <DomainSetupDetails deleverybityData={deleverybityData} />,
    },
    {
      key: "2",
      label: (
        <LableComponnets
          imgSrc={inboxIcons}
          title={"Inbox Placement"}
          subTitle={" Inbox: 83%"}
          btn={
            deleverybityData ? (
              <RoundedButtion
                className={
                  deleverybityData?.inboxPlacementScore == 100 ? "green" : "red"
                }
              >
                {deleverybityData?.inboxPlacementScore == 100 ? (
                  <MdCheck fontSize={"medium"} />
                ) : (
                  <IoWarningSharp />
                )}
                Inbox:{" "}
                {deleverybityData?.inboxPlacementScore
                  ? deleverybityData?.inboxPlacementScore
                  : 0}
                %
              </RoundedButtion>
            ) : (
              <Skeleton.Button
                active={true}
                size="default"
                shape="round"
                style={{ width: "100px" }}
              />
            )
          }
        />
      ),
      children: (
        <InboxPlacementComponents deleverybityData={deleverybityData} />
      ),
    },
    {
      key: "3",
      label: (
        <LableComponnets
          imgSrc={inboxIcons}
          title={"Domain and IP blacklists"}
          btn={
            deleverybityData ? (
              <div className={styles.lableBtnContainer}>
                <RoundedButtion
                  className={
                    deleverybityData?.blackListResult?.status == "true"
                      ? "green"
                      : "red"
                  }
                >
                  {deleverybityData?.blackListResult?.status == "true" ? (
                    <MdCheck fontSize={"medium"} />
                  ) : (
                    <IoWarningSharp />
                  )}
                  Domain
                </RoundedButtion>
                {/* <RoundedButtion
                  className={
                    deleverybityData?.blackListResult?.status == "true"
                      ? "green"
                      : "red"
                  }
                >
                  {deleverybityData?.blackListResult?.status == "true" ? (
                    <FaCheck />
                  ) : (
                    <RxCross2 />
                  )}
                  Sender IP
                </RoundedButtion> */}
              </div>
            ) : (
              <>
                <Skeleton.Button
                  active={true}
                  size="default"
                  shape="round"
                  style={{ width: "100px" }}
                />
                <Skeleton.Button
                  active={true}
                  size="default"
                  shape="round"
                  style={{ width: "100px" }}
                />
              </>
            )
          }
        />
      ),
      children: <DomainIPBlackList deleverybityData={deleverybityData} />,
    },
    {
      key: "4",
      label: (
        <>
          <LableComponnets
            imgSrc={emailContactIcons}
            title={"Email Content"}
            subTitle={" Spamfree"}
            btn={
              deleverybityData ? (
                <RoundedButtion
                  className={
                    deleverybityData?.emailContentResult?.status == 'true' ? "warning" : "green"
                  }
                >

                  {deleverybityData?.emailContentResult?.status == 'true' ? (

                    <PiWarningCircleLight className={styles.checkIconsBtn} />
                  ) : (
                    <MdCheck fontSize={"medium"} />
                  )}

                  {deleverybityData?.emailContentResult?.status == 'true'
                    ? `Spam words found : ${deleverybityData?.emailContentResult?.data?.countSpamWord}`
                    : 'Spam-free'}

                </RoundedButtion>
              ) : (
                <Skeleton.Button
                  active={true}
                  size="default"
                  shape="round"
                  style={{ width: "100px" }}
                />
              )

            }
          />
        </>
      ),
      children: <EmailContentDetails deleverybityData={deleverybityData} />,
    },
  ];
  return (
    <Col span={24}>
      <div className={styles.collepseContainer}>
        <h3>Details</h3>
        <Collapse
          collapsible={deleverybityData ? "" : "disabled"}
          accordion
          onChange={onChange}
          items={items}
          bordered={false}
          className="domainCollpseContainer"
          expandIconPosition="right"
        />
      </div>
    </Col>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default DeliverabilityDetails;
