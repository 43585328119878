import React from "react";

const SMTPSVGIcon = () => {
  return (
    <svg
      viewBox="0 0 27 27"
      fill="none"
      height="28px"
      width="28px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.834 23.9163H8.16732C4.66732 23.9163 2.33398 22.1663 2.33398 18.083V9.91634C2.33398 5.83301 4.66732 4.08301 8.16732 4.08301H19.834C23.334 4.08301 25.6673 5.83301 25.6673 9.91634V18.083C25.6673 22.1663 23.334 23.9163 19.834 23.9163Z"
        stroke="#101828"
        strokeWidth="1.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M19.8327 10.5L16.181 13.4167C14.9793 14.3733 13.0077 14.3733 11.806 13.4167L8.16602 10.5"
        stroke="#101828"
        strokeWidth="1.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SMTPSVGIcon;
