import React from "react";
import Template from "../../component/Template/Template/Template";

// DEFINE MAIN FUNCTION
const Templates = () => {
  // DEFINE ALL STATES

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <Template />
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default Templates;
