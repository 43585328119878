import React, { useState } from "react";
import { Form, Input, Spin, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../redux/authentication/action";
import { useDispatch } from "react-redux";
import logo from "../../assets/logo.svg";
import style from "./AuthFiles.module.css";

// DEFINE MAIN FUNCTION
const Login = () => {
  // DEFINE ALL STATES
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formValidateStatus, setFormValidateStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  // DEFINE FUNCTION FOR FORM SUBMIT
  const onFinish = async (values) => {
    setLoading(true);
    let response = await dispatch(login(values));
    console.log("response", response);
    if (response.status) {
      console.log("response.data", response.data);
      if (response.data !== "") {
        console.log("response.data", response.data);
        navigate(response?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  // DEFINE FUNCTION FOR FORM ONCHANGE VALUES VALIDATIONS CHECK
  const onCheckFormChangeValue = (changedValues, allValues) => {
    if (
      !Object.values(allValues).includes(undefined) &&
      !Object.values(allValues).includes("")
    ) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!allValues.email || emailRegex.test(allValues.email)) {
        setFormValidateStatus(true);
      } else {
        setFormValidateStatus(false);
      }
    } else {
      setFormValidateStatus(false);
    }
  };

  // DEFINE FUNCTION FOR CUSTOM VALIDATION EMAIL
  const validateEmail = (_, value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value || emailRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter valid email!");
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <div className={style.loginComponent}>
        <div className={style.detailsComponent}>
          <img src={logo} />
          <h2 className={style.title}>Welcome back 🥳</h2>
          <p className={style.description}>
            Log in to continue. We're excited to have you back!
          </p>
        </div>
        <Form
          layout="vertical"
          name="basic"
          size="large"
          requiredMark={false}
          onFinish={(value) => {
            if (!loading) {
              onFinish(value);
            }
          }}
          form={form}
          onValuesChange={(value, allValues) => {
            onCheckFormChangeValue(value, allValues);
          }}
          autoComplete="off"
        >
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
              { validator: validateEmail },
            ]}
          >
            <Input placeholder="Enter Email" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password placeholder="Enter Password" />
          </Form.Item>
          <div className={style.forgotPasswordTitle}>
            <Link to={"/reset-password"}>
              <span>Forgot Password?</span>
            </Link>
          </div>

          <Form.Item className={style.submitButton}>
            <button
              type="submit"
              style={{
                opacity: formValidateStatus ? "100%" : "35%",
              }}
            >
              {loading ? <Spin /> : "Log in"}
            </button>
          </Form.Item>
        </Form>
        <div className={style.otherDescription}>
          <p>
            Don’t have an account yet?
            <Link to={"/signup"}>
              <span>Sign Up</span>
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default Login;
