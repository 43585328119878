import React, { useEffect, useState } from "react";
import {
  Tabs,
  Form,
  Input,
  Space,
  Select,
  Col,
  Row,
  Radio,
  Switch,
  Spin,
  notification,
  Checkbox,
  Tooltip,
} from "antd";
import { Drawer } from "antd";
import checkImage from "../../assets/Email/Icon.png";
import weekdayImg from "../../assets/Email/Frame.png";
import mailIcon from "../../assets/Email/mail.png";
import itag from "../../assets/Email/close-square.png";
import refreshIcon from "../../assets/Email/refresh.png";
import mailSendicon from "../../assets/Email/sendEmail.png";
import mailRecieveIcon from "../../assets/Email/mailReceive.png";
import mailSavedIcon from "../../assets/Email/mailSaved.png";
import searchIcon from "../../assets/Email/search-normal.png";
import templateIcon from "../../assets/Email/template-icon.png";
import smtpIcone from "../../assets/svg/smtpEmail.svg";
import rightIcon from "../../assets/Email/notification-right.png";
import addIcon from "../../assets/Email/add-square.png";
import "./WarmUpSetting.css";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import Chart from "./Charts";
import {
  formatDateFromNow,
  searchDataOfArray,
} from "../../config/CommonFuntion";
import {
  genarateCapcha,
  getIdBasedTemplateList,
  updateWarmUpSetting,
} from "../../redux/dashboard/action";
import WarmUpBuildSVGIcon from "../../assets/dashboard/WarmUpBuildSVGIcon";
import WarmUpRecoverSVGIcon from "../../assets/dashboard/WarmUpRecoverSVGIcon";
import WarmUpMaintainSVGIcon from "../../assets/dashboard/WarmUpMaintainSVGIcon";
import WarmUpToolTipSVGIcon from "../../assets/dashboard/WarmUpToolTipSVGIcon";
import SuccessSVGIcon from "../../assets/dashboard/SuccessSVGIcon";
import FailSVGIcon from "../../assets/dashboard/FailSVGIcon";
import { useDispatch, useSelector } from "react-redux";
import { getItem } from "../../utility/localStorageControl";
import { Link, useNavigate, useParams } from "react-router-dom";
import { type } from "@testing-library/user-event/dist/type";
import RoundedButtion from "../deliverabilityTest/RoundedButtion";
// import { getItem } from "../../utility/localStorageControl";
// import { Link } from "react-router-dom";

// DEFINE MAIN FUNCTION
const WarmUpSetting = ({
  warmupModel,
  value,
  onClose,
  analyticsData,
  onChartTypeChange,
  setOpenDrawer,
  clickEditWarmup,
  setEditWarmupIndex,
  openDrawer,
  settingModelClick,
}) => {
  // DEFINE ALL STATES
  const [analiysAllData, setAnaliysAllData] = useState(analyticsData);
  console.log("value", value);
  console.log("clickEditWarmup", clickEditWarmup);
  useEffect(() => {
    setAnaliysAllData(analyticsData);
  }, analyticsData);
  console.log("analiysAllData", analiysAllData);
  // let analyticsData = useSelector((state) => state.dashboard.typeData);
  // console.log("analiysData", analyticsData);
  const { TabPane } = Tabs;
  console.log("settingModelClicksettingModelClick", settingModelClick);
  const maxEmailsSendPerDays = getItem("userDetails")
    .maximum_daily_email_send_count
    ? getItem("userDetails").maximum_daily_email_send_count
    : 50;
  console.log("maxEmailsSendPerDays", maxEmailsSendPerDays);
  console.log("settingModelClicksettingModelClick", settingModelClick);
  const dispatch = useDispatch();
  const [settingForm] = Form.useForm();
  const [activeTab, setActiveTab] = useState(
    clickEditWarmup == "2" ? "2" : "1"
  );
  const { id, type } = useParams();
  console.log("id", id, type);
  const [showDropDown, setShowDropDown] = useState(false);
  const [addedEmails, setAddedEmails] = useState(
    value?.sender_templates?.length
      ? value.sender_templates.map((val) => ({
          label: val.title,
          value: val.id,
        }))
      : []
  );
  console.log("addedEmailsaddedEmails", addedEmails);
  const [emailListLoading, setEmailListLoading] = useState(true);
  const [warmupMode, setWarmupMode] = useState(value?.warmup_mode);
  const [changeWeek, setChangeWeek] = useState(value?.skipWeekends);
  const [selectedESP, setSelectedESP] = useState("all");
  const navigate = useNavigate();
  const [catpchaLoading, setCatpchaLoading] = useState(false);
  const [activityFilter, setActivityFilter] = useState("deliverability");
  const [dayWiseFilter, setDayWiseFilter] = useState("today");
  const [emailType, setEmailType] = useState(
    value?.warmup_email_text == "" ? "custom" : value?.warmup_email_text
  );
  const [endPoint, setEndPoint] = useState("report");
  const isEnableCustomTemplate =
    getItem("userDetails")?.subscriptions?.subscriptionPlan
      ?.subscription_plan_type;
  console.log("isEnableCustomTemplate", isEnableCustomTemplate);
  // if(clickEditWarmup){
  //   setActiveTab(clickEditWarmup)
  // }
  useEffect(() => {
    setActiveTab(clickEditWarmup == "2" ? "2" : "1");
  }, [clickEditWarmup, settingModelClick]);

  useEffect(() => {
    if (activeTab == "2") {
      setEndPoint("setting");
    }
    if (activeTab == "1") {
      setEndPoint("report");
    }
  }, [activeTab, setEndPoint]);
  console.log("endpoint", endPoint);
  console.log("activeTab", activeTab);

  const [maxEmailsSendPerDay, setMaxEmailsSendPerDay] =
    useState(maxEmailsSendPerDays);
  const [increaseByEmailPerDay, setIncreaseByEmailPerDay] = useState(3);
  const [emailTemplatesList, setEmailTemplatesList] = useState([]);
  console.log("emailTemplatesList", emailTemplatesList);
  const [selectedEmails, setSelectedEmails] = useState(
    value?.sender_templates?.length
      ? value.sender_templates.map((val) => ({
          label: val.title,
          value: val.id,
        }))
      : []
  );
  console.log("selectedEmails", selectedEmails);
  const [backEmailTemplatesList, setBackEmailTemplatesList] = useState([]);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [minRangeValue, setMinRangeValue] = useState(0);
  console.log("max emails send per day01", maxEmailsSendPerDay);
  console.log("value: ", value);
  // DEFINE USEEFFECT
  useEffect(() => {
    settingForm.setFieldsValue(value);
    settingForm.setFieldsValue({
      warmup_email_text:
        value?.warmup_email_text == "" ? "custom" : value?.warmup_email_text,
    });
    setWarmupMode(value?.warmup_mode);
    templateList();
  }, [value]);

  const templateList = async () => {
    setEmailListLoading(true);
    let response = await dispatch(getIdBasedTemplateList(value?.id));
    if (response?.length > 0) {
      setEmailListLoading(false);
      setEmailTemplatesList(
        response.map((resValue) => {
          return {
            value: resValue?.id,
            label: resValue?.title,
          };
        })
      );
      setBackEmailTemplatesList(
        response.map((resValue) => {
          return {
            value: resValue?.id,
            label: resValue?.title,
          };
        })
      );
      setAddedEmails(
        value?.sender_templates?.length
          ? value.sender_templates.map((val) => ({
              label: val.title,
              value: val.id,
            }))
          : []
      );

      setSelectedEmails(
        value?.sender_templates?.length
          ? value.sender_templates.map((val) => ({
              label: val.title,
              value: val.id,
            }))
          : []
      );
    } else {
      setEmailListLoading(false);
      setEmailTemplatesList([]);
    }
  };

  // useEffect(()=>{
  //   if (!maxEmailsSendPerDay >= increaseByEmailPerDay) {
  //     console.log("error: max emails send per day")
  //     return "max emails send per day";
  //   }

  // }, [maxEmailsSendPerDay])

  const onCloseDrawer = () => {
    setOpenDrawer(false);
    // setActiveTab(clickEditWarmup == "2" ? "2" : "1");
    setActivityFilter("deliverability");
    setMaxEmailsSendPerDay(maxEmailsSendPerDays);
    navigate("/email-warmup");
  };

  // DEFINE USEEFFECT
  useEffect(() => {
    settingForm.setFieldsValue(value);
    settingForm.setFieldsValue({
      warmup_email_text:
        value?.warmup_email_text == "" ? "custom" : value?.warmup_email_text,
    });
    onChartTypeChange(value, dayWiseFilter, activityFilter, selectedESP);
  }, [dayWiseFilter, activityFilter, selectedESP]);

  // DEFINE FUNCTION FOR MODAL CANCEL
  const handleCancel = () => {
    settingForm.resetFields();
    onClose(false);
  };

  // DEFINE FUNCTION FOR CHECK ARRAY OBJECTS IN SAME VALUES IS EXITS OR NOT
  function checkIdExistence(array1, array2) {
    const idsToCheck = array1.map((item) => item.value);
    const valuesInArray2 = array2.map((item) => item.value);
    if (
      (JSON.stringify(idsToCheck) === JSON.stringify(valuesInArray2)) ==
      false
    ) {
      return (
        JSON.stringify(idsToCheck.reverse()) === JSON.stringify(valuesInArray2)
      );
    } else {
      return JSON.stringify(idsToCheck) === JSON.stringify(valuesInArray2);
    }
  }

  // DEFINE FUNCTION FOR FORM SUBMIT
  const handleSubmit = async (values) => {
    setSubmitStatus(true);
    if (
      values?.warmup_email_text == "ai_powered" ||
      (values?.warmup_email_text == "custom" && addedEmails.length > 0)
    ) {
      setSubmitStatus(false);
      let response = await dispatch(
        updateWarmUpSetting(
          {
            ...values,
            skipWeekends: changeWeek,
            warmup_mode: warmupMode,
            sender_templates: addedEmails.map((value) => {
              return { id: value.value };
            }),
          },
          value?.id
        )
      );
      if (response) {
        notification.success({
          message: (
            <span className="notificationText">
              Email account updated successfully.
            </span>
          ),
          description: <span className="startBorder"></span>,
          icon: <img src={rightIcon} alt="right-icon" width={20} height={20} />,
          className: "custom-notification",
          duration: 3,
          style: { width: "362px", height: "68px" },
        });
        setEmailListLoading(false);
        setEmailTemplatesList([]);
        setAddedEmails([]);
        setSelectedEmails([]);
        setBackEmailTemplatesList([]);
        settingForm.resetFields();
        onClose(true);
      }
    } else {
      setSubmitStatus(true);
    }
  };

  // DEFINE FUNCTION FOR REGENARATE CAPTCHA API CALL
  const reGenarateCapcha = async () => {
    setCatpchaLoading(true);
    let response = await dispatch(genarateCapcha());
    if (response.status) {
      settingForm.setFieldsValue({ warmup_tag: response.data });
      setCatpchaLoading(false);
    } else {
      setCatpchaLoading(false);
    }
  };

  // DEFINE TEMPLATES ARRAY
  const templates = [
    { id: 1, name: "Template 1", icon: "icon1.png" },
    { id: 2, name: "Template 2", icon: "icon2.png" },
    // Add more templates as needed
  ];

  // DEFINE FUNCTION FOR MIN & MAX VALUE CHECK VALIDATION
  const validateMaxValue = (rule, value, callback) => {
    const minValue = settingForm.getFieldValue("min_email_send");
    if (value && minValue && parseFloat(value) <= parseFloat(minValue)) {
      callback("Max value must be greater than min value");
    } else {
      callback();
    }
  };

  // DEFINE MAIN FUNCTION RETURN
  const userDetail = useSelector((state) => state?.auth?.userDetails);
  const isEnableAdvanceSetting =
    userDetail?.subscriptions?.subscriptionPlanFeatures
      ?.advanced_warmup_settings;
  const isEnableFreeTrial = userDetail?.free_trial;

  const handleChangeEndpoint = (value) => {
    setEndPoint(value);
  };

  useEffect(() => {
    // Update the URL path when endpoint changes
    const newPath = window.location.pathname.replace(
      /\/[^/]*$/,
      "/" + endPoint
    );
    window.history.pushState({}, "", newPath);
  }, [endPoint, activeTab]);
  const handleSave = () => {
    settingForm.submit();
    navigate("/email-warmup");
    onClose(false);
  };
  console.log("selectedESP", selectedESP);
  return (
    <div className="warmupSettingContainer">
      <Drawer
        width={"53%"}
        title={false}
        onClose={onCloseDrawer}
        open={openDrawer}
        closeIcon={false}
      >
        <div
          className="modalContent"
          // style={{ height: activeTab == 1 ? "86vh" : "76vh" }}
        >
          <div className="header_contaienr">
            <Space className="modalTitle">
              <div className="titleContainerICons">
                <span>{value?.sender_username}</span>
                <div>
                  {value.type == "google" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="20"
                      height="20"
                      viewBox="0 0 48 48"
                    >
                      <path
                        fill="#fbc02d"
                        d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                      ></path>
                      <path
                        fill="#e53935"
                        d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                      ></path>
                      <path
                        fill="#4caf50"
                        d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                      ></path>
                      <path
                        fill="#1565c0"
                        d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                      ></path>
                    </svg>
                  ) : value.type == "smtp_imap" ? (
                    <img src={smtpIcone} alt="" width={20} height={20} />
                  ) : (
                    <img
                      src={
                        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUX59uiRkc2pPDHXubtYIW7wc5eJicBQ4eDK6SUNlP_g&s"
                      }
                      alt=""
                      width={20}
                      height={20}
                    />
                  )}
                </div>
              </div>
              <div>
                <CloseOutlined onClick={onCloseDrawer} />
              </div>
            </Space>
            <Space>
              <span className="emailText">{value?.sender_email}</span>
              {value?.warmup_status == "active" ||
              value?.warmup_status == "paused" ? (
                <SuccessSVGIcon />
              ) : (
                <FailSVGIcon />
              )}{" "}
            </Space>
          </div>
          <div className="dateContainer">
            <img src={mailIcon} alt="mail-icon" />
            <span>
              Started on{" "}
              <span>{moment(value?.createdAt).format("MMM Do YYYY")}</span>
            </span>
            <span>|</span>
            <span>{formatDateFromNow(value?.createdAt)}</span>
          </div>
          <Tabs
            activeKey={activeTab}
            // defaultActiveKey={activeTab}
            onChange={(key) => {
              setActiveTab(key);

              // setEditWarmupIndex(key?.toString());
            }}
            className="customTab"
          >
            <TabPane
              tab={
                <span
                  onClick={() => {
                    handleChangeEndpoint("report");
                  }}
                  className="tabText"
                >
                  Analytics
                </span>
              }
              key="1"
              value="1"
              className="tab"
            >
              <div className="analyticsDiv">
                <span>Summary for Today</span>
                <div className="summaryDiv">
                  <div className="countDiv">
                    <img
                      src={mailSendicon}
                      alt="mail-send"
                      width={32}
                      height={32}
                    />
                    <div className="countText">
                      <span>{value?.sent_today}</span>
                      <span>Warmup emails sent</span>
                    </div>
                  </div>
                  <div className="countDiv">
                    <img
                      src={mailRecieveIcon}
                      alt="mail-send"
                      width={32}
                      height={32}
                    />
                    <div className="countText">
                      <span>{value?.receive_today}</span>
                      <span>Warmup emails received</span>
                    </div>
                  </div>
                  <div className="countDiv">
                    <img
                      src={mailSavedIcon}
                      alt="mail-send"
                      width={32}
                      height={32}
                    />
                    <div className="countText">
                      <span>
                        {value?.save_from_spam ? value?.save_from_spam : "0"}
                      </span>
                      <span>Saved from spam</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="warmupInsightsDiv">
                <Row justify={"space-between"} className="warmupChartDiv">
                  <div className="warnupinsighText">
                    <span>Warmup Insights</span>
                  </div>
                  {activityFilter == "deliverability" && (
                    <div className="chartFilter">
                      <span>ESP-specific Deliverability</span>
                      <Select
                        defaultValue={selectedESP}
                        popupClassName="custom-dropdown"
                        onChange={(value) => {
                          setSelectedESP(value);
                        }}
                      >
                        <Select.Option value="all">All the ESPs</Select.Option>
                        <Select.Option value="gmail">Gmail</Select.Option>
                        <Select.Option value="microsoft">
                          Microsoft
                        </Select.Option>
                        <Select.Option value="other">Other</Select.Option>
                      </Select>
                    </div>
                  )}
                </Row>
                <Row justify={"space-between"} className="filterDiv" gutter={8}>
                  <div>
                    <button
                      className={
                        activityFilter == "deliverability" ? "activeButton" : ""
                      }
                      onClick={() => {
                        setActivityFilter("deliverability");
                        setDayWiseFilter("today");
                      }}
                    >
                      <span>Deliverability</span>
                    </button>
                    <button
                      className={
                        activityFilter == "activity" ? "activeButton" : ""
                      }
                      onClick={() => {
                        setActivityFilter("activity");
                        setDayWiseFilter("today");
                      }}
                    >
                      <span>Activity</span>
                    </button>
                  </div>
                  {activityFilter == "deliverability" ? (
                    <div>
                      <button
                        className={
                          dayWiseFilter == "today" ? "activeButton" : ""
                        }
                        onClick={() => {
                          setDayWiseFilter("today");
                        }}
                      >
                        <span>Today</span>
                      </button>
                      <button
                        className={
                          dayWiseFilter == "last_7_days" ? "activeButton" : ""
                        }
                        onClick={() => {
                          setDayWiseFilter("last_7_days");
                        }}
                      >
                        <span>Last 7 Days</span>
                      </button>
                      <button
                        className={
                          dayWiseFilter == "last_4_weeks" ? "activeButton" : ""
                        }
                        onClick={() => {
                          setDayWiseFilter("last_4_weeks");
                        }}
                      >
                        <span>Last 4 Weeks</span>
                      </button>
                      <button
                        className={
                          dayWiseFilter == "last_3_months" ? "activeButton" : ""
                        }
                        onClick={() => {
                          setDayWiseFilter("last_3_months");
                        }}
                      >
                        <span>Last 3 Months</span>
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        className={
                          dayWiseFilter == "today" ? "activeButton" : ""
                        }
                        onClick={() => {
                          setDayWiseFilter("today");
                        }}
                      >
                        <span>Today</span>
                      </button>
                      <button
                        className={
                          dayWiseFilter == "last_7_days" ? "activeButton" : ""
                        }
                        onClick={() => {
                          setDayWiseFilter("last_7_days");
                        }}
                      >
                        <span>Last 7 Days</span>
                      </button>
                      <button
                        className={
                          dayWiseFilter == "last_4_weeks" ? "activeButton" : ""
                        }
                        onClick={() => {
                          setDayWiseFilter("last_4_weeks");
                        }}
                      >
                        <span>Last 4 Weeks</span>
                      </button>
                      <button
                        className={
                          dayWiseFilter == "last_3_months" ? "activeButton" : ""
                        }
                        onClick={() => {
                          setDayWiseFilter("last_3_months");
                        }}
                      >
                        <span>Last 3 Months</span>
                      </button>
                      {/* <button
                        className={
                          dayWiseFilter == "year" ? "activeButton" : ""
                        }
                        onClick={() => {
                          setDayWiseFilter("year");
                        }}
                      >
                        <span>Year</span>
                      </button> */}
                    </div>
                  )}
                </Row>
                {activityFilter == "activity" && (
                  <div className="filterCountDiv">
                    <div>
                      <span></span>
                      <span>Sent {analyticsData?.total?.send_today}</span>
                    </div>
                    <div>
                      <span></span>
                      <span>
                        Received {analyticsData?.total?.receive_today}
                      </span>
                    </div>
                  </div>
                )}
                <Chart
                  activityFilter={activityFilter}
                  analyticsData={analyticsData[dayWiseFilter]}
                />
              </div>
            </TabPane>
            <TabPane
              tab={
                <span
                  onClick={() => {
                    handleChangeEndpoint("setting");
                  }}
                  className="tabText"
                >
                  Settings
                </span>
              }
              value="2"
              key="2"
            >
              {/* {isEnableAdvanceSetting || isEnableFreeTrial ? ( */}
              <Form
                name="tab1"
                onFinish={handleSubmit}
                layout="vertical"
                form={settingForm}
                className="form"
              >
                <div className="warm_up_mode_container">
                  <div
                    className={`warm_up_mode_container_component ${
                      warmupMode == "build"
                        ? "warm_up_mode_container_component_selected"
                        : ""
                    }`}
                    onClick={() => {
                      setWarmupMode("build");
                    }}
                  >
                    <WarmUpBuildSVGIcon status={warmupMode == "build"} />
                    <span>
                      Build{" "}
                      <Tooltip
                        placement="top"
                        title={
                          "Choose this warmup mode if your email account is fairly new and you want to build a sender reputation to ensure your emails don’t land in spam when you start sending more emails from this email account. The system builds the sender reputation gradually"
                        }
                        overlayInnerStyle={{
                          backgroundColor: "white",
                          color: "#344054",
                          textAlign: "start",
                          fontSize: "12px",
                          lineHeight: "16.8px",
                        }}
                        arrow={false}
                      >
                        <WarmUpToolTipSVGIcon />
                        {""}
                      </Tooltip>
                    </span>
                    <div>
                      Suitable for new email accounts/domains for building
                      sender reputation
                    </div>
                    <label>High Reply Rate</label>
                  </div>
                  <div
                    className={`warm_up_mode_container_component ${
                      warmupMode == "recover"
                        ? "warm_up_mode_container_component_selected"
                        : ""
                    }`}
                    onClick={() => {
                      setWarmupMode("recover");
                    }}
                  >
                    <WarmUpRecoverSVGIcon status={warmupMode == "recover"} />
                    <span>
                      Recover
                      <Tooltip
                        placement="top"
                        title={
                          " Choose this warmup mode if your email account is old enough but many of your emails are landing in spam. The system does extensive deliverability fixes in this mode that help you recover the sender reputation quickly. "
                        }
                        overlayInnerStyle={{
                          backgroundColor: "white",
                          color: "#344054",
                          textAlign: "start",
                          fontSize: "12px",
                          lineHeight: "16.8px",
                        }}
                        arrow={false}
                      >
                        <WarmUpToolTipSVGIcon />
                        {""}
                      </Tooltip>
                    </span>
                    <div>
                      Suitable for email accounts with poor deliverability to
                      get it fixed
                    </div>
                    <label>High Reply Rate</label>
                  </div>
                  <div
                    className={`warm_up_mode_container_component ${
                      warmupMode == "maintain"
                        ? "warm_up_mode_container_component_selected"
                        : ""
                    }`}
                    onClick={() => {
                      setWarmupMode("maintain");
                    }}
                  >
                    <WarmUpMaintainSVGIcon status={warmupMode == "maintain"} />
                    <span>
                      Maintain
                      <Tooltip
                        placement="top"
                        title={
                          "Choose this warmup mode if your email account is old enough and you’re getting decent deliverability. The system helps you maintain an optimum reputation with moderate engagement. "
                        }
                        overlayInnerStyle={{
                          backgroundColor: "white",
                          color: "#344054",
                          textAlign: "start",
                          fontSize: "12px",
                          lineHeight: "16.8px",
                        }}
                        arrow={false}
                      >
                        <WarmUpToolTipSVGIcon />
                        {""}
                      </Tooltip>
                    </span>
                    <div>
                      Suitable for old and warmed up email accounts to maintain
                      the optimum deliverability
                    </div>
                    <label>Moderate Reply Rate</label>
                  </div>
                </div>
                {warmupMode != "build" && warmupMode != "" ? (
                  <Row gutter={[10]}>
                    <Col>
                      <Form.Item
                        name="min_email_send"
                        label={
                          <div className="formLabel">
                            <span>
                              Sending Range
                              <Tooltip
                                placement="top"
                                title={
                                  "Select the range of count for daily warmup sending and the system randomly picks any value in between to keep your warmup sending dynamic and human-like which helps you warm up faster."
                                }
                                overlayInnerStyle={{
                                  backgroundColor: "white",
                                  color: "#344054",
                                  textAlign: "start",
                                  fontSize: "12px",
                                  lineHeight: "16.8px",
                                }}
                                arrow={false}
                              >
                                <WarmUpToolTipSVGIcon className={"icons"} />
                                {""}
                              </Tooltip>
                            </span>
                          </div>
                        }
                        validateTrigger={["onChange", "onBlur"]} // Validate on change and blur events
                        rules={[
                          {
                            validator: (rule, value) => {
                              setMinRangeValue(value);
                              if (
                                !isNaN(value) &&
                                value > 0 &&
                                value <=
                                  settingForm.getFieldsValue()
                                    .max_emails_per_day
                              ) {
                                // Check for valid number, minimum of 0, and maximum of 50
                                return Promise.resolve(); // Validation successful
                              }
                              return Promise.reject(
                                `Please enter a number between 0 and  
                                  ${
                                    settingForm.getFieldsValue()
                                      .max_emails_per_day
                                  }`
                              ); // Validation error
                            },
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          suffix={<span className="inputSuffix">Minimum</span>}
                          onInput={(event) => {
                            event.target.value = event.target.value.replace(
                              /\D/g,
                              ""
                            );
                          }}
                          placeholder="Enter Min Send Range"
                          className="customeInput"
                        />
                      </Form.Item>
                    </Col>
                    <div className="rangeMid">-</div>
                    <Col>
                      <Form.Item
                        // rules={[{ validator: validateMaxValue }]}
                        name="max_email_send"
                        label={
                          <div className="formLabel">
                            <span style={{ color: "#fff" }}>lnoib</span>
                          </div>
                        }
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            validator: (rule, value) => {
                              if (
                                !isNaN(value) &&
                                value &&
                                value > 0 &&
                                value >= minRangeValue &&
                                value <=
                                  settingForm.getFieldsValue()
                                    .max_emails_per_day
                              ) {
                                // Check for valid number, minimum of 0, and maximum of 50
                                return Promise.resolve(); // Validation successful
                              }
                              return Promise.reject(
                                `Please enter a number between ${minRangeValue} and  
                                  ${
                                    settingForm.getFieldsValue()
                                      .max_emails_per_day
                                  }`
                              ); // Validation error
                            },
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          suffix={<span className="inputSuffix">Maximum</span>}
                          onInput={(event) => {
                            event.target.value = event.target.value.replace(
                              /\D/g,
                              ""
                            );
                          }}
                          placeholder="Enter Max Send Range"
                          className="customeInput"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                ) : (
                  <Row gutter={[20]}>
                    <Col span={12}>
                      <Form.Item
                        name="start_with_email_per_day"
                        label={
                          <div className="formLabel">
                            <span>Start with emails/day</span>
                            <span>(Recommended 3)</span>
                          </div>
                        }
                        validateTrigger={["onChange", "onBlur"]} // Validate on change and blur events
                        rules={[
                          {
                            validator: (rule, value) => {
                              if (
                                !isNaN(value) &&
                                value >= 0 &&
                                value <= maxEmailsSendPerDay
                              ) {
                                // Check for valid number, minimum of 0, and maximum of 50
                                return Promise.resolve(); // Validation successful
                              }
                              return Promise.reject(
                                "Please enter a number between 0 and " +
                                  maxEmailsSendPerDay
                              ); // Validation error
                            },
                          },
                        ]}
                      >
                        <Input
                          onInput={(event) => {
                            event.target.value = event.target.value.replace(
                              /\D/g,
                              ""
                            );
                          }}
                          placeholder="Enter Email Per Day"
                          type="number"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="increase_by_email_per_day"
                        label={
                          <div className="formLabel">
                            <span>Increase by emails every day</span>
                            <span>(Recommended 3)</span>
                          </div>
                        }
                        validateTrigger={["onChange", "onBlur"]} // Validate on change and blur events
                        rules={[
                          {
                            validator: (rule, value) => {
                              setIncreaseByEmailPerDay(value);
                              console.log("value of validation error", value);
                              if (
                                !isNaN(value) && // Check if value is a number
                                value > 0 && // Check if value is greater than 0
                                value <= maxEmailsSendPerDays
                              ) {
                                return Promise.resolve(); // Validation successful
                              }
                              return Promise.reject(
                                "Please enter a number between 0 and " +
                                  maxEmailsSendPerDay
                              ); // Validation error
                            },
                          },
                        ]}
                      >
                        <Input
                          type="number" // Set input type to number for better validation
                          onInput={(event) => {
                            const newValue = event.target.value.replace(
                              /\D/g,
                              ""
                            );

                            if (newValue !== event.target.value) {
                              event.target.value = newValue;
                            }
                          }}
                          placeholder="Enter Increse Per Day"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                <Row gutter={[20]}>
                  <Col span={12}>
                    <Form.Item
                      name="max_emails_per_day"
                      label={
                        <div className="formLabel">
                          <span>Maximum emails to be sent per day</span>
                          <span>(Recommended 50)</span>
                        </div>
                      }
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          validator: (rule, value) => {
                            if (!value) {
                              return Promise.reject(
                                "Please enter the maximum number of emails to be sent per day."
                              );
                            }
                            if (
                              !isNaN(value) &&
                              value > 0 &&
                              value >= minRangeValue &&
                              value <= maxEmailsSendPerDays
                            ) {
                              return Promise.resolve(); // Validation successful
                            }
                            return Promise.reject(
                              <>
                                <span>
                                  Plan limit exceeded.{" "}
                                  <Link
                                    className="update_subscription"
                                    to={"/subscription/choose-plan"}
                                  >
                                    Upgrade your plan{" "}
                                  </Link>
                                  to increase the limit
                                </span>
                              </>
                            ); // Validation error
                          },
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        onChange={(e) => setMaxEmailsSendPerDay(e.target.value)}
                        onInput={(event) => {
                          event.target.value = event.target.value.replace(
                            /\D/g,
                            ""
                          );
                        }}
                        // defaultValue={maxEmailsSendPerDay}
                        placeholder="Enter Max Email Send Per Day"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="sender_username"
                      label={
                        <div className="formLabel">
                          <span>Sender name</span>
                        </div>
                      }
                    >
                      <Input placeholder="Enter Sender Name" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[20]}>
                  <Col span={12}>
                    <Form.Item
                      className="dashboard_email_capcha_form_item"
                      name="warmup_tag"
                      label={
                        <div className="formLabel">
                          <span>
                            Warmup tag
                            <Tooltip
                              placement="top"
                              title={
                                "It’s a unique identifier that you will find at the end of all your warmup emails so you can create a filter in your mailbox based on this text. Click here to learn more about it. "
                              }
                              overlayInnerStyle={{
                                backgroundColor: "white",
                                color: "#344054",
                                textAlign: "start",
                                fontSize: "12px",
                                lineHeight: "16.8px",
                              }}
                              arrow={false}
                            >
                              <WarmUpToolTipSVGIcon className={"icons"} />
                              {""}
                            </Tooltip>
                          </span>
                          {/* <img src={itag} /> */}
                        </div>
                      }
                    >
                      <Input
                        suffix={
                          catpchaLoading ? (
                            <Spin indicator={<LoadingOutlined spin />} />
                          ) : (
                            <img
                              onClick={() => {
                                if (!catpchaLoading) {
                                  reGenarateCapcha();
                                }
                              }}
                              src={refreshIcon}
                            />
                          )
                        }
                        placeholder="Generate Email Capcha"
                        disabled
                        className="customeInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="warmup_email_text"
                      label={
                        <div className="formLabel">
                          <span>
                            Warmup Email Text
                            <Tooltip
                              placement="top"
                              title={
                                "Choose the content of your warmup emails. You can select your custom email template for warmup emails or system-generated & AI-powered templates. The system creates unique content for each warmup email"
                              }
                              overlayInnerStyle={{
                                backgroundColor: "white",
                                color: "#344054",
                                textAlign: "start",
                                fontSize: "12px",
                                lineHeight: "16.8px",
                              }}
                              arrow={false}
                            >
                              <WarmUpToolTipSVGIcon className={"icons"} />
                              {""}
                            </Tooltip>
                          </span>
                        </div>
                      }
                    >
                      <Radio.Group
                        className="radioGroup"
                        onChange={(e) => {
                          setEmailType(e.target.value);
                        }}
                        style={{ width: "100%" }}
                        value={emailType}
                        defaultValue={
                          value?.warmup_email_text == ""
                            ? "custom"
                            : value?.warmup_email_text
                        }
                      >
                        <Row gutter={3}>
                          <Col span={12}>
                            <Radio
                              value="ai_powered"
                              style={{
                                border:
                                  emailType == "ai_powered"
                                    ? "1px solid #f75f18"
                                    : "",
                              }}
                            >
                              <span>AI-powered templates</span>
                            </Radio>
                          </Col>
                          <Col span={12}>
                            <Radio
                              disabled={
                                isEnableCustomTemplate == "starter"
                                  ? true
                                  : false
                              }
                              value="custom"
                              style={{
                                border:
                                  emailType == "custom"
                                    ? "1px solid #f75f18"
                                    : "",
                              }}
                            >
                              <span>Custom template</span>
                            </Radio>
                          </Col>
                        </Row>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                {emailType == "custom" && (
                  <Row gutter={[20, 0]}>
                    <Col span={12}></Col>
                    {console.log(
                      "addedEmails",
                      addedEmails.map((el) => el.label)
                    )}
                    <Col span={12}>
                      <Select
                        placeholder="Select template"
                        className="templateSelect"
                        style={{
                          marginBottom:
                            submitStatus && addedEmails.length <= 0
                              ? "0px"
                              : "20px",
                        }}
                        popupClassName="selectPopup"
                        value={addedEmails.map((el) => el.label)}
                        showSearch={false}
                        open={showDropDown}
                        onDropdownVisibleChange={(value) => {
                          setSelectedEmails(addedEmails);
                          setShowDropDown(value);
                        }}
                        dropdownRender={(menu) => (
                          <div>
                            {menu}
                            <div className="selctBarFooter emailTemplateFooter">
                              <button
                                onClick={() => {
                                  setSelectedEmails(addedEmails);
                                  setShowDropDown(false);
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                style={{
                                  opacity:
                                    selectedEmails.length > 0 ? "100%" : "35%",
                                }}
                                onClick={() => {
                                  if (selectedEmails.length > 0) {
                                    setAddedEmails(selectedEmails);
                                    setShowDropDown(false);
                                  }
                                }}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        )}
                        mode="multiple"
                        loading={emailListLoading}
                        onChange={(value) => {
                          if (
                            !value.some(
                              (element) => typeof element === "number"
                            )
                          ) {
                            if (value == "add-new-temaplte") {
                              navigate("/templates/add");
                            } else {
                              setSelectedEmails(
                                selectedEmails.filter((template) =>
                                  value.includes(template.label)
                                )
                              );
                              setAddedEmails(
                                selectedEmails.filter((template) =>
                                  value.includes(template.label)
                                )
                              );
                            }
                          }
                        }}
                      >
                        <div className="selcMenuRender">
                          <Input
                            prefix={<img src={searchIcon} alt="search-icon" />}
                            placeholder="Search..."
                            onChange={(event) => {
                              if (event.target.value == "") {
                                setEmailTemplatesList(backEmailTemplatesList);
                              } else {
                                let searchData = searchDataOfArray(
                                  event.target.value,
                                  backEmailTemplatesList,
                                  ["label"]
                                );
                                setEmailTemplatesList(searchData);
                              }
                            }}
                            className="templateListDropDown"
                          />
                        </div>
                        {!emailListLoading && emailTemplatesList.length > 0 ? (
                          <>
                            <Select.Option
                              className="selectPopupOption mailTemplateOption"
                              value="all"
                            >
                              <Checkbox
                                checked={checkIdExistence(
                                  selectedEmails,
                                  emailTemplatesList
                                )}
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    setSelectedEmails(emailTemplatesList);
                                  } else {
                                    setSelectedEmails([]);
                                  }
                                }}
                              >
                                Select All
                              </Checkbox>
                            </Select.Option>
                          </>
                        ) : (
                          ""
                        )}

                        {emailTemplatesList.length > 0
                          ? emailTemplatesList.map((item, index) => {
                              return (
                                <Select.Option
                                  className="selectPopupOption mailTemplateOption"
                                  value={item?.value}
                                  key={index}
                                >
                                  <Checkbox
                                    checked={
                                      selectedEmails.find(
                                        (value) => value.value == item?.value
                                      )
                                        ? true
                                        : false
                                    }
                                    onChange={(event) => {
                                      if (event.target.checked) {
                                        if (
                                          !selectedEmails.find(
                                            (value) =>
                                              value.value == item?.value
                                          )
                                        ) {
                                          setSelectedEmails((prevItems) => [
                                            ...prevItems,
                                            {
                                              value: item?.value,
                                              label: item?.label,
                                            },
                                          ]);
                                        }
                                      } else {
                                        if (
                                          selectedEmails.find(
                                            (value) =>
                                              value.value == item?.value
                                          )
                                        ) {
                                          setSelectedEmails((prevItems) =>
                                            prevItems.filter(
                                              (value) =>
                                                value.value !== item?.value
                                            )
                                          );
                                        }
                                      }
                                    }}
                                  >
                                    <span className="selectTemplateMailtext">
                                      {item?.label}
                                    </span>
                                  </Checkbox>
                                </Select.Option>
                              );
                            })
                          : ""}

                        <Select.Option
                          value="add-new-temaplte"
                          className="selectPopupOption mailTemplateOption addNewOption"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g opacity="0.4">
                              <path
                                d="M5.33398 8H10.6673"
                                stroke="#F75F18"
                                stroke-width="1.2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M8 10.6663V5.33301"
                                stroke="#F75F18"
                                stroke-width="1.2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <path
                              d="M6.00065 14.6663H10.0007C13.334 14.6663 14.6673 13.333 14.6673 9.99967V5.99967C14.6673 2.66634 13.334 1.33301 10.0007 1.33301H6.00065C2.66732 1.33301 1.33398 2.66634 1.33398 5.99967V9.99967C1.33398 13.333 2.66732 14.6663 6.00065 14.6663Z"
                              stroke="#F75F18"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>{" "}
                          <span>Add new template</span>
                        </Select.Option>
                      </Select>
                      {submitStatus && addedEmails.length <= 0 ? (
                        <span className="validate_message ant-form-item ant-form-item-explain-error">
                          please select template!
                        </span>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                )}
              </Form>
              {/* ) : ( */}
              {/* <>
                  <div className="upgradePlanBtn">
                    <div className="add-button-container">
                      <h2>Upgrade Plan</h2>
                      <p>
                        For viewing advance warmup setting please upgrade your
                        plan
                      </p>
                      <button
                        onClick={() => {
                          navigate("/subscription/choose-plan");
                        }}
                      >
                        <span>Upgrade Your Plan</span>
                      </button>
                    </div>
                  </div>
                </> */}
              {/* )} */}

              <div className="weekdayContainer">
                <div>
                  <img src={weekdayImg} alt="" />
                </div>
                <div className="weekText">
                  <span>Don't send on weekends</span>
                  <span>
                    Send warmup emails from Monday to Friday only to mimic real
                    conversations.
                  </span>
                </div>
                <div className="swichContainer">
                  <span>|</span>
                  <Switch
                    onChange={(value) => {
                      setChangeWeek(value);
                    }}
                    value={changeWeek}
                    defaultValue={value?.skipWeekends}
                  />
                </div>
              </div>
            </TabPane>
          </Tabs>
        </div>
        {activeTab != "1" && (
          <div className="buttonContainer">
            <button onClick={handleSave}>Save</button>
            <button onClick={handleCancel}>Cancel</button>
          </div>
        )}

        {/* <div className="buttonContainer">
          <button onClick={settingForm.submit}>Save</button>
          <button onClick={handleCancel}>Cancel</button>
        </div> */}
      </Drawer>
    </div>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default WarmUpSetting;
