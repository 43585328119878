import React from "react";
import style from "./Subscription.module.css";
import { Card, Col, Row, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import FreePlanUsageSubscription from "./Free-Plan-Usage/Free-plan-usage";
import DeliverabilityTest from "./Deliverability-test/Deliverability-test";
import { useSelector } from "react-redux";
import { capitalizeFirstLetterWord } from "../../../config/CommonFuntion";

// DEFINE MAIN FUNCTION
const Subscription = () => {
  // DEFINE ALL STATES
  const navigate = useNavigate();
  const updatedUserdetails = useSelector((state) => state.auth.userDetails);
  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <Row gutter={[20]}>
        <Col span={24}>
          <Card className={style.mainCard}>
            <Row justify={"space-between"} align={"middle"}>
              <div className={style.cardTitle}>Plan Details</div>
              <button
                className={style.upgradeButton}
                size="large"
                onClick={() => {
                  navigate("/subscription/choose-plan");
                }}
              >
                Upgrade
              </button>
            </Row>
            <Divider />
            <div className={style.titleContainer}>
              <div>
               Free
                
                Plan
              </div>
              <span> · Active</span>
              {/* <label> · Free trial ended</label> */}
            </div>
            <div className={style.cardDescription}>
              Once your trial expires, your account will switch to the free
              plan. Upgrade to continue using Mail Optimal without restrictions.
            </div>
            <div className={style.dateWarn}>
              Free trial ends on 24 Mar, 2024 (7 days left)
            </div>
          </Card>
        </Col>
        <Row gutter={18} className={style.subsciptionContainer}>
          <FreePlanUsageSubscription></FreePlanUsageSubscription>
          <DeliverabilityTest></DeliverabilityTest>
        </Row>
      </Row>
    </>
  );
};
// DEFINE MAIN FUNCTION EXPORT
export default Subscription;
