import { API } from "../../config/api/Index";

// ALL TYPE WISE DATA RETURN
const initState = {
  subscriptionPlanListCounts: {
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
  subscriptionPlanList: undefined,
};

// DEFINE AUTH REDUCER FUNCTION
const subscriptionPlanReducer = (state = initState, action) => {
  switch (action.type) {
    case API.subscription.subscriptionPlanList:
      return {
        ...state,
        subscriptionPlanList: action.subscriptionPlanList,
        subscriptionPlanListCounts: action.subscriptionPlanListCounts,
      };
    default:
      return state;
  }
};

// EXPORT ALL FUNCTION
export default subscriptionPlanReducer;
