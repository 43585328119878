import React from "react";

const WarmUpMaintainSVGIcon = ({ status }) => {
  return (
    <svg
      width="185"
      height="81"
      viewBox="0 0 185 81"
      className="warm_up_mode_container_component_svg"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="-4.37114e-08" y1="80.5" x2="185" y2="80.5" stroke="#EAECF0" />
      <line
        x1="-2.18557e-08"
        y1="60.75"
        x2="185"
        y2="60.75"
        stroke="#EAECF0"
        strokeWidth="0.5"
        stroke-dasharray="2 2"
      />
      <line
        x1="-2.18557e-08"
        y1="40.75"
        x2="185"
        y2="40.75"
        stroke="#EAECF0"
        strokeWidth="0.5"
        stroke-dasharray="2 2"
      />
      <line
        x1="-2.18557e-08"
        y1="20.75"
        x2="185"
        y2="20.75"
        stroke="#EAECF0"
        strokeWidth="0.5"
        stroke-dasharray="2 2"
      />
      <line
        x1="-2.18557e-08"
        y1="0.750031"
        x2="185"
        y2="0.750014"
        stroke="#EAECF0"
        strokeWidth="0.5"
        stroke-dasharray="2 2"
      />
      <path
        d="M2 25.5C2 23.0147 4.01472 21 6.5 21C8.98528 21 11 23.0147 11 25.5V77C11 78.8856 11 79.8284 10.4142 80.4142C9.82843 81 8.88562 81 7 81H6C4.11438 81 3.17157 81 2.58579 80.4142C2 79.8284 2 78.8856 2 77V25.5Z"
        fill={`url(#paint0_linear_1833_${status ? "31645" : "30783"})`}
      />
      <path
        d="M17 31.5C17 29.0147 19.0147 27 21.5 27C23.9853 27 26 29.0147 26 31.5V77C26 78.8856 26 79.8284 25.4142 80.4142C24.8284 81 23.8856 81 22 81H21C19.1144 81 18.1716 81 17.5858 80.4142C17 79.8284 17 78.8856 17 77V31.5Z"
        fill={`url(#paint1_linear_1833_${status ? "31645" : "30783"})`}
      />
      <path
        d="M32 27.5C32 25.0147 34.0147 23 36.5 23C38.9853 23 41 25.0147 41 27.5V77C41 78.8856 41 79.8284 40.4142 80.4142C39.8284 81 38.8856 81 37 81H36C34.1144 81 33.1716 81 32.5858 80.4142C32 79.8284 32 78.8856 32 77V27.5Z"
        fill={`url(#paint2_linear_1833_${status ? "31645" : "30783"})`}
      />
      <path
        d="M47 25.5C47 23.0147 49.0147 21 51.5 21C53.9853 21 56 23.0147 56 25.5V77C56 78.8856 56 79.8284 55.4142 80.4142C54.8284 81 53.8856 81 52 81H51C49.1144 81 48.1716 81 47.5858 80.4142C47 79.8284 47 78.8856 47 77V25.5Z"
        fill={`url(#paint3_linear_1833_${status ? "31645" : "30783"})`}
      />
      <path
        d="M62 31.5C62 29.0147 64.0147 27 66.5 27C68.9853 27 71 29.0147 71 31.5V77C71 78.8856 71 79.8284 70.4142 80.4142C69.8284 81 68.8856 81 67 81H66C64.1144 81 63.1716 81 62.5858 80.4142C62 79.8284 62 78.8856 62 77V31.5Z"
        fill={`url(#paint4_linear_1833_${status ? "31645" : "30783"})`}
      />
      <path
        d="M77 29.5C77 27.0147 79.0147 25 81.5 25C83.9853 25 86 27.0147 86 29.5V77C86 78.8856 86 79.8284 85.4142 80.4142C84.8284 81 83.8856 81 82 81H81C79.1144 81 78.1716 81 77.5858 80.4142C77 79.8284 77 78.8856 77 77V29.5Z"
        fill={`url(#paint5_linear_1833_${status ? "31645" : "30783"})`}
      />
      <path
        d="M92 29.5C92 27.0147 94.0147 25 96.5 25C98.9853 25 101 27.0147 101 29.5V77C101 78.8856 101 79.8284 100.414 80.4142C99.8284 81 98.8856 81 97 81H96C94.1144 81 93.1716 81 92.5858 80.4142C92 79.8284 92 78.8856 92 77V29.5Z"
        fill={`url(#paint6_linear_1833_${status ? "31645" : "30783"})`}
      />
      <path
        d="M107 25.5C107 23.0147 109.015 21 111.5 21C113.985 21 116 23.0147 116 25.5V77C116 78.8856 116 79.8284 115.414 80.4142C114.828 81 113.886 81 112 81H111C109.114 81 108.172 81 107.586 80.4142C107 79.8284 107 78.8856 107 77V25.5Z"
        fill={`url(#paint7_linear_1833_${status ? "31645" : "30783"})`}
      />
      <path
        d="M122 27.5C122 25.0147 124.015 23 126.5 23C128.985 23 131 25.0147 131 27.5V77C131 78.8856 131 79.8284 130.414 80.4142C129.828 81 128.886 81 127 81H126C124.114 81 123.172 81 122.586 80.4142C122 79.8284 122 78.8856 122 77V27.5Z"
        fill={`url(#paint8_linear_1833_${status ? "31645" : "30783"})`}
      />
      <path
        d="M139 25.5C139 23.0147 141.015 21 143.5 21C145.985 21 148 23.0147 148 25.5V77C148 78.8856 148 79.8284 147.414 80.4142C146.828 81 145.886 81 144 81H143C141.114 81 140.172 81 139.586 80.4142C139 79.8284 139 78.8856 139 77V25.5Z"
        fill={`url(#paint9_linear_1833_${status ? "31645" : "30783"})`}
      />
      <path
        d="M156 25.5C156 23.0147 158.015 21 160.5 21C162.985 21 165 23.0147 165 25.5V77C165 78.8856 165 79.8284 164.414 80.4142C163.828 81 162.886 81 161 81H160C158.114 81 157.172 81 156.586 80.4142C156 79.8284 156 78.8856 156 77V25.5Z"
        fill={`url(#paint10_linear_1833_${status ? "31645" : "30783"})`}
      />
      <path
        d="M173 29.5C173 27.0147 175.015 25 177.5 25C179.985 25 182 27.0147 182 29.5V77C182 78.8856 182 79.8284 181.414 80.4142C180.828 81 179.886 81 178 81H177C175.114 81 174.172 81 173.586 80.4142C173 79.8284 173 78.8856 173 77V29.5Z"
        fill={`url(#paint11_linear_1833_${status ? "31645" : "30783"})`}
      />
      <defs>
        <linearGradient
          id={`paint0_linear_1833_${status ? "31645" : "30783"}`}
          x1="6"
          y1="-9"
          x2="6"
          y2="114.333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#FCAE75" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#E8450E" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_1833_${status ? "31645" : "30783"}`}
          x1="21"
          y1="0"
          x2="21"
          y2="111"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#FCAE75" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#E8450E" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint2_linear_1833_${status ? "31645" : "30783"}`}
          x1="36"
          y1="-6"
          x2="36"
          y2="113.222"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#FCAE75" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#E8450E" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint3_linear_1833_${status ? "31645" : "30783"}`}
          x1="51"
          y1="-9"
          x2="51"
          y2="114.333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#FCAE75" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#E8450E" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint4_linear_1833_${status ? "31645" : "30783"}`}
          x1="66"
          y1="0"
          x2="66"
          y2="111"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#FCAE75" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#E8450E" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint5_linear_1833_${status ? "31645" : "30783"}`}
          x1="81"
          y1="-3"
          x2="81"
          y2="112.111"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#FCAE75" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#E8450E" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint6_linear_1833_${status ? "31645" : "30783"}`}
          x1="96"
          y1="-3"
          x2="96"
          y2="112.111"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#FCAE75" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#E8450E" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint7_linear_1833_${status ? "31645" : "30783"}`}
          x1="111"
          y1="-9"
          x2="111"
          y2="114.333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#FCAE75" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#E8450E" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint8_linear_1833_${status ? "31645" : "30783"}`}
          x1="126"
          y1="-6"
          x2="126"
          y2="113.222"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#FCAE75" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#E8450E" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint9_linear_1833_${status ? "31645" : "30783"}`}
          x1="143"
          y1="-9"
          x2="143"
          y2="114.333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#FCAE75" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#E8450E" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint10_linear_1833_${status ? "31645" : "30783"}`}
          x1="160"
          y1="-9"
          x2="160"
          y2="114.333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#FCAE75" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#E8450E" : "#D1DAEF"} />
        </linearGradient>
        <linearGradient
          id={`paint11_linear_1833_${status ? "31645" : "30783"}`}
          x1="177"
          y1="-3"
          x2="177"
          y2="112.111"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={status ? "#FCAE75" : "#F9F9F9"} />
          <stop offset="1" stop-color={status ? "#E8450E" : "#D1DAEF"} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default WarmUpMaintainSVGIcon;
