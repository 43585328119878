import { API } from "../../config/api/Index";

// ALL TYPE WISE DATA RETURN
const initState = {
  templateListCounts: {
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
  templateList: undefined,
};

// DEFINE AUTH REDUCER FUNCTION
const TemplateReducer = (state = initState, action) => {
  switch (action.type) {
    case API.template.list:
      return {
        ...state,
        templateList: action.templateList,
        templateListCounts: action.templateListCounts,
      };
    default:
      return state;
  }
};

// EXPORT ALL FUNCTION
export default TemplateReducer;
