// DEFINE EXTERNAL IMPORT
import { Spin, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { subscriptionPlanList } from "../../../../redux/subscription-plan/action";
// DEFINE INTERNAL IMPORT
import MonthlySubscription from "./MonthlySubscriptuionPlan/MonthlySubscription";
import YearlySubscription from "./YearlySubscriptionPlan/YearlySubscription";
import styles from "./ChoosePlan.module.css";

// DEFINE MAIN FUNCTION
const ChoosePlan = () => {
  const dispatch = useDispatch();
  const [selectedPlanTypeVaue, setSelectedPlanTypeVaue] = useState("monthly");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedPlanTypeVaue == "monthly") {
      list();
    }
  }, [selectedPlanTypeVaue]);

  const list = async () => {
    setLoading(true);
    const response = await dispatch(subscriptionPlanList());
    if (response) {
      console.log(response);
    }
    setLoading(false);
  };

  // DEFINE ALL LISTS WITH REDUX
  let listOfPlan = useSelector(
    (state) => state.subscriptionPlan.subscriptionPlanList
  );
  const monthlyData = listOfPlan?.month;
  console.log("monthly data", monthlyData);
  const yearlyData = listOfPlan?.year;

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      {loading ? (
        <>
          <div className={styles.spinLoaderContainer}>
            <Spin className={styles.spinLoader} />
          </div>
        </>
      ) : (
        <div className={styles.choosePlanContaienr}>
          <Tabs
            defaultActiveKey="monthly"
            className={styles.choosePlanMainContaienrTabBtn}
            centered={true}
            onChange={(value) => {
              setSelectedPlanTypeVaue(value);
            }}
            destroyInactiveTabPane={false}
            items={[
              {
                label: (
                  <div className={styles.monthBtn}>
                    <p>Monthly</p>
                  </div>
                ),
                key: "monthly",
                children: <MonthlySubscription monthData={monthlyData} />,
              },
              {
                label: (
                  <div className={styles.yearBtn}>
                    <p>Yearly</p>
                    <button>Save 50%</button>
                  </div>
                ),
                key: "yearly",
                children: <YearlySubscription yearData={yearlyData} />,
              },
            ]}
          />
        </div>
      )}
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT

export default ChoosePlan;
