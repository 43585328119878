//DEFINE ALL EXTERNAL IMPORT
import React from "react";
import { Collapse } from "antd";
import { FaCheck, FaCheckCircle } from "react-icons/fa";
import { RxCrossCircled } from "react-icons/rx";
import { IoMdCheckmark } from "react-icons/io";
import { MdCheck } from "react-icons/md";

// DEFINE ALL INTERNAL IMPORT
import styles from "./deliverability.module.css";
import RoundedButtion from "../../../component/deliverabilityTest/RoundedButtion";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";

// DEFINE MAIN FUNCTION
const DomainSetupDetails = ({ deleverybityData }) => {

  const emailType = deleverybityData?.emailAccount?.type
  const checkStatus = (item) => {
    if (!deleverybityData) return;

    const resultMap = {
      MX: 'mxResult',
      rDNS: 'rdnsResult',
      DKIM: 'dkimResult',
      SPF: 'spfResult',
      DMARC: 'dmarcResult'
    };

    const result = deleverybityData[resultMap[item]];
    if (!result) return;

    const status = result.status;

    if (status === 'true' || (item === 'SPF' && status === true)) return 'true';
    if (status === 'missing') return 'missing';
    if (status === 'false' || status === 'error') return 'false';
  };

  const Lable = ({ name }) => {
    return (
      <>
        <div className={styles.DomainSetupDetailsLabel}>
          <h3>{name} </h3>
          <RoundedButtion
            className={checkStatus(name) == "true" ? "green" : "red"}
          >
            {checkStatus(name) == "true" ? (
              <MdCheck fontSize={"medium"} />
            ) : (
              <RxCross2 />
            )}
            {checkStatus(name) == "true"
              ? "Valid"
              : checkStatus(name) == "missing"
                ? "Missing"
                : "Invalid"}
          </RoundedButtion>
        </div>
      </>
    );
  };
  const renderRecordData = (name) => {
    if (name == "SPF") {
      return (
        <div>
          <p style={{ margin: "0px" }}>domain: {deleverybityData?.domain};</p>
          <p style={{ margin: "0px" }}>{deleverybityData?.spfResult?.data}</p>
        </div>
      );
    }
    if (name == "DKIM") {
      return (
        <div>
          {Object.entries(deleverybityData?.dkimResult?.data?.params || {}).map(
            ([key, value]) => {
              return <p style={{ margin: "0px" }}>{`${key} : ${value}`}</p>;
            }
          )}
        </div>
      );
    }
    if (name == "DMARC") {
      return (
        <div>
          <p style={{ margin: "0px" }}>domain: {deleverybityData?.domain};</p>
          <p style={{ margin: "0px" }}>
            record :{deleverybityData?.dmarcResult?.data}
          </p>
        </div>
      );
    }
    if (name == "rDNS") {
      return (
        <div>
          <p style={{ margin: "0px" }}>
            ip: {deleverybityData?.rdnsResult?.ip};
          </p>
          <p style={{ margin: "0px" }}>
            rDNS: {deleverybityData?.rdnsResult.data}
          </p>
        </div>
      );
    }
    if (name == "MX") {
      return (
        <div>
          {Array.isArray(deleverybityData?.mxResult?.data) == "true" ? deleverybityData?.mxResult?.data.map((item, index) => {
            return (
              <div key={index}>
                <p style={{ margin: "0px" }}>
                  ip: {item?.ip}; pri:{item?.priority}; host:{item?.exchange}
                </p>
              </div>
            );
          }) : '-'}
        </div>
      );
    }
  };
  // DEFINE CHILDREN COMPONENTS
  const Children = ({ name }) => {
    console.log("checkStatus(name)", checkStatus(name))
    console.log("name", name)
    // DEFINE RETURN OF CHILDREN
    if (checkStatus(name) != "missing") {
      return (
        <>
          <div>
            <div
              className={
                checkStatus(name) == "true"
                  ? styles.domainSetupChildrenContainer
                  : styles.domainSetupChildrenRedContainer
              }
            >
              <FaCheckCircle className={styles.checkIcons} />
              <div className={styles.textContainer}>
                <h5>
                  Your {name} record is{" "}
                  {checkStatus(name) == "true" ? "valid" : "invalid"}!
                </h5>
                {name == "DKIM" ? (
                  <p>

                    {checkStatus(name) == "true" ? <>
                      {name} record verifies that emails are sent from your email
                      account and not from a spoofed sender.
                      <span> Learn More</span>
                    </> : <>
                      {emailType == 'google' ? <>
                        Your DKIM is not set or is invalid. <Link to={"https://help.mailoptimal.com/en/article/13-how-to-set-up-dkim-for-gmail"} target="_blank">Follow this guide</Link> to set it up now.

                      </> : emailType == 'microsoft' ? <>
                        Your DKIM is not set or is invalid. <Link to={"https://help.mailoptimal.com/en/article/13-how-to-set-up-dkim-for-microsoft"} target="_blank">Follow this guide</Link> to set it up now.
                      </> : <>Your DKIM is not set or is invalid. <Link to={"https://help.mailoptimal.com/en/article/11-what-is-dkim-record-and-how-to-set-it-up"} target="_blank">
                        Refer to this guide</Link> to learn more.

                      </>}

                    </>}
                  </p>
                ) : name == 'DMARC' ? <>
                  {checkStatus(name) ? '' : <>
                    Your DMARC is not set or is invalid. <Link to={"https://help.mailoptimal.com/en/article/15-what-is-a-dmarc-record-how-to-set-it-up"} target="_blank">Follow this guide</Link> to set it up now.
                  </>}
                </> : name == "rDNS" ? (
                  <p>
                    {checkStatus(name) ? <>
                      {name} record helps prevent spam and phishing emails from
                      being sent using your email server.
                      <span> Learn More</span></> : <>
                      Your rDNS is not set or is invalid. <Link to={"https://help.mailoptimal.com/en/article/16-what-is-an-rdns-record-how-to-set-it-up"} target="_blank">Follow this guide</Link> to set it up now.

                    </>}

                  </p>
                ) : name == "MX" ? (
                  <p>
                    {checkStatus(name) == 'true' ? <>The {name} record points to the hostname of the mail server
                      that should receive incoming emails for that domain.
                      <span> Learn More</span></> : <>
                      {emailType == 'google' ? <>
                        Your MX record is not set or is invalid. <Link to={"https://help.mailoptimal.com/en/article/17-how-to-set-up-mx-records-for-a-gmail-google-workspace-account"} target="_blank">Follow this guide</Link> to set it up now.
                      </> : emailType == 'microsoft' ? <>
                        Your MX record is not set or is invalid. <Link to={"https://help.mailoptimal.com/en/article/18-how-to-set-up-mx-records-for-a-microsoft-office365-email-account"} target="_blank">Follow this guide</Link> to set it up now. </> : <>
                        Your MX record is not set or is invalid. <Link to={"https://help.mailoptimal.com/en/article/19-what-is-an-mx-record-how-to-set-it-up"} target="_blank">Refer to this guide</Link> to learn more</>}
                    </>}

                  </p>
                ) : name == 'SPF' ? (
                  <p>
                    {checkStatus(name)}
                    {checkStatus(name) == 'true' ? <>
                      {name} record prevents spammers from sending emails from
                      your domain.
                      <span> Learn More</span></> : <>
                      {emailType == 'google' ? <>Your SPF is not set or is invalid. <Link to={"https://help.mailoptimal.com/en/article/7-how-to-set-up-spf-record-for-gmail"} target="_blank">Follow this guide</Link> to set it up now. </> : emailType == 'microsoft' ? <>Your SPF is not set or is invalid. <Link to={"https://help.mailoptimal.com/en/article/12-how-to-set-up-spf-record-for-microsoft"} target="_blank">Follow this guide</Link> to set it up now.
                      </> : <>
                        Your SPF is not set or incorrectly set. <Link to={"https://help.mailoptimal.com/en/article/10-what-is-spf-record-and-how-to-set-it-up"} target="_blank">Refer to this guide</Link> to learn more.
                      </>}
                    </>}

                  </p>
                ) : ''}
              </div>
            </div>

            <p className={styles.detailsText}>Your domain's {name} record:</p>
            <div className={styles.detailsDescriptionContainer}>
              {renderRecordData(name)}
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            <div className={styles.domainSetupChildrenRedContainer}>
              <RxCrossCircled className={styles.checkIcons} />
              <div className={styles.textContainer}>
                <h5>
                  Your {name} record is {"Missing"}!
                </h5>
                {/* <p>
                  {name} record prevents spammers from sending emails from your
                  domain.
                  <span> Learn More</span>
                </p> */}
              </div>
            </div>

            {/* <p className={styles.detailsText}>Your domain's SPF record:</p>
            <div className={styles.detailsDescriptionContainer}>
              <p>domain: gmail.com;</p>
              <p> v=spf1 redirect=_spf.google.com.</p>
            </div> */}
          </div>
        </>
      );
    }
  };

  // DEFINE NESTED COLLAPSE  ITEMS OF DOMAIN SETUP
  const itemsNest = [
    {
      key: "1",
      label: <Lable name="SPF" />,
      children: <Children name="SPF" />,
    },
    {
      key: "2",
      label: <Lable name="DKIM" />,
      children: <Children name="DKIM" />,
    },
    {
      key: "3",
      label: <Lable name="DMARC" />,
      children: <Children name="DMARC" />,
    },
    {
      key: "4",
      label: <Lable name="rDNS" />,
      children: <Children name="rDNS" />,
    },
    {
      key: "5",
      label: <Lable name="MX" />,
      children: <Children name="MX" />,
    },
  ];

  // DEFINE ALL STATE HERE

  // DEFINE MAIN FUNCTION RETURN
  return <Collapse accordion items={itemsNest} expandIconPosition="right" />;
};

// DEFINE MAIN FUNCTION EXPORTS
export default DomainSetupDetails;
