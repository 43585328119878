import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";

// DEFINE ALL INTERNAL IMPORT
import EmailSetUpScore from "../deliverability/EmailSetUpScore";
import InboxPlacementScore from "../deliverability/InboxPlacementScore";
import styles from "../../admin/deliverability/deliverability.module.css";
import DeliverabilityDetails from "../deliverability/DeliverabilityDetails";
import SubscriptionButtion from "../../../component/subscription/SubscriptionButtion";
import greenCheck from "../../../assets/deleverability/greenCheck.png";

import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import GoogleSVGIcon from "../../../assets/dashboard/GoogleSVGIcon";
import SMTPSVGIcon from "../../../assets/dashboard/SMTPSVGIcon";
import MicrisoftSVGIcon from "../../../assets/dashboard/MicrisoftSVGIcon";
import clockIcon from "../../../assets/svg/clock.svg";

function DeliverabilityTestLoader({ accountInfo, submitTime }) {
  return (
    <div>
      <Row justify={"space-between"} className={styles.userInfo}>
        <Col>
          <div className={styles.username}>
            <h4>{accountInfo && accountInfo?.sender_username}</h4>
            <div>
              {accountInfo && accountInfo?.type == "google" ? (
                <GoogleSVGIcon className={styles.EmailProviderIcons} />
              ) : accountInfo && accountInfo?.type == "smtp_imap" ? (
                <SMTPSVGIcon className={styles.EmailProviderIcons} />
              ) : (
                <MicrisoftSVGIcon className={styles.EmailProviderIcons} />
              )}
            </div>
          </div>

          <div className={styles.userMain}>
            <a href={`mailto:${accountInfo?.sender_email}`}>
              {accountInfo && accountInfo?.sender_email}
            </a>
            <img src={greenCheck} alt="" />
          </div>
        </Col>
        <Col>
          <div className={styles.progress_TimeInfo}>
            <p>
              <strong>Created:</strong> {submitTime}
            </p>
            <div className={styles.progress_Time}>
              <img src={clockIcon} alt="" />
              <p>
                <strong>In progress...</strong>
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={{ xs: 12, sm: 24 }}>
        <EmailSetUpScore score={0} />
        <InboxPlacementScore score={0} />
      </Row>
      <Row className={styles.collepseMainContainer}>
        <DeliverabilityDetails deleverybityData={null} />
      </Row>
    </div>
  );
}

export default DeliverabilityTestLoader;
