import React, { useState } from "react";
import { Layout, Spin } from "antd";
import TopHeader from "./TopHeader";
import LeftMenu from "./LeftMenu";
import PrivateRoute from "../../routes/PrivateRoute";
import "./adminStyle.css";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resendUserVarifyLink } from "../../redux/authentication/action";
import mailSVG from "../../assets/svg/smtpEmail.svg";
import { getItem } from "../../utility/localStorageControl";

// DEFINE MAIN FUNCTION
const DashBoardLayout = ({ width }) => {
  // DEFINE ALL STATES
  const { Content } = Layout;
  const dispatch = useDispatch();
  const location = useLocation();
  const [resendLinkLoading, setResendLinkLoading] = useState(false);

  // DEFINE REDUX IN GET DATA
  let showLayoutStatus = location.pathname.includes("connect");
  let authStatus = useSelector((state) => state.auth.userDetails);

  // DEFINE FUNCTION FOR RESEND VERIFY LINK API CALL
  const onResendButtonClick = async () => {
    setResendLinkLoading(true);
    let response = await dispatch(
      resendUserVarifyLink({ status: true, token: getItem("access_token") })
    );
    if (response) {
      setResendLinkLoading(false);
      // window.open("https://mail.google.com/", "_blank");
    } else {
      setResendLinkLoading(false);
    }
  };
  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      {location.pathname == "/verify-account" ||
      location.pathname == "/reset-password" ? (
        <div className="authContainer">
          <div
            style={{
              height:
                location.pathname == "/reset-password" ? "" : "min-content",
            }}
            className="authComponent"
          >
            <PrivateRoute width={width} />
          </div>
        </div>
      ) : (
        <div className="authContainer-component">
          {showLayoutStatus ? (
            ""
          ) : authStatus?.status == "pending" ? (
            <div className="varification-component">
              <div className="varification-component-description">
                <div
                  onClick={() => {
                    window.open("https://mail.google.com/", "_blank");
                  }}
                >
                  <img src={mailSVG} />
                </div>
                <label>
                  Check your registered email and verify your profile by
                  clicking the link. If you haven’t received it, check your spam
                  folder
                </label>
              </div>
              <div
                className="button"
                onClick={() => {
                  if (!resendLinkLoading) {
                    onResendButtonClick();
                  }
                }}
              >
                {resendLinkLoading ? <Spin /> : "Resend Verification Mail"}
              </div>
            </div>
          ) : (
            ""
          )}
          <Layout
            className="dashboard-layout"
            style={{
              height: showLayoutStatus
                ? "100vh"
                : authStatus?.status == "pending"
                ? "calc(100vh - 70px)"
                : "100vh",
            }}
          >
            <LeftMenu collapsed={true} />
            <Layout>
              <TopHeader />
              <Content
                className="dashboard-layout-content"
                style={{
                  height: authStatus?.status == "pending" ? "76vh" : "85vh",
                }}
              >
                <PrivateRoute width={width} />
              </Content>
            </Layout>
          </Layout>
        </div>
      )}
    </>
  );
};
// DEFINE MAIN FUNCTION EXPORT
export default DashBoardLayout;
