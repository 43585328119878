// DEFINE ALL EXTERNAL IMPORT
import React from "react";
import { Col, Row } from "antd";
import { FaCheckCircle } from "react-icons/fa";

// DEFINE ALL INTERNAL IMPORT
import googleIcons from "../../../assets/deleverability/Google.png";
import microsoftIcons from "../../../assets/deleverability/microsoft.png";
import yahooIcons from "../../../assets/deleverability/yahoo.png";
import zohoIcons from "../../../assets/deleverability/zoho.png";
import styles from "./deliverability.module.css";
import { EmailBoxData, dataInbox } from "../../../data";
import emailContactIcons from "../../../assets/deleverability/sms.png";
import { truncateString } from "../../../config/CommonFuntion";

// DEFINE MAIN FUNCTION
const InboxPlacementComponents = ({ deleverybityData }) => {
  // DEFINE ALL STATES
  console.log("deleverybityData", deleverybityData)
  // DEFINE MAIN FUNCTIONS RETURN
  let gmailReceivers = deleverybityData?.receiverInfos?.filter(
    (val) => val.receiver_type == "gmail"
  );
  let microsoftReceivers = deleverybityData?.receiverInfos?.filter(
    (val) => val.receiver_type == "microsoft"
  );
  let smtpReceivers = deleverybityData?.receiverInfos?.filter(
    (val) => val.receiver_type == "smtp_imap"
  );
  let zohoReceivers = deleverybityData?.receiverInfos?.filter(
    (val) => val.receiver_type == "zoho"
  );
  let yahooReceivers = deleverybityData?.receiverInfos?.filter(
    (val) => val.receiver_type == "yahoo"
  );
  let otherReceivers = deleverybityData?.receiverInfos?.filter(
    (val) => val.receiver_type == "other"
  );
  let gmailPercentage =
    (gmailReceivers.filter((val) => val.isMailInSpam == false)?.length * 100) /
    gmailReceivers?.length;
  let microsoftPercentage =
    (microsoftReceivers.filter((val) => val.isMailInSpam == false)?.length *
      100) /
    microsoftReceivers?.length;
  let smtpPercentage =
    (smtpReceivers.filter((val) => val.isMailInSpam == false)?.length * 100) /
    smtpReceivers?.length;
  let zohoPercentage =
    (zohoReceivers.filter((val) => val.isMailInSpam == false)?.length * 100) /
    zohoReceivers?.length;
  let yahooPercentage =
    (yahooReceivers.filter((val) => val.isMailInSpam == false)?.length * 100) /
    yahooReceivers?.length;
  let otherPercentage =
    (otherReceivers.filter((val) => val.isMailInSpam == false)?.length * 100) /
    otherReceivers?.length;
  return (
    <div className={styles.inboxPlacementMainContainer}>
      {/* {deleverybityData?.inboxPlacementScore != 100 && (
        <div className={styles.headerContainer}>
          <FaCheckCircle className={styles.checkCircleIcons} />
          <div>
            <h5>Some emails don't reach the inbox</h5>
            <p>
              Some of your emails are landing in spam. Activate warmup to
              increase the inbox placement score.
            </p>
          </div>
        </div>
      )} */}

      <Row className={styles.boxContainer} gutter={12}>
        {EmailBoxData &&
          EmailBoxData.map((item) => {
            if (item?.name == "Inbox") {
              return (
                <Col xs={12} sm={8} key={item?.id}>
                  <div className={styles.boxDetailsContainer}>
                    <p>{item?.name}</p>
                    <h3>{deleverybityData?.inboxPlacementScore}%</h3>
                  </div>
                </Col>
              );
            }
            // if (item?.name == "Categories") {
            //   return (
            //     <Col xs={12} sm={6} key={item?.id}>
            //       <div className={styles.boxDetailsContainer}>
            //         <p>{item?.name}</p>
            //         <h3>{0}%</h3>
            //       </div>
            //     </Col>
            //   );
            // }
            if (item?.name == "Spam") {
              return (
                <Col xs={12} sm={8} key={item?.id}>
                  <div className={styles.boxDetailsContainer}>
                    <p>{item?.name}</p>
                    <h3>{100 - deleverybityData?.inboxPlacementScore}%</h3>
                  </div>
                </Col>
              );
            }
            if (item?.name == "Not delivered") {
              let totalSend = deleverybityData?.receiverInfos?.length;
              let findNotDelivered = deleverybityData?.receiverInfos?.filter(
                (value) => value?.response?.error == true
              )?.length;
              let notDeliveredPercentage = (findNotDelivered * 100) / totalSend;
              return (
                <Col xs={12} sm={8} key={item?.id}>
                  <div className={styles.boxDetailsContainer}>
                    <p>{item?.name}</p>
                    <h3>{notDeliveredPercentage}%</h3>
                  </div>
                </Col>
              );
            }
          })}
      </Row>
      <div className={styles.providerContainer}>
        <div className={styles.providerHeader}>
          <div className={styles.headerLogo}>
            <img src={googleIcons} alt="sms edit icons" />
          </div>
          <div className={styles.providerHeaderText}>
            <h3>Gmail</h3>
            <button
              className={
                gmailPercentage == 100 ? `${styles.green}` : `${styles.red}`
              }
            >
              <span>
                Inbox: {gmailPercentage ? Math.round(gmailPercentage) : 0}%
              </span>
            </button>
          </div>
        </div>
        <div className={styles.items}>
          <Row gutter={[12, 12]}>
            {gmailReceivers &&
              gmailReceivers.map((item) => {
                return (
                  <Col xs={12} sm={6} key={item?.receiver_id}>
                    <div className={styles.emailBoxContaienr}>

                      <a href="#">{item?.receiver_email.length > 40 ? truncateString(item?.receiver_email) : item?.receiver_email}</a>
                      {/* {item?.email_type
                          ? `${
                              item?.email_type == "work_email"
                                ? "(Work)"
                                : "(Free)"
                            }`
                          : null} */}

                      <button
                        className={
                          !item?.isMailInSpam
                            ? `${styles.green}`
                            : `${styles.red}`
                        }
                      >
                        <span>{item?.isMailInSpam ? "Spam" : "Inbox"}</span>
                      </button>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>
      <div className={styles.providerContainer}>
        <div className={styles.providerHeader}>
          <div className={styles.headerLogo}>
            <img src={microsoftIcons} alt="sms edit icons" />
          </div>
          <div className={styles.providerHeaderText}>
            <h3>Microsoft</h3>
            <button
              className={
                microsoftPercentage == 100 ? `${styles.green}` : `${styles.red}`
              }
            >
              <span>
                Inbox:{" "}
                {microsoftPercentage ? Math.round(microsoftPercentage) : 0}%
              </span>
            </button>
          </div>
        </div>
        <div className={styles.items}>
          <Row gutter={[12, 12]}>
            {microsoftReceivers &&
              microsoftReceivers.map((item) => {
                return (
                  <Col xs={12} sm={6} key={item?.receiver_id}>
                    <div className={styles.emailBoxContaienr}>
                      <a href="#">{item?.receiver_email.length > 40 ? truncateString(item?.receiver_email) : item?.receiver_email}</a>
                      <button
                        className={
                          !item?.isMailInSpam
                            ? `${styles.green}`
                            : `${styles.red}`
                        }
                      >
                        <span>{item?.isMailInSpam ? "Spam" : "Inbox"}</span>
                      </button>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>


      <div className={styles.providerContainer}>
        <div className={styles.providerHeader}>
          <div className={styles.headerLogo}>
            <img src={zohoIcons} alt="sms edit icons" width={26} height={24} />
          </div>
          <div className={styles.providerHeaderText}>
            <h3>Zoho</h3>
            <button
              className={
                zohoPercentage == 100 ? `${styles.green}` : `${styles.red}`
              }
            >
              <span>
                Inbox: {zohoPercentage ? Math.round(zohoPercentage) : 0}%
              </span>
            </button>
          </div>
        </div>
        <div className={styles.items}>
          <Row gutter={[12, 12]}>
            {zohoReceivers &&
              zohoReceivers.map((item) => {
                return (
                  <Col xs={12} sm={6} key={item?.receiver_id}>
                    <div className={styles.emailBoxContaienr}>
                      <a href="#">{item?.receiver_email.length > 40 ? truncateString(item?.receiver_email) : item?.receiver_email}</a>
                      <button
                        className={
                          !item?.isMailInSpam
                            ? `${styles.green}`
                            : `${styles.red}`
                        }
                      >
                        <span>{item?.isMailInSpam ? "Spam" : "Inbox"}</span>
                      </button>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>
      <div className={styles.providerContainer}>
        <div className={styles.providerHeader}>
          <div className={styles.headerLogo}>
            <img src={yahooIcons} alt="sms edit icons" width={24} height={24} />
          </div>
          <div className={styles.providerHeaderText}>
            <h3>Yahoo</h3>
            <button
              className={
                yahooPercentage == 100 ? `${styles.green}` : `${styles.red}`
              }
            >
              <span>
                Inbox: {yahooPercentage ? Math.round(yahooPercentage) : 0}%
              </span>
            </button>
          </div>
        </div>
        <div className={styles.items}>
          <Row gutter={[12, 12]}>
            {yahooReceivers &&
              yahooReceivers.map((item) => {
                return (
                  <Col xs={12} sm={6} key={item?.receiver_id}>
                    <div className={styles.emailBoxContaienr}>
                      <a href="#">{item?.receiver_email.length > 40 ? truncateString(item?.receiver_email) : item?.receiver_email}</a>
                      <button
                        className={
                          !item?.isMailInSpam
                            ? `${styles.green}`
                            : `${styles.red}`
                        }
                      >
                        <span>{item?.isMailInSpam ? "Spam" : "Inbox"}</span>
                      </button>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>
      <div className={styles.providerContainer}>
        <div className={styles.providerHeader}>
          <div className={styles.headerLogo}>
            <img src={emailContactIcons} width={24} height={24} alt="sms edit icons" />
          </div>
          <div className={styles.providerHeaderText}>
            <h3>Other</h3>
            <button
              className={
                otherPercentage == 100 ? `${styles.green}` : `${styles.red}`
              }
            >
              <span>
                Inbox: {otherPercentage ? Math.round(otherPercentage) : 0}%
              </span>
            </button>
          </div>
        </div>
        <div className={styles.items}>
          <Row gutter={[12, 12]}>
            {otherReceivers &&
              otherReceivers.map((item) => {
                return (
                  <Col xs={12} sm={6} key={item?.receiver_id}>
                    <div className={styles.emailBoxContaienr}>
                      <a href="#">{item?.receiver_email.length > 40 ? truncateString(item?.receiver_email) : item?.receiver_email}</a>
                      <button
                        className={
                          !item?.isMailInSpam
                            ? `${styles.green}`
                            : `${styles.red}`
                        }
                      >
                        <span>{item?.isMailInSpam ? "Spam" : "Inbox"}</span>
                      </button>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>
    </div >
  );
};

// DEFINE MAIN FUNCTIONS EXPORT
export default InboxPlacementComponents;
