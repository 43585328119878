import React from "react";

const FailSVGIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 11C8.75 11 11 8.75 11 6C11 3.25 8.75 1 6 1C3.25 1 1 3.25 1 6C1 8.75 3.25 11 6 11Z"
        stroke="#F04438"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 4V6.5"
        stroke="#F04438"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99805 8H6.00254"
        stroke="#F04438"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FailSVGIcon;
