import React, { useEffect, useState } from "react";
import style from "./ConnectSMTP.module.css";
import {
  Form,
  Input,
  Spin,
  Checkbox,
  Row,
  Col,
  Flex,
  Radio,
  notification,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  connectAccount,
  editConnectAccount,
  testIMAP,
  testSMTP,
  warmupAccountSetting,
} from "../../../redux/connect-account/action";
import successSVG from "../../../assets/svg/success.svg";
import failSVG from "../../../assets/svg/fail.svg";
import rightIcon from "../../../assets/Email/notification-right.png";
import { checkValuesExistOnObject } from "../../../config/CommonFuntion";
import { getItem } from "../../../utility/localStorageControl";

// DEFINE MAIN FUNCTION
const ConnectSMTP = () => {
  // DEFINE ALL STATES
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formValidateStatus, setFormValidateStatus] = useState(false);
  const [sendSelectEncryption, setSendsendSelectEncryption] = useState("ssl");
  const [reciveSelectEncryption, setReciveSelectEncryption] = useState("ssl");
  const [showSendUserNameField, setShowSendUserNameField] = useState(false);
  const [showReciveUserNameField, setShowReciveUserNameField] = useState(false);
  const [SMTPConnectValidation, setSMTPConnectValidation] = useState(false);
  const [IMAPConnectValidation, setIMAPConnectValidation] = useState(false);
  const [testSMTPLoading, setTestSMTPLoading] = useState(false);
  const [testSMTPValidation, setTestSMTPValidation] = useState("");
  const [testIMAPValidation, setTestIMAPValidation] = useState("");
  const [testIMAPLoading, setTestIMAPLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchWarmupSettings();
    }
  }, [id]);

  const fetchWarmupSettings = async () => {
    try {
      let response = await dispatch(warmupAccountSetting(id));
      if (response) {
        console.log("response", response);
        form.setFieldValue("sender_username", response.sender_username);
        form.setFieldValue("sender_password", response.sender_password);
        form.setFieldValue("sender_email", response.sender_email);
        form.setFieldValue("receiver_host", response.receiver_host);
        form.setFieldValue("sender_host", response.sender_host);
        form.setFieldValue("receiver_email", response.receiver_email);
        form.setFieldValue("receiver_password", response.receiver_password);
        form.setFieldValue(
          "use_different_sender_name",
          response.sender_different_username
        );
        setSendsendSelectEncryption(
          response.sender_encryption_type.toLowerCase()
        );
        setReciveSelectEncryption(
          response.receiver_encryption_type.toLowerCase()
        );
        onCheckFormChangeValue();
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  // DEFINE FUNCTION FOR FORM SUBMIT
  const onFinish = async (values) => {
    if (id) {
      setLoading(true);
      let payload = {
        ...values,
        type: "smtp_imap",
        use_different_sender_name: values.sender_different_username
          ? true
          : false,
        sender_encryption_type: sendSelectEncryption.toUpperCase(),
        receiver_encryption_type: reciveSelectEncryption.toUpperCase(),
      };
      let response = await dispatch(editConnectAccount(payload, id));
      if (response) {
        navigate("/");
        setLoading(false);
        notification.success({
          message: (
            <span className="notificationText">
              Congratulations. Your email account is updated successfully.
            </span>
          ),
          description: (
            <span className="startBorder" style={{ height: "88px" }}></span>
          ),
          icon: <img src={rightIcon} alt="right-icon" width={20} height={20} />,
          className: "custom-notification",
          duration: 3,
          style: { width: "362px", height: "88px" },
        });
      } else {
        setLoading(false);
      }
    } else {
      setLoading(true);
      let payload = {
        ...values,
        type: "smtp_imap",
        use_different_sender_name: values.sender_different_username
          ? true
          : false,
        sender_encryption_type: sendSelectEncryption.toUpperCase(),
        receiver_encryption_type: reciveSelectEncryption.toUpperCase(),
      };
      let response = await dispatch(connectAccount(payload));
      if (response) {
        navigate("/");
        setLoading(false);
        notification.success({
          message: (
            <span className="notificationText">
              Congratulations. Your email account is connected successfully.
            </span>
          ),
          description: (
            <span className="startBorder" style={{ height: "88px" }}></span>
          ),
          icon: <img src={rightIcon} alt="right-icon" width={20} height={20} />,
          className: "custom-notification",
          duration: 3,
          style: { width: "362px", height: "88px" },
        });
      } else {
        setLoading(false);
      }
    }
  };

  // DEFINE FUNCTION FOR SMTP TEST FUNCTION
  const onClickSMTPTest = async () => {
    setTestSMTPLoading(true);
    let payload = {
      type: "smtp",
      sender_email: form.getFieldsValue()?.sender_email,
      sender_username: form.getFieldsValue()?.sender_username,
      sender_password: form.getFieldsValue()?.sender_password,
      sender_host: form.getFieldsValue()?.sender_host,
      sender_port: form.getFieldsValue()?.sender_port,
      sender_encryption_type: sendSelectEncryption.toUpperCase(),
      sender_different_username:
        form.getFieldsValue()?.sender_different_username,
    };
    let response = await dispatch(testSMTP(payload));
    if (response) {
      setTestSMTPValidation("succcess");
      setTestSMTPLoading(false);
    } else {
      setTestSMTPValidation("fail");
      setTestSMTPLoading(false);
    }
  };

  // DEFINE FUNCTION FOR IMAP TEST FUNCTION
  const onClickIMAPTest = async () => {
    setTestIMAPLoading(true);
    let payload = {
      type: "smtp",
      receiver_different_username:
        form.getFieldsValue()?.receiver_different_username,
      receiver_email: form.getFieldsValue()?.receiver_email,
      receiver_password: form.getFieldsValue()?.receiver_password,
      receiver_host: form.getFieldsValue()?.receiver_host,
      receiver_port: form.getFieldsValue()?.receiver_port,
      receiver_encryption_type: reciveSelectEncryption.toUpperCase(),
    };
    let response = await dispatch(testIMAP(payload));
    if (response) {
      setTestIMAPLoading(false);
      setTestIMAPValidation("succcess");
    } else {
      setTestIMAPValidation("fail");
      setTestIMAPLoading(false);
    }
  };

  // DEFINE FUNCTION FOR FORM ONCHANGE VALUES VALIDATIONS CHECK
  const onCheckFormChangeValue = () => {
    setTestIMAPValidation("");
    setTestSMTPValidation("");

    if (checkValuesExistOnObject(form.getFieldsValue(), "sender")) {
      setSMTPConnectValidation(true);
    } else {
      setSMTPConnectValidation(false);
    }

    if (checkValuesExistOnObject(form.getFieldsValue(), "receiver")) {
      setIMAPConnectValidation(true);
    } else {
      setIMAPConnectValidation(false);
    }

    const validationErrors = form.getFieldsError();
    const validationValues = Object.values(form.getFieldsValue());
    if (
      !validationValues.includes(undefined) &&
      !validationValues.includes("") &&
      validationErrors.find((err) => err.name == "receiver_email").errors
        .length == 0 &&
      validationErrors.find((err) => err.name == "sender_email").errors
        .length == 0
    ) {
      setFormValidateStatus(true);
    } else {
      setFormValidateStatus(false);
    }
  };

  // RETURN MAIN FUNCTION
  return (
    <>
      <div className={style.googleComponent}>
        <div className={style.formComponent}>
          <label className={style.headings}>
            <span> SMTP Details</span> - <span>Sending Emails</span>
          </label>
          <Form
            layout="vertical"
            name="basic"
            size="large"
            requiredMark={false}
            onFinish={(value) => {
              if (!loading) {
                onFinish(value);
              }
            }}
            form={form}
            onValuesChange={(value) => {
              onCheckFormChangeValue(value);
            }}
            autoComplete="off"
          >
            <Form.Item
              label={"Sender Name"}
              name="sender_username"
              rules={[
                {
                  required: true,
                  message: "Please enter name!",
                },
              ]}
            >
              <Input placeholder="Enter Name" />
            </Form.Item>

            {showSendUserNameField ? (
              <Form.Item
                className="connect-email-google-form"
                label={
                  <div className={style.checkBoxFieldLabel}>
                    <span>Username</span>
                    <label className={style.title}>
                      <Checkbox
                        checked={showSendUserNameField}
                        onChange={(e) => {
                          setShowSendUserNameField(e.target.checked);
                        }}
                      >
                        Use different username
                      </Checkbox>
                    </label>
                  </div>
                }
                name="sender_different_username"
                rules={[
                  {
                    required: true,
                    message: "Please enter user name!",
                  },
                ]}
              >
                <Input placeholder="Enter username" />
              </Form.Item>
            ) : (
              ""
            )}

            <Form.Item
              className="connect-email-google-form"
              label={
                <div className={style.checkBoxFieldLabel}>
                  <span>Email Address</span>
                  {!showSendUserNameField ? (
                    <label className={style.title}>
                      <Checkbox
                        checked={showSendUserNameField}
                        onChange={(e) => {
                          setShowSendUserNameField(e.target.checked);
                          if (
                            !form.getFieldValue("sender_different_username")
                          ) {
                            setFormValidateStatus(false);
                          }
                        }}
                      >
                        Use different username
                      </Checkbox>
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              }
              name="sender_email"
              rules={[
                {
                  required: true,
                  message: "Please enter your email!",
                },
                {
                  type: "email",
                  message: "Please enter valid email!",
                },
              ]}
            >
              <Input placeholder="Enter Email" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="sender_password"
              rules={[
                {
                  required: true,
                  message: "Please enter your password!",
                },
              ]}
            >
              <Input.Password placeholder="Enter Password" />
            </Form.Item>
            <Row gutter={16}>
              <Col span={16}>
                {" "}
                <Form.Item
                  label="SMTP Host"
                  name="sender_host"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your SMTP Host!",
                    },
                  ]}
                >
                  <Input placeholder="Enter SMTP Host" />
                </Form.Item>
              </Col>
              <Col span={8}>
                {" "}
                <Form.Item
                  label="SMTP Port"
                  name="sender_port"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your SMTP Port!",
                    },
                  ]}
                  initialValue={465}
                >
                  <Input
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^\d]/g, "");
                    }}
                    onKeyDown={(event) => {
                      if (
                        event.key == "." ||
                        event.key === "-" ||
                        event.key == "e"
                      ) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="Enter SMTP Port"
                  />
                </Form.Item>
              </Col>{" "}
            </Row>
            <Form.Item label="Encryption" className={style.encryptionFormItem}>
              <Flex vertical gap="middle" className={style.smtpButtons}>
                <Radio.Group
                  defaultValue={"ssl"}
                  onChange={(e) => {
                    setSendsendSelectEncryption(e.target.value);
                  }}
                  value={sendSelectEncryption}
                >
                  <span
                    className={`${style.button} ${sendSelectEncryption == "ssl" ? style.selected : ""
                      }`}
                    onClick={() => {
                      setSendsendSelectEncryption("ssl");
                    }}
                  >
                    {" "}
                    <Radio value={"ssl"}>SSL</Radio>
                  </span>{" "}
                  <span
                    className={`${style.button} ${sendSelectEncryption == "tsl" ? style.selected : ""
                      }`}
                    onClick={() => {
                      setSendsendSelectEncryption("tsl");
                    }}
                  >
                    {" "}
                    <Radio value={"tsl"}>TLS</Radio>
                  </span>{" "}
                  <span
                    className={`${style.button} ${sendSelectEncryption == "none" ? style.selected : ""
                      }`}
                    onClick={() => {
                      setSendsendSelectEncryption("");
                    }}
                  >
                    {" "}
                    <Radio value={"none"}>None</Radio>
                  </span>
                </Radio.Group>
              </Flex>
            </Form.Item>
            <div className={style.middleLine}></div>
            <label className={style.headings}>
              <span>IMAP Details</span> - <span>Receiving Emails</span>
            </label>
            <Form.Item
              className="connect-email-google-form"
              label={
                <div className={style.checkBoxFieldLabel}>
                  <span>Email Address</span>
                  <label className={`${style.title} ${style.title_user_name}`}>
                    <Checkbox
                      checked={showReciveUserNameField}
                      onChange={(e) => {
                        setShowReciveUserNameField(e.target.checked);
                        form.setFieldsValue({
                          receiver_email: form.getFieldsValue()?.sender_email,
                          receiver_different_username:
                            form.getFieldsValue()?.sender_username,
                          receiver_password:
                            form.getFieldsValue()?.sender_password,
                        });
                        if (
                          !form.getFieldValue("receiver_different_username")
                        ) {
                          setFormValidateStatus(false);
                        }
                      }}
                    >
                      Use the same username and password from SMTP
                    </Checkbox>
                  </label>
                </div>
              }
              name="receiver_email"
              rules={[
                {
                  type: "email",
                  message: "Please enter valid email!",
                },
                {
                  required: true,
                  message: "Please enter your user name!",
                },
              ]}
            >
              <Input placeholder="Enter Email" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="receiver_password"
              rules={[
                {
                  required: true,
                  message: "Please enter your password!",
                },
              ]}
            >
              <Input.Password placeholder="Enter Password" />
            </Form.Item>

            <Row gutter={16}>
              <Col span={16}>
                {" "}
                <Form.Item
                  label="IMAP Host"
                  name="receiver_host"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your IMAP Host!",
                    },
                  ]}
                >
                  <Input placeholder="Enter IMAP Host" />
                </Form.Item>
              </Col>
              <Col span={8}>
                {" "}
                <Form.Item
                  label="IMAP Port"
                  name="receiver_port"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your IMAP Port!",
                    },
                  ]}
                  initialValue={993}
                >
                  <Input
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^\d]/g, "");
                    }}
                    onKeyDown={(event) => {
                      if (
                        event.key == "." ||
                        event.key === "-" ||
                        event.key == "e"
                      ) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="Enter IMAP Port"
                  />
                </Form.Item>
              </Col>{" "}
            </Row>

            <Form.Item label="Encryption" className={style.encryptionFormItem}>
              <Flex vertical gap="middle" className={style.smtpButtons}>
                <Radio.Group
                  defaultValue={"ssl"}
                  onChange={(e) => {
                    setReciveSelectEncryption(e.target.value);
                  }}
                  value={reciveSelectEncryption}
                >
                  <span
                    className={`${style.button} ${reciveSelectEncryption == "ssl" ? style.selected : ""
                      }`}
                    onClick={() => {
                      setReciveSelectEncryption("ssl");
                    }}
                  >
                    {" "}
                    <Radio value={"ssl"}>SSL</Radio>
                  </span>{" "}
                  <span
                    className={`${style.button} ${reciveSelectEncryption == "tsl" ? style.selected : ""
                      }`}
                    onClick={() => {
                      setReciveSelectEncryption("tsl");
                    }}
                  >
                    {" "}
                    <Radio value={"tsl"}>TTL</Radio>
                  </span>{" "}
                  <span
                    className={`${style.button} ${reciveSelectEncryption == "none" ? style.selected : ""
                      }`}
                    onClick={() => {
                      setReciveSelectEncryption("");
                    }}
                  >
                    {" "}
                    <Radio value={"none"}>None</Radio>
                  </span>
                </Radio.Group>
              </Flex>
            </Form.Item>
          </Form>
        </div>

        <div className={style.googleInfoComponent}>
          <div
            className={`${style.googleInfo} ${testSMTPValidation == "fail" || testIMAPValidation == "fail"
                ? style.failInstractions
                : ""
              }`}
          >
            <span>
              Instructions to connect your email account through the SMTP Method
            </span>{" "}
            <label>
              1. Make sure SMTP & IMAP are enabled on your email account.
            </label>{" "}
            <label>
              2. If you’re using a regular password, ensure that 2FA
              (Multi-factor authentication) is disabled on your email account.
            </label>{" "}
            <label>
              3. If your ESP supports an app password, use it instead of a
              regular password.
            </label>
            <label>
              4. Use correct details like SMTP/IMAP Host, Port & Encryption.
              Confirm it with your email provider.
            </label>
            <label>
              5. If you still need assistance connecting your SMTP email to
              MailOptimal, initiate a chat with us.{" "}
            </label>
            <label>
              6. Use correct SMTP details like host & port to connect your email
              account. Click here to know SMTP details for popular email
              providers.
            </label>
          </div>

          <div>
            <button
              onClick={() => {
                if (SMTPConnectValidation && !testSMTPLoading) {
                  onClickSMTPTest();
                }
              }}
              style={{
                opacity: SMTPConnectValidation ? "100%" : "35%",
              }}
            >
              {testSMTPLoading ? <Spin /> : "Test SMTP"}
            </button>
            <button
              style={{
                opacity: IMAPConnectValidation ? "100%" : "35%",
              }}
              onClick={() => {
                if (IMAPConnectValidation && !testIMAPLoading) {
                  onClickIMAPTest();
                }
              }}
            >
              {testIMAPLoading ? <Spin /> : "Test IMAP"}
            </button>
          </div>

          {testSMTPValidation == "succcess" ? (
            <span className={style.successComponent}>
              <img src={successSVG} />
              <span className={style.success}>
                SMTP has been connected successfully!
              </span>
            </span>
          ) : testSMTPValidation == "fail" ? (
            <span className={style.successComponent}>
              <img src={failSVG} />
              <span className={style.fail}>SMTP Failed to connect</span>
            </span>
          ) : (
            ""
          )}

          {testIMAPValidation == "succcess" ? (
            <span className={style.successComponent}>
              <img src={successSVG} />
              <span className={style.success}>
                IMAP has been connected successfully!
              </span>
            </span>
          ) : testIMAPValidation == "fail" ? (
            <span className={style.successComponent}>
              <img src={failSVG} />
              <span className={style.fail}>IMAP Failed to connect</span>
            </span>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className={style.submitButtom}>
        <button
          onClick={() => {
            navigate("/account");
          }}
        >
          Cancel
        </button>
        <button
          onClick={form.submit}
          style={{
            opacity: formValidateStatus ? "100%" : "35%",
          }}
        >
          {loading ? <Spin /> : "Connect & Save"}
        </button>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default ConnectSMTP;
