import React, { useEffect, useRef, useState } from "react";
import style from "./DeliverabilityEmailContent.module.css";
import {
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Card,
  Spin,
  message,
  Tooltip,
} from "antd";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor } from "ckeditor5-custom-build/build/ckeditor";
import {
  CheckCircleOutlined,
  PlusSquareOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import ReactDOM from "react-dom";
import PictureIcon from "../../../assets/dashboard/Content.svg";
import CodeIcon from "../../../assets/dashboard/Arrows.svg";
import PictureEmailModal from "../../../component/dashboardModal/PictureEmailModal/PictureEmailModal";
import SourseCodeModal from "../../../component/dashboardModal/SourseCodeModal/SourseCodeModal";
import { getItem, setItem } from "../../../utility/localStorageControl";
import { useDispatch, useSelector } from "react-redux";
import {
  connectedEmailList,
  getAllEmailList,
  getIdBasedTemplateList,
  personalizationsList,
} from "../../../redux/dashboard/action";
import { useForm } from "antd/es/form/Form";
import { createDeliverabilityTestApi } from "../../../redux/deliverabilityTest/action";
import DeliverabilityTestLoader from "./DeliverabilityTestLoader";
import moment from "moment";

import UpgradeCreditModel from "./UpgradeCreditModel";
import { userDetailsSave } from "../../../redux/authentication/action";

// DEFINE MAIN FUNCTION
const DeliverabilityEmailContent = () => {
  // DEFINE ALL STATES
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = useForm();

  const listOfEmail = useSelector((state) => state.dashboard.newEmailList);

  const [editor, setEditor] = useState();
  const [searchData2, setSearchData2] = useState("");
  const [searchTemplateData, setsearchTemplateData] = useState("");
  const [pictureEmailModal, setPictureEMailModal] = useState(false);
  const [sourseCodeModal, setSourseCodeModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [data, setData] = useState();
  const [selectedEmail, setSelectedEmail] = useState();
  const [subject, setSubject] = useState();
  const [loader, setLoader] = useState(false);
  const [selectOption, setSelectOption] = useState([]);
  let isMount = useRef(true);
  const selectContainerRef = useRef(null);
  const [searchPersonalization, setSearchPersonalization] = useState("");
  const [submitTime, setSubmitTime] = useState("");
  const [filetrTempList, setFilterTempList] = useState([]);
  const [upgradeCreditModel, setUpgradeCreditModel] = useState(false)



  const userDetails = useSelector((state) => state?.auth?.userDetails);
  console.log("userDetails", userDetails);
  const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  };

  const fetchEmailList = async (qryStr) => {
    let queryStrings = {

      userId: getItem("userDetails")?.id,
    };
    try {
      const response = await dispatch(getAllEmailList(queryStrings));
      console.log("response: ", response);
    } catch (error) {
      console.log("error: ", error);
    }
  };
  const debouncedSearchData = useDebounce(searchData2, 500);
  useEffect(() => {
    fetchEmailList()
  }, []);

  // const fetchEmailList = async (qryStr) => {
  //   let queryStrings = {
  //     search: searchData2,
  //     userId: getItem("userDetails")?.id,
  //   };
  //   try {
  //     const response = await dispatch(getAllEmailList(queryStrings));
  //     console.log("response: ", response);

  //   } catch (error) {
  //     console.log("error: ", error)
  //     return
  //   }
  // };

  // useEffect(() => {
  //   fetchEmailList();
  // }, [searchData2]);

  // DEFINE USEEFFECT WITH SEARCH DATA
  useEffect(() => {
    templateListApi(searchTemplateData);
  }, []);

  console.log("searchData2", searchData2)

  const templateListApi = async () => {
    await dispatch(getIdBasedTemplateList(""));
  };

  let listOfTemplate = useSelector(
    (state) => state.dashboard.emailTemplateList
  );

  useEffect(() => {
    personalizationList(searchPersonalization);
  }, [searchPersonalization]);

  let listOfPersonalizations = useSelector(
    (state) => state.dashboard.personalizationList
  );

  // DEFINE FUNCTION FOR CONNECTED EMAIL LIST
  const personalizationList = async (search) => {
    let queryStrings = {
      page: 1,
      limit: 500,
      search: search,
    };
    await dispatch(personalizationsList(queryStrings));
  };

  useEffect(() => {
    if (searchTemplateData !== "") {
      const arr = listOfTemplate.filter((template) =>
        template.title.toLowerCase().includes(searchTemplateData.toLowerCase())
      );
      console.log("arr: ", arr)
      setFilterTempList(arr);
    } else {
      setFilterTempList(listOfTemplate);
    }
  }, [searchTemplateData, listOfTemplate]);
  console.log(filetrTempList)

  // DEFINE USEEFFECT
  useEffect(() => {
    if (editor && listOfPersonalizations) {
      insertInputField(editor, listOfPersonalizations);
    }
    return () => {
      if (selectContainerRef.current) {
        selectContainerRef.current.remove();
      }
    };
  }, [editor, filetrTempList, listOfPersonalizations, selectedEmail]);

  useEffect(() => {
    getSelectOptions();
  }, [listOfEmail]);

  const getSelectOptions = () => {
    if (listOfEmail && listOfEmail.length > 0) {
      let arr = [];
      listOfEmail &&
        listOfEmail.map((email, i) => {
          const obj = {
            data: email,
            value: email?.sender_email,
            key: `rc-index-key-${i}`,
            label: (
              <span className={style.custome_selectOtption_lable}>
                <span className={style.emailType_logo}>
                  {email.type == "google" ? (
                    <svg viewBox="0 0 500 500" id="google">
                      <path
                        fill="#fbbb00"
                        d="M113.47 309.408 95.648 375.94l-65.139 1.378C11.042 341.211 0 299.9 0 256c0-42.451 10.324-82.483 28.624-117.732h.014L86.63 148.9l25.404 57.644c-5.317 15.501-8.215 32.141-8.215 49.456.002 18.792 3.406 36.797 9.651 53.408z"
                      />
                      <path
                        fill="#518ef8"
                        d="M507.527 208.176C510.467 223.662 512 239.655 512 256c0 18.328-1.927 36.206-5.598 53.451-12.462 58.683-45.025 109.925-90.134 146.187l-.014-.014-73.044-3.727-10.338-64.535c29.932-17.554 53.324-45.025 65.646-77.911h-136.89V208.176h245.899z"
                      />
                      <path
                        fill="#28b446"
                        d="m416.253 455.624.014.014C372.396 490.901 316.666 512 256 512c-97.491 0-182.252-54.491-225.491-134.681l82.961-67.91c21.619 57.698 77.278 98.771 142.53 98.771 28.047 0 54.323-7.582 76.87-20.818l83.383 68.262z"
                      />
                      <path
                        fill="#f14336"
                        d="m419.404 58.936-82.933 67.896C313.136 112.246 285.552 103.82 256 103.82c-66.729 0-123.429 42.957-143.965 102.724l-83.397-68.276h-.014C71.23 56.123 157.06 0 256 0c62.115 0 119.068 22.126 163.404 58.936z"
                      />
                    </svg>
                  ) : email.type == "smtp_imap" ? (
                    <svg
                      viewBox="0 0 27 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.834 23.9163H8.16732C4.66732 23.9163 2.33398 22.1663 2.33398 18.083V9.91634C2.33398 5.83301 4.66732 4.08301 8.16732 4.08301H19.834C23.334 4.08301 25.6673 5.83301 25.6673 9.91634V18.083C25.6673 22.1663 23.334 23.9163 19.834 23.9163Z"
                        stroke="#101828"
                        strokeWidth="1.75"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        opacity="0.4"
                        d="M19.8327 10.5L16.181 13.4167C14.9793 14.3733 13.0077 14.3733 11.806 13.4167L8.16602 10.5"
                        stroke="#101828"
                        strokeWidth="1.75"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg viewBox="0 0 260 260">
                      <path
                        d="M104.868 104.868H0V0h104.868v104.868z"
                        fill="#f1511b"
                      />
                      <path
                        d="M220.654 104.868H115.788V0h104.866v104.868z"
                        fill="#80cc28"
                      />
                      <path
                        d="M104.865 220.695H0V115.828h104.865v104.867z"
                        fill="#00adef"
                      />
                      <path
                        d="M220.654 220.695H115.788V115.828h104.866v104.867z"
                        fill="#fbbc09"
                      />
                    </svg>
                  )}
                </span>
                <span className={style.selectMailtext}>
                  {email?.sender_email}
                </span>
              </span>
            ),
          };
          arr.push(obj);
        });
      setSelectOption(arr);
    }
  };

  const handleSubmit = async () => {
    if (!subject) {
      return message.error('Subject must be provided')
    }
    if (!data) {
      return message.error('Content must be provided')
    }
    if (selectedEmail && subject && data && selectedEmail?.id) {
      setLoader(true);
      setSubmitTime(moment().format("DD MMMM YYYY, h:mm a"));
      const payload = {
        email_account_id: selectedEmail?.id,
        subject: subject,
        body: data,
      };
      const resp = await dispatch(createDeliverabilityTestApi(payload));
      if (resp?.status) {
        let { data } = resp;
        dispatch(userDetailsSave(data?.userDetails));
        navigate(`/deliverability-test/${data?.id}`, { state: data });
      }
      setLoader(false);
    } else {
      message.error("Please select an email account.");
    }
  };

  // DEFINE FUNCTION FOR IMAGE URL
  const handleImageSelection = (urlFile) => {
    let imageUrl =
      `<img style="width:300px"  src= ` + URL.createObjectURL(urlFile) + ` />`;
    setData((prevData) => {
      if (prevData == undefined) {
        return imageUrl;
      } else {
        return prevData + imageUrl;
      }
    });
  };

  // DEFINE FUNCTION FOR HTML CODE HANDLE
  const handleHtmlCode = (codeFile) => {
    setData((prevData) => {
      if (prevData == undefined) {
        return codeFile;
      } else {
        return prevData + codeFile;
      }
    });
  };

  // DFFINE FUNCTION FOR CKEDITOR CONFIGRATION
  const insertInputField = (editor, listPersonalizations) => {
    const toolbarElement = editor.ui.view.toolbar.element;
    const selectContainer = document.createElement("div");
    selectContainer.classList.add("ck", "ck-toolbar__items");
    if (selectContainerRef.current) {
      selectContainerRef.current.remove();
    }
    const select = (
      <>
        <img
          src={PictureIcon}
          className={style.pictureIcon}
          onClick={() => {
            setPictureEMailModal(true);
            setSelectedValue();
          }}
          alt="imageUpload"
        />
        <img
          src={CodeIcon}
          className={style.pictureIcon}
          onClick={() => {
            setSourseCodeModal(true);
          }}
          alt="codeIcon"
        />

        <div className={style.templatePlugin}>
          <Select
            // disabled={selectedEmail ? false : true}
            size="large"
            placeholder="Template"
            className={style.selectEmail2}
            allowClear

            popupClassName="selectPopup"
            value={null}
            dropdownRender={(menu) => {
              return (
                <div>
                  {/* <div className={style.searchInputDropdownContainor}>
                      <Input
                        onChange={(event) => {
                          setsearchTemplateData(event.target.value);
                        }}
                        className={style.searchInputDropdown}
                        suffix={
                          searchTemplateData &&
                          searchTemplateData.length > 0 && (
                            <div onClick={() => setsearchTemplateData("")}>
                              <svg
                                fill-rule="evenodd"
                                viewBox="64 64 896 896"
                                focusable="false"
                                data-icon="close"
                                width="10px"
                                height="10px"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                              </svg>
                            </div>
                          )
                        }
                        prefix={
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.58317 17.5003C13.9554 17.5003 17.4998 13.9559 17.4998 9.58366C17.4998 5.2114 13.9554 1.66699 9.58317 1.66699C5.21092 1.66699 1.6665 5.2114 1.6665 9.58366C1.6665 13.9559 5.21092 17.5003 9.58317 17.5003Z"
                              stroke="#98A2B3"
                              strokeWidth="1.25"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              opacity="0.4"
                              d="M18.3332 18.3337L16.6665 16.667"
                              stroke="#98A2B3"
                              strokeWidth="1.25"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        }
                        placeholder="Search"
                        value={searchTemplateData}
                      />
                    </div> */}


                  {menu}


                  <div
                    className={style.selctBarFooter}
                    onClick={(e) => {
                      setItem(
                        "templateTitle",
                        `Untitled Template ${listOfTemplate.length + 1}`
                      );
                      setTimeout(() => {
                        navigate("/templates/create-new");
                      }, 500);
                    }}
                  >
                    <PlusSquareOutlined /> <span>Add new template</span>
                  </div>
                </div>
              );
            }}
            onChange={(value) => {

              const selectVal = filetrTempList.find(
                (item) => item.id === value
              );
              setData(selectVal?.body);
              form.setFieldValue('subject', selectVal?.subject)
              setSubject(selectVal?.subject)
            }}
          >
            {filetrTempList && filetrTempList.length > 0
              ? filetrTempList.map((item, index) => {
                return (
                  <Select.Option
                    className="selectPopupOption"
                    value={item?.id}
                    key={index}
                  >
                    <div>
                      <FileTextOutlined />{" "}
                      <Tooltip placement="top" title={item?.title}>
                        <span className={style.selectTemplatename}>
                          {item?.title}
                        </span>
                      </Tooltip>
                      {/* <span className={style.selectTemplatename}>
                        {item?.title}
                      </span> */}
                    </div>
                  </Select.Option>
                );
              })
              : "No template data available"}
          </Select>
        </div>
        <div className={style.templatePlugin}>
          <Select
            size="large"
            placeholder="Personalization"
            className={style.selectEmail2}
            style={{ width: "auto" }}
            popupClassName="selectPopup"
            value={null}

            listHeight={200}
            onChange={(value) => {
              setData((prevData) => {
                if (prevData == undefined) {
                  return value;
                } else {
                  const tempDiv = document.createElement("div");
                  tempDiv.innerHTML = prevData;
                  let lastTextNode = findLastTextNode(tempDiv);
                  if (lastTextNode == false) {
                    return (tempDiv.innerHTML += ` ${value}`);
                  } else {
                    lastTextNode.textContent += ` ${value}`;
                    return tempDiv.innerHTML;
                  }
                }
              });
            }}
          >
            {listPersonalizations &&
              listPersonalizations?.map((item, index) => {
                return (
                  <Select.Option
                    className="selectPopupOption"
                    value={item?.personalization}
                    key={index}
                  >
                    <Tooltip placement="top" title={item?.personalization}>
                      <span className={style.selectTemplatename}>
                        {item?.personalization}
                      </span>
                    </Tooltip>
                  </Select.Option>
                );
              })}
          </Select>
        </div>
      </>
    );

    ReactDOM.render(select, selectContainer);
    toolbarElement.appendChild(selectContainer);
    selectContainerRef.current = selectContainer;
  };
  console.log("subject", subject)

  // Function to recursively find the last text node within a DOM element
  const findLastTextNode = (element) => {
    const childNodes = element.childNodes;
    const lastChild = childNodes[childNodes.length - 1];
    if (lastChild) {
      if (lastChild.nodeType === Node.TEXT_NODE) {
        return lastChild; // Found the last text node
      } else {
        return findLastTextNode(lastChild); // Recursively search deeper for the last text node
      }
    } else {
      return false;
    }
  };
  // DEFINE FUNCTION TO CLEAR THE SELECT VALUE
  const handleChangeEmailAccount = (value) => {
    console.log("value", value)
    if (value === "" || value === undefined) {
      setSelectedEmail({});
    }
    else {
      setSelectedEmail((prev) => {
        return { ...prev, receiver_email: value }
      });
    }
    console.log("value", value)
  }

  // DEFINE FILTER EMAIL LIST ON THE BASIS OF NAME AND EMAIL
  const filterEmailList = selectOption?.filter((item) => {
    if (searchData2 == '') return item
    return item?.data?.receiver_email?.toLowerCase()?.includes(searchData2?.toLowerCase()) || item?.data?.sender_username?.toLowerCase()?.includes(searchData2?.toLowerCase())
  })

  // FUNCTION TO HANDLE SELECTED EMAIL ACCOUNT
  const handleSelectEmailAccountChange = (e, option) => {
    if (e.target.checked) {
      setSelectedEmail(option?.data?.data);
      console.log("option?.data?.data", option?.data?.data)
    } else {
      setSelectedEmail(null);
    }
  }

  return (
    <>
      {loader ? (
        <DeliverabilityTestLoader
          accountInfo={selectedEmail}
          submitTime={submitTime}
        />
      ) : (
        <>
          <Row gutter={[20]}>
            <Col span={24}>
              <Card className={style.mainCard}>
                <Row justify={"space-between"} align={"middle"}>
                  <div className={style.cardTitle}>Email Account</div>
                  <Select
                    size="large"
                    placeholder="Select Email Account"
                    className={style.selectEmail}
                    popupClassName="selectPopup"
                    allowClear
                    onChange={handleChangeEmailAccount}
                    value={selectedEmail?.email}
                    dropdownRender={(menu) => (
                      <div>
                        <div className={style.searchInputDropdownContainor}>
                          <Input
                            onChange={(event) => {
                              setSearchData2(event.target.value);
                              if (event.target.checked) {
                              }
                              else {
                                // setSearchData2("");

                              }

                            }}
                            className={style.searchInputDropdown}
                            prefix={
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.58317 17.5003C13.9554 17.5003 17.4998 13.9559 17.4998 9.58366C17.4998 5.2114 13.9554 1.66699 9.58317 1.66699C5.21092 1.66699 1.6665 5.2114 1.6665 9.58366C1.6665 13.9559 5.21092 17.5003 9.58317 17.5003Z"
                                  stroke="#98A2B3"
                                  strokeWidth="1.25"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  opacity="0.4"
                                  d="M18.3332 18.3337L16.6665 16.667"
                                  stroke="#98A2B3"
                                  strokeWidth="1.25"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            }
                            suffix={
                              searchData2 &&
                              searchData2.length > 0 && (
                                <div onClick={() => setSearchData2("")}>
                                  <svg
                                    fill-rule="evenodd"
                                    viewBox="64 64 896 896"
                                    focusable="false"
                                    data-icon="close"
                                    width="10px"
                                    height="10px"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                                  </svg>
                                </div>
                              )
                            }
                            placeholder="Search"
                            value={searchData2}
                          />
                        </div>
                        {menu}
                        <div
                          className={style.selctBarFooter}
                          onClick={() => navigate(`/new-email`)}
                        >
                          <PlusSquareOutlined /> <span>Add New  Email Account</span>
                        </div>
                      </div>
                    )}
                    options={filterEmailList}
                    optionRender={(option) => {
                      return (
                        <div className="selectPopupOption">
                          <Checkbox
                            onChange={(e) => handleSelectEmailAccountChange(e, option)}
                            checked={
                              option.data.data?.id ==
                                selectedEmail?.id
                                ? true
                                : false
                            }
                          >
                            <div className="select_item_content">
                              <span className={style.selectMailname}>
                                {option.data.data?.sender_username}
                              </span>
                              <span className={style.imgGoogle}>
                                {option.data.data.type == "google" ? (
                                  <svg viewBox="0 0 500 500" id="google">
                                    <path
                                      fill="#fbbb00"
                                      d="M113.47 309.408 95.648 375.94l-65.139 1.378C11.042 341.211 0 299.9 0 256c0-42.451 10.324-82.483 28.624-117.732h.014L86.63 148.9l25.404 57.644c-5.317 15.501-8.215 32.141-8.215 49.456.002 18.792 3.406 36.797 9.651 53.408z"
                                    />
                                    <path
                                      fill="#518ef8"
                                      d="M507.527 208.176C510.467 223.662 512 239.655 512 256c0 18.328-1.927 36.206-5.598 53.451-12.462 58.683-45.025 109.925-90.134 146.187l-.014-.014-73.044-3.727-10.338-64.535c29.932-17.554 53.324-45.025 65.646-77.911h-136.89V208.176h245.899z"
                                    />
                                    <path
                                      fill="#28b446"
                                      d="m416.253 455.624.014.014C372.396 490.901 316.666 512 256 512c-97.491 0-182.252-54.491-225.491-134.681l82.961-67.91c21.619 57.698 77.278 98.771 142.53 98.771 28.047 0 54.323-7.582 76.87-20.818l83.383 68.262z"
                                    />
                                    <path
                                      fill="#f14336"
                                      d="m419.404 58.936-82.933 67.896C313.136 112.246 285.552 103.82 256 103.82c-66.729 0-123.429 42.957-143.965 102.724l-83.397-68.276h-.014C71.23 56.123 157.06 0 256 0c62.115 0 119.068 22.126 163.404 58.936z"
                                    />
                                  </svg>
                                ) : option.data.data.type == "smtp_imap" ? (
                                  <svg
                                    viewBox="0 0 27 27"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M19.834 23.9163H8.16732C4.66732 23.9163 2.33398 22.1663 2.33398 18.083V9.91634C2.33398 5.83301 4.66732 4.08301 8.16732 4.08301H19.834C23.334 4.08301 25.6673 5.83301 25.6673 9.91634V18.083C25.6673 22.1663 23.334 23.9163 19.834 23.9163Z"
                                      stroke="#101828"
                                      strokeWidth="1.75"
                                      strokeMiterlimit="10"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      opacity="0.4"
                                      d="M19.8327 10.5L16.181 13.4167C14.9793 14.3733 13.0077 14.3733 11.806 13.4167L8.16602 10.5"
                                      stroke="#101828"
                                      strokeWidth="1.75"
                                      strokeMiterlimit="10"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg viewBox="0 0 260 260">
                                    <path
                                      d="M104.868 104.868H0V0h104.868v104.868z"
                                      fill="#f1511b"
                                    />
                                    <path
                                      d="M220.654 104.868H115.788V0h104.866v104.868z"
                                      fill="#80cc28"
                                    />
                                    <path
                                      d="M104.865 220.695H0V115.828h104.865v104.867z"
                                      fill="#00adef"
                                    />
                                    <path
                                      d="M220.654 220.695H115.788V115.828h104.866v104.867z"
                                      fill="#fbbc09"
                                    />
                                  </svg>
                                )}
                              </span>
                              <div>
                                <span className={style.selectMailtext}>
                                  {option.data.data?.sender_email}
                                </span>
                                <span className={style.selectIcontext}>
                                  <CheckCircleOutlined />
                                </span>
                              </div>
                            </div>
                          </Checkbox>
                        </div>
                      );
                    }}
                  />
                </Row>
                <div className={style.cardDescription}>
                  Select the email account for which you want to test deliverability
                </div>
              </Card>
            </Col>
          </Row>
          <div className={style.templateContainer}>
            <Form layout="vertical" form={form}>
              <Row gutter={[20, 20]}>
                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                  <div className={style.rightContainer}>
                    <div className={style.cardTitle}>Email Template</div>
                    <div className={style.ContentDescription}>
                      Add your email content to test deliverability or insert the saved templates
                    </div>
                    <Form.Item name={"subject"} className="subjectInput">
                      <Input
                        value={subject}
                        onChange={(e) => {
                          setSubject(e.target.value);
                        }}
                        prefix={
                          <>
                            <div className={style.prefixLabel}>Subject</div>
                            <span className={style.dateDivider}></span>
                          </>
                        }
                        placeholder="Enter Subject"
                      />
                    </Form.Item>
                    <Form.Item
                      name={"description"}
                      className="descriptionInput"
                    >
                      <CKEditor
                        editor={Editor}
                        data={data}
                        onReady={(editor) => {
                          setEditor(editor);
                        }}
                        onChange={(event, editor) => {
                          let filterData = editor.getData();
                          let replaceData = filterData
                            .replace(/&lt;/g, "<")
                            .replace(/&gt;/g, ">");
                          setData(replaceData);
                        }}
                        config={{
                          placeholder: "Your message",
                          removePlugins: ["Title", "MediaEmbedToolbar"],
                          toolbar: {
                            items: [
                              "fontFamily",
                              "FontSize",
                              "bold",
                              "italic",
                              "Underline",
                              "alignment:left",
                              "alignment:right",
                              "alignment:center",
                              "alignment:justify",
                              "bulletedList",
                              "numberedList",
                              "link",
                              // "imageUpload",
                              // "code",
                              // "codeBlock",
                              "|",
                              // "SourceEditing",
                              ,
                            ],
                            // shouldNotGroupWhenFull: true,
                          },
                        }}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row gutter={[20]} justify={"end"}>
                <div className={style.priceContainer}>
                  <Tooltip
                    title={
                      selectedEmail && selectedEmail?.id
                        ? ""
                        : "Please select an email account."
                    }
                  >
                    <button
                      // disabled={selectedEmail && selectedEmail?.id ? false : true}
                      onClick={() => {
                        if (userDetails?.pending_deliverability_tests <= 0) {
                          // message.error("Insufficient credit.");
                          setUpgradeCreditModel(true)
                          return;
                        }
                        // navigate(`/deliverability-test/2`);
                        if (selectedEmail && selectedEmail?.id) {
                          handleSubmit();
                        } else {
                          message.error("Please select an email account.");
                        }
                      }}
                    >
                      <span>Start Test</span>
                    </button>
                  </Tooltip>
                </div>
              </Row>
            </Form>
          </div>
          {pictureEmailModal && (
            <PictureEmailModal
              pictureEmailModal={pictureEmailModal}
              selectedValue={selectedValue}
              onClose={() => {
                setPictureEMailModal(false);
                setSelectedValue({});
              }}
              onSubmit={handleImageSelection}
            />
          )}
          {sourseCodeModal && (
            <SourseCodeModal
              sourseCodeModal={sourseCodeModal}
              selectedValue={selectedValue}
              onClose={() => {
                setSourseCodeModal(false);
                setSelectedValue({});
              }}
              onSubmit={handleHtmlCode}
            />
          )}
        </>
      )}
      {upgradeCreditModel &&
        (
          <UpgradeCreditModel
            deleteEmailModal={upgradeCreditModel}
            onClose={() => {
              setUpgradeCreditModel(false);
            }}
            type="template"
          />
        )}
    </>
  );
};
// DEFINE MAIN FUNCTION EXPORT
export default DeliverabilityEmailContent;
