import { message } from "antd";
import { DataService } from "../../config/DataService";
import { API } from "../../config/api/Index";

// SUBSCRIPTION PLAN API CALL
export const subscriptionPlanList = () => {
  return async (dispatch) => {
    try {
      // let responseIsoCode = await axios.get(
      //   "https://scripts.pubnation.com/flooring/countryLookup"
      // );
      // if (responseIsoCode?.status == 200) {
      //   const response = await DataService.get(
      //     `${API.subscription.subscriptionPlanList}/${responseIsoCode?.data?.geo?.country_code}`
      //   );
      //   if (response.data.status) {
      //     dispatch({
      //       type: API.subscription.subscriptionPlanList,
      //       subscriptionPlanList: response.data.data,
      //     });
      //     return true;
      //   } else {
      //     return false;
      //   }
      // } else {
      //   const response = await DataService.get(
      //     `${API.subscription.subscriptionPlanList}/US`
      //   );
      //   if (response.data.status) {
      //     dispatch({
      //       type: API.subscription.subscriptionPlanList,
      //       subscriptionPlanList: response.data.data,
      //     });
      //     return true;
      //   } else {
      //     return false;
      //   }
      // }

      // ///////////////////////////////////////////////////////////////////////////////

      const response = await DataService.get(
        `${API.subscription.subscriptionPlanList}/US`
      );
      if (response.data.status) {
        dispatch({
          type: API.subscription.subscriptionPlanList,
          subscriptionPlanList: response.data.data,
        });
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// CREATE SUBSCRIPTION API CALL
export const createSubscription = (id) => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.post(API.subscription.create, id);
      if (loggedIn.data.status) {
        return { status: true, data: loggedIn.data.data };
      } else {
        message.error(loggedIn.data.message);
        return { status: false };
      }
    } catch (err) {
      return { status: false };
    }
  };
};
export const getInvoiceUrl = () => {
  return async (dispatch) => {
    try {
      const res = await DataService.get(API.subscription.invoice);
      if (res.data.status) {
        return res.data.data;
      } else {
        // message.error(res.data.message);
        return null;
      }
    } catch (err) {
      return null;
    }
  };
}
