import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row, Spin } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { getItem, setItem } from "../../utility/localStorageControl";
import { signUp } from "../../redux/authentication/action";
import { useDispatch } from "react-redux";
import logo from "../../assets/logo.svg";
import style from "./AuthFiles.module.css";

// DEFINE MAIN FUNCTION
const SignUp = () => {
  // DEFINE ALL STATES
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [formValidateStatus, setFormValidateStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  // DEFINE USEEFFECT FOR FORM VALUES VALIDATE
  useEffect(() => {
    if (getItem("userDetails")) {
      form.setFieldsValue(getItem("signUpFormValues"));
      let checkFormValues = {
        first_name: getItem("signUpFormValues")?.first_name,
        last_name: getItem("signUpFormValues")?.last_name,
        email: getItem("signUpFormValues")?.email,
        password: getItem("signUpFormValues")?.password,
      };
      onCheckFormChangeValue("", checkFormValues);
    }
  }, []);

  // DEFINE FUNCTION FOR FORM SUBMIT
  const onFinish = async (values) => {
    setLoading(true);
    let response = await dispatch(
      signUp({
        ...values,
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    );
    if (response.status) {
      if (response?.data?.signup_step == 1) {
        navigate("/onboarding");
      }
      setItem("signUpFormValues", {
        ...response?.data,
        password: values.password,
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  // DEFINE FUNCTION FOR FORM ONCHANGE VALUES VALIDATIONS CHECK
  const onCheckFormChangeValue = (changedValues, allValues) => {
    console.log(allValues);
    if (
      !Object.values(allValues).includes(undefined) &&
      !Object.values(allValues).includes("")
    ) {
      const regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+{}|:"<>?`\-=[\]\\;',./]{8,64}$/;
      if (!allValues.password || regex.test(allValues.password)) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!allValues.email || emailRegex.test(allValues.email)) {
          setFormValidateStatus(true);
        } else {
          setFormValidateStatus(false);
        }
      } else {
        setFormValidateStatus(false);
      }
    } else {
      setFormValidateStatus(false);
    }
  };

  // DEFINE FUNCTION FOR CUSTOM VALIDATION PASSWORD
  const validatePassword = (rule, value, callback) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+{}|:"<>?`\-=[\]\\;',./]{8,64}$/;
    if (!value || regex.test(value)) {
      callback();
    } else {
      callback(
        "please enter strog password, one uppercase character, one numerical character, one lowercase character, characters length between 8 - 64"
      );
    }
  };

  // DEFINE FUNCTION FOR CUSTOM VALIDATION EMAIL
  const validateEmail = (_, value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value || emailRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter valid email!");
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <div className={style.signUpComponent}>
        <div className={style.detailsComponent}>
          <img src={logo} />
          <h2 className={style.title}>Get Started</h2>
          <p className={style.description}>Start setting up your account ✌️</p>
        </div>
        <Form
          layout="vertical"
          name="basic"
          requiredMark={false}
          size="large"
          form={form}
          onFinish={(event) => {
            if (!loading) {
              onFinish(event);
            }
          }}
          onValuesChange={(value, allValues) => {
            onCheckFormChangeValue(value, allValues);
          }}
          autoComplete="off"
        >
          <Row gutter={12}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="First Name"
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: "Please input your first name!",
                  },
                ]}
              >
                <Input placeholder="Enter First Name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Last Name"
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: "Please input your last name!",
                  },
                ]}
              >
                <Input placeholder="Enter Last Name" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              { validator: validateEmail },
            ]}
          >
            <Input placeholder="Enter Email" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              { validator: validatePassword },
            ]}
            hasFeedback
          >
            <Input.Password placeholder="Enter Password" />
          </Form.Item>

          <Form.Item className={style.submitButton}>
            <button
              type="submit"
              style={{
                opacity: formValidateStatus ? "100%" : "35%",
              }}
            >
              {loading ? <Spin /> : "Sign Up"}
            </button>
          </Form.Item>
        </Form>
        <div className={style.termsDescritions}>
          <p>
            By signing up, you agree to our <u>privacy policy</u> and{" "}
            <u>terms of use.</u>
          </p>
          <div className={style.otherDescription}>
            <p>
              Already have an account?
              <Link to={"/login"}>
                <span>Log In</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default SignUp;
