import React, { useState } from "react";
import { Modal, Input, Avatar, Image } from "antd";
import "./PictureEmailModal.css";
import { useMutationEmailDelete } from "../../../state/EmailConnect/Emailconnect.hook";

// DEFINE MAIN FUNCTION
const PictureEmailModal = ({
  pictureEmailModal,
  onClose,
  onSubmit,
  selectedValue,
}) => {
  // DEFINE ALL STATES
  const { mutate: connectMicroSoft } = useMutationEmailDelete(
    selectedValue?.id,
    onSubmit
  );
  const [uploadThumbnailURL, setUploadThumbnailURL] = useState(null);

  // DEFINE FUNCTION FOR UPLOAD IMAGE
  const handleUpload = () => {
    if (uploadThumbnailURL) {
      onSubmit(uploadThumbnailURL);
      onClose();
      setUploadThumbnailURL(null);
    }
  };

  // DEFUNE MAIN FUNCTION RETURN
  return (
    <div>
      <Modal
        open={pictureEmailModal}
        className={"pictureModal"}
        footer={null}
        centered
        width={782}
        onCancel={() => {
          onClose();
        }}
        maskStyle={{
          backdropFilter: "blur(8px) saturate(35%)",
          backgroundColor: "rgba(16, 24, 40, 0.35)",
        }}
      >
        <div className="pictureModalContent">
          <div className="modalHeader">
            <span>Upload image</span>
          </div>
          <div className="modalDesc">
            <label
              htmlFor="input-Top-file"
              style={{
                padding: "38px 26px",
              }}
            >
              {uploadThumbnailURL ? (
                <>
                  {(uploadThumbnailURL !== "") &
                  (uploadThumbnailURL != undefined) ? (
                    <Image
                      src={URL.createObjectURL(uploadThumbnailURL)}
                      alt="image"
                      className="imgPreview"
                    />
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  <div className="btncontainer">
                    <span>
                      Drag & drop image here
                      <br />
                      <div className="orDivider">or</div>
                    </span>
                    <span>
                      <div className="priceContainer">
                        <button>
                          <span>Browser file</span>
                        </button>
                      </div>
                    </span>
                  </div>
                </>
              )}
            </label>
            <Input
              type="file"
              onChange={(event) => {
                setUploadThumbnailURL(event.target.files[0]);
              }}
              accept="image/jpeg,image/png,"
              aria-label="Upload File"
            />
          </div>
          <div className="pictureModalFooter">
            <button
              onClick={() => {
                handleUpload();
              }}
              style={{ opacity: uploadThumbnailURL ? "100%" : "35%" }}
            >
              Upload
            </button>
            <button
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

// DEFINE EXPORT MAIN FUNCTION
export default PictureEmailModal;
