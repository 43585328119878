import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import SignUp from "../containers/Auth/SignUp";
import Login from "../containers/Auth/Login";
import ForgotPassword from "../containers/Auth/ForgotPassword";
import EmailVarification from "../containers/Auth/EmailVarification";
import ResetPassword from "../containers/Auth/ResetPassword";
import SignUpDeliveryQuestions from "../containers/Auth/SignUpDeliveryQuestions";
import SignUpAboutQuestions from "../containers/Auth/SignUpAboutQuestions";
import VerifyAccount from "../containers/Auth/VerifyAccount";
import "../component/authLayout/authStyle.css";
import { useSelector } from "react-redux";
import { getItem } from "../utility/localStorageControl";
import UserLogin from "../containers/Auth/UserLogin";
import PublicDeliverability from "../containers/admin/PublicDeliverability";
import TopHeader from "../component/dashBoardLayout/TopHeader";
import PublicTopHeader from "../component/dashBoardLayout/PublicHeader";
import SignUpWithSubscriptionCode from "../containers/Auth/SignUpWithSubscriptionCode";
import LoginWithSubscriptionCode from "../containers/Auth/LoginWithSubscriptionCode";

// DEFINE MAIN FUNCTION
const PublicRoute = ({ width }) => {
  // DEFINE MAIN FUNCTION RETURN
  const userDetails = useSelector((state) => state.auth.userDetails);
  const userDetailsFromLS = getItem("userDetails");
  console.log("userDetailsFromLS", userDetailsFromLS);
  let QuestionRoute =
    userDetails.signup_step == "2" ? (
      <SignUpAboutQuestions width={width} />
    ) : (
      <SignUpDeliveryQuestions width={width} />
    );
  // QuestionRoute = userDetailsFromLS.signup_step == "1" && (
  //   <SignUpDeliveryQuestions width={width} />
  // );

  // console.log("userDetailsFromLS", userDetailsFromLS.signup_step);
  // if (
  //   userDetailsFromLS?.signup_step == "2" ||
  //   userDetailsFromLS?.signup_step == "1"
  // ) {
  //   return (
  //     <Routes>
  //       <Route path="/onboarding" element={"hii"}></Route>
  //     </Routes>
  //   );
  // }
  const location = useLocation();
  console.log("locationFromLS", location.search);
  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get("token");
  console.log("token", token);
  return (
    <>
      <Helmet>
        <title>
          {window.location.pathname == "/login" ? "Login" : "Sign Up"}
        </title>
        <meta
          name="description"
          content="Email Warming: Providing mail automation services."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {location.pathname.includes("/deliverability-test-public") && (
        <PublicTopHeader />
      )}
      <Routes>
        <Route path="/login" element={<Login width={width} />} />
        <Route
          path="/login/ltd-activation"
          element={<LoginWithSubscriptionCode width={width} />}
        />

        <Route
          path="/userLogin/:token"
          exact
          element={<UserLogin width={width} />}
        />
        <Route path="/signup" element={<SignUp width={width} />} />
        <Route
          path="/signup/ltd-activation"
          element={<SignUpWithSubscriptionCode width={width} />}
        />
        {token ? (
          <Route
            path="/reset-password"
            element={<ResetPassword width={width} />}
          />
        ) : (
          <Route
            path="/reset-password"
            element={<ForgotPassword width={width} />}
          />
        )}

        <Route
          path="/email-varification"
          element={<EmailVarification width={width} />}
        />
        <Route
          path="/deliverability-test-public/:id"
          element={<PublicDeliverability />}
        />

        <Route
          path="/verify-account"
          element={<VerifyAccount width={width} />}
        />
        <Route path="/onboarding" element={QuestionRoute} />

        <Route
          exact
          path="/*"
          element={<Navigate to="/login" width={width} />}
        />
      </Routes>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default PublicRoute;
