import React from "react";
import styles from "./Subscription.module.css";
import { Spin } from "antd";

// DEFINE MAIN FUNCTION
const SubscriptionButtion = ({ btnText, btnState, className, onClick, loading = false }) => {
  // DEFINE MAIN FUNCTION RETURN
  return (
    <div className={`${styles.priceContainer} ${className}`}>
      <button onClick={onClick} disabled={btnState ? true : false} size="large">
        <span>{loading ? <Spin /> : btnText}</span>
      </button>
    </div>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default SubscriptionButtion;
