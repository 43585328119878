import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import { resetPasswordQuery, updateProfileQuery } from "./setting.api";

export const useMutationProfileUpdate = (id) => {
  return useMutation({
    mutationFn: (data) => updateProfileQuery(data, id),
    onError: (err) => {
      if (err?.response?.data?.message) {
        message.error(err?.response?.data?.message);
      } else {
        message.error(err);
      }
    },
    onSuccess: (data) => {
        console.log(data);
    },
  });
};

export const useMutationPassWordUpdate = (successPasswordChange) => {
  return useMutation({
    mutationFn: (data) => resetPasswordQuery(data),
    onError: (err) => {
      if (err?.response?.data?.message) {
        message.error(err?.response?.data?.message);
      } else {
        message.error(err);
      }
    },
    onSuccess: (success) => {
      if (success) {
        successPasswordChange(success);
      }
    },
  });
};
