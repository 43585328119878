import { API } from "../../config/api/Index";

//ALL TYPE WISE DATA RETURN
const initState = {};
const warmupSettingData = {};
const userSettingView = {};

//DEFINE AUTH REDUCER FUNCTION
const ConnectAccountReducer = (state = initState, action) => {
  switch (action.type) {
    case API.test.warmupSetting: {
      return {
        ...state,
        warmupSettingData: action.warmupSettingData,
        userSettingView: action.userSettingView,
      };
    }
    default:
      return state;
  }
};

//EXPORT ALL FUNCTION
export default ConnectAccountReducer;
