import { Modal, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import "../DeleteEmailModal/DeleteEmailModal.css";
import { useNavigate } from "react-router";
// DEFINE MAIN FUNCTION
const UpgradePlanModel = ({
  deleteEmailModal,
  onClose,
  onSubmit,
  selectedValue,
  loading,
  type,
}) => {
  const navigate = useNavigate();
  // DEFINE MAIN FUNCTION RETURN
  return (
    <div>
      <Modal
        open={deleteEmailModal}
        className={`deleteModal`}
        footer={null}
        centered
        width={450}
        onCancel={() => {
          onClose();
        }}
        maskStyle={{
          backdropFilter: "blur(8px) saturate(35%)",
          backgroundColor: "rgba(16, 24, 40, 0.35)",
        }}
      >
        <div className="deleteModalContent">
          <div className="modalHeader">
            <span>Upgrade Plan</span>
          </div>

          <div className="modalDesc">
            <span>Upgrade your subscription plan to create template</span>
          </div>
        </div>
        <div className="deleteModalFooter">
          <button
            onClick={() => {
              navigate("/billing-and-subscription/plans");
            }}
          >
            Upgrade Plan
          </button>
          <button
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default UpgradePlanModel;
