import { API } from "../../config/api/Index";
import Cookies from "js-cookie";
import { message } from "antd";
import { getItem, setItem } from "../../utility/localStorageControl";
import { DataService } from "../../config/DataService";

// LOGIN USER API CALL
export const login = (payloads) => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.post(API.auth.login, payloads);
      console.log("login successful", loggedIn?.data);
      if (loggedIn.data.status) {
        setItem("userDetails", loggedIn?.data?.data);
        setItem("access_token", loggedIn.data.data.token);
        dispatch({
          type: API.auth.login,
          login: loggedIn.data.data?.signup_step == 3 ? true : false,
          userDetails: loggedIn.data.data,
        });
        if (loggedIn.data.data?.signup_step == 1) {
          return { status: true, data: "/onboarding" };
        } else if (loggedIn.data.data?.signup_step == 2) {
          return { status: true, data: "/onboarding" };
        } else if (loggedIn.data.data?.signup_step == 3) {
          Cookies.set("logedIn", true);
          message.success(loggedIn.data.message);
          return { status: true, data: "/emailwarming" };
        }
      } else {
        message.error(loggedIn.data.message);
        return { status: false };
      }
    } catch (err) {
      return { status: false };
    }
  };
};

export const userLogin = (payloads) => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.post(API.auth.userLogin, payloads);
      console.log("login successful", loggedIn?.data);
      if (loggedIn.data.status) {
        setItem("userDetails", loggedIn?.data?.data);
        setItem("access_token", loggedIn.data.data.token);
        dispatch({
          type: API.auth.login,
          login: loggedIn.data.data?.signup_step == 3 ? true : false,
          userDetails: loggedIn.data.data,
        });
        if (loggedIn.data.data?.signup_step == 1) {
          return { status: true, data: "/onboarding" };
        } else if (loggedIn.data.data?.signup_step == 2) {
          return { status: true, data: "/onboarding" };
        } else if (loggedIn.data.data?.signup_step == 3) {
          Cookies.set("logedIn", true);
          message.success(loggedIn.data.message);
          return { status: true, data: "/" };
        }
      } else {
        message.error(loggedIn.data.message);
        return { status: false };
      }
    } catch (err) {
      return { status: false };
    }
  };
};
// UPDATE USER API CALL
export const updateProfile = async (payloads, id) => {
  try {
    const loggedIn = await DataService.put(
      API.auth.updateProfile + id,
      payloads
    );
    if (loggedIn.data.status) {
      Cookies.set("logedIn", true);
      setItem("userDetails", loggedIn.data.data);
      // dispatch({
      //   type: API.auth.login,
      //   login: true,
      //   userDetails: loggedIn.data.data,
      // });
      message.success(loggedIn.data.message);
      return true;
    } else {
      message.error(loggedIn.data.message);
      return false;
    }
  } catch (err) {
    return false;
  }
};

// FORGOT PASSWORD API CALL
export const forgotPassword = (payloads) => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.post(
        API.auth.forgotPassword,
        payloads
      );
      if (loggedIn.data.status) {
        message.success(loggedIn.data.message);
        return true;
      } else {
        message.error(loggedIn.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// RESET PASSWORD API CALL
export const resetPassword = (payloads) => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.post(API.auth.resetPassword, payloads);
      if (loggedIn.data.status) {
        message.success(loggedIn.data.message);
        return true;
      } else {
        message.error(loggedIn.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// RESEND USER VERIFY ACCOUNT API CALL
export const resendUserVarifyLink = (payloads) => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.post(
        API.auth.resendVerifyAccount,
        payloads
      );
      if (loggedIn.data.status) {
        message.success(loggedIn.data.message);
        return true;
      } else {
        message.error(loggedIn.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// RESEND USER VERIFY ACCOUNT API CALL
export const validateEmail = (payloads) => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.post(API.auth.verifyEmail, payloads);
      if (loggedIn.data.status) {
        return true;
      } else {
        message.error(loggedIn.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// VERIFY ACCOUNT API CALL
export const verifyUserAccount = (payloads) => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.post(API.auth.verify, payloads);
      if (loggedIn.data.status) {
        setItem("userDetails", loggedIn.data.data);
        setItem("access_token", loggedIn.data.data.token);
        dispatch({
          type: API.auth.login,
          login: loggedIn.data.data?.signup_step == 3 ? true : false,
          userDetails: loggedIn.data.data,
        });
        if (loggedIn.data.data?.signup_step == 1) {
          return { status: true, data: "/onboarding" };
        } else if (loggedIn.data.data?.signup_step == 2) {
          return { status: true, data: "/onboarding" };
        } else if (loggedIn.data.data?.signup_step == 3) {
          Cookies.set("logedIn", true);
          message.success(loggedIn.data.message);
          return { status: true, data: "/" };
        }
      } else {
        message.error(loggedIn.data.message);
        return { status: false };
      }
    } catch (err) {
      return false;
    }
  };
};

// REGISTER USER API CALL
export const signUp = (payloads) => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.post(API.auth.signUp, payloads);
      if (loggedIn.data.status) {
        setItem("userDetails", loggedIn.data.data);
        setItem("access_token", loggedIn.data.data.token);
        dispatch({
          type: API.auth.login,
          login: false,
          userDetails: loggedIn.data.data,
        });
        message.success(loggedIn.data.message);
        return { status: true, data: loggedIn.data.data };
      } else {
        message.error(loggedIn.data.message);
        return { status: false };
      }
    } catch (err) {
      return { status: false };
    }
  };
};

// UPDATE USER QUESTIONS API CALL
export const updateQuestions = (payloads) => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.put(
        API.auth.updateQuestion + getItem("userDetails")?.id,
        payloads
      );
      if (loggedIn.data.status) {
        setItem("userDetails", loggedIn.data.data);
        dispatch({
          type: API.auth.login,
          login: false,
          userDetails: loggedIn.data.data,
        });
        return { status: true, data: loggedIn.data.data };
      } else {
        message.error(loggedIn.data.message);
        return { status: false };
      }
    } catch (err) {
      return { status: false };
    }
  };
};

// UPDATE USER LAST QUESTION API CALL
export const updateFinalQuestions = (payloads) => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.put(
        API.auth.updateFianlQuestion + getItem("userDetails")?.id,
        payloads
      );
      if (loggedIn.data.status) {
        Cookies.set("logedIn", true);
        setItem("userDetails", {
          ...loggedIn.data.data,
          token: getItem("access_token"),
        });

        dispatch({
          type: API.auth.login,
          login: true,
          userDetails: {
            ...loggedIn.data.data,
            token: getItem("access_token"),
          },
        });
        message.success("Login Successfully");

        // window.location.reload();
        return {
          status: true,
          data: {
            ...loggedIn.data.data,
            token: getItem("access_token"),
          },
        };
      } else {
        message.error(loggedIn.data.message);
        return { status: false };
      }
    } catch (err) {
      return { status: false };
    }
  };
};

// LOG OUT FUNCTION
export const logOut = (messageStatus) => {
  return async (dispatch) => {
    try {
      Cookies.remove("logedIn");
      localStorage.clear();
      dispatch({
        type: API.auth.login,
        userDetails: {},
        login: false,
      });
      if (messageStatus) {
        message.success("Logout Successfully");
      }
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const userDetailsSave = (userData) => {
  return async (dispatch) => {
    try {
      console.log("userDatauserData", userData);
      dispatch({
        type: API.auth.login,
        login: true,
        userDetails: userData,
      });
    } catch (err) {
      return { status: false };
    }
  };
};
