import { API } from "../../config/api/Index";

// ALL TYPE WISE DATA RETURN
const initState = {
  creditPlanListCounts: {
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
  creditPlanList: undefined,
};

// DEFINE AUTH REDUCER FUNCTION
const creditPlanReducer = (state = initState, action) => {
  switch (action.type) {
    case API.subscription.creditPlanList:
      return {
        ...state,
        creditPlanList: action.creditPlanList,
        creditPlanListCounts: action.creditPlanListCounts,
      };
    default:
      return state;
  }
};

// EXPORT ALL FUNCTION
export default creditPlanReducer;
