import React from "react";
import { Col, Divider, Row } from "antd";
import gmail from "../../assets/signup/gmail.png";
import outlook from "../../assets/signup/outlook.png";
import { useNavigate } from "react-router-dom";
import { getItem } from "../../utility/localStorageControl";
import style from "./AuthFiles.module.css";

// DEFINE MAIN FUNCTION
const EmailVarification = () => {
  // DEFINE ALL STATES
  const navigate = useNavigate();

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <div className={style.emailVarificationComponent}>
        <div className={style.detailsComponent}>
          <h2 className={style.title}>Check your inbox 📨</h2>
          <p className={style.description}>
            Password recovery link has been sent from <br />
            {getItem("varification-email")
              ? getItem("varification-email")
              : "your mail"}
          </p>
          <p className={style.description}>
            If the email does not arrive soon, Check your spam folder.
          </p>
          <Divider className={style.devider}>Jump to</Divider>
        </div>

        <Row className={style.buttonContainer} justify={"center"} gutter={16}>
          <Col span={12}>
            <button
              onClick={() => {
                window.open("https://mail.google.com/", "_blank");
              }}
              size="large"
            >
              <img src={gmail} alt="logo"></img> Gmail
            </button>
          </Col>
          <Col span={12}>
            <button
              size="large"
              onClick={() => {
                window.open("https://outlook.live.com/mail", "_blank");
              }}
            >
              <img src={outlook} alt="logo"></img> Outlook
            </button>
          </Col>
        </Row>
        <button
          onClick={() => {
            navigate("/login");
          }}
          type="button"
          className={style.backButton}
        >
          Back to Log In
        </button>
        <div className={style.blankDiv}></div>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default EmailVarification;
